import {observer} from "mobx-react-lite";
import {Loading} from "../../components/Loading";
import {TextEditor} from "../sops/usa_sop/editor/text-editor";
import { useEffect, useRef } from "react";

const SopContainer = observer((props: any) => {
  const {sopStore} = props
  const topRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (topRef.current) {
      topRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [sopStore.activeStep]);
  const OpenApiStatusSOP = localStorage.getItem('openApiStatus')
  return (
    <>
      <div ref={topRef}></div>
      {sopStore.loading ? <><Loading/></> : ''}
      {sopStore.generatedOutput !== '' ?
        <>
          <TextEditor/>
        </> :
        <>
        <div className = {`${OpenApiStatusSOP ? 'mt-4 mb-6' : ''}`} style={{ zIndex: 1  }}>
          {(() => {
            switch (sopStore.activeStep) {
              case "Step 1":
                return props.step1
              case "Step 2":
                return props.step2
              case "Step 3":
                return props.step3
              case "Step 4" :
                return props.step4
              case "Step 5" :
                return props.step5
                case "Step 6" :
                return props.step6
                case "Step 7" :
                return props.step7
                case "Step 8" :
                return props.step8
              default:
                return
            }
          })()}
          </div>
        </>
      }
    </>
  )
})
export default SopContainer
