import { observer } from "mobx-react-lite";

export const MinusButton = observer((props: any) => {
  const handleUndo = () => {
    props.handleUndo(props.index);
  };

  return (
    <div onClick={handleUndo} className={`ml-2 border border-[#A033FF] rounded-lg text-[#A033FF] p-1.5 cursor-pointer h-10 ${props.disabled ? 'opacity-50 cursor-not-allowed' : ''}`}>
      <span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-6 h-6"
        >
          <path strokeLinecap="round" strokeLinejoin="round" d="M20 12H4" />
        </svg>
      </span>
    </div>
  );
});
