import { Fragment, useState, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/24/outline';
import { useStore } from '../../hooks/use-store';
import { observer } from 'mobx-react-lite';
import { ResumeModalStepUploadCV } from './resume-step-upload';
import { ResumeModalStepAddress } from './resume-step-address';
import Location from '../student-onBoarding/location';
import Education from '../student-onBoarding/education';
import WorkExperience from '../student-onBoarding/work-experience';
import Skills from '../student-onBoarding/skills/skills';
import CertificationLicenses from '../student-onBoarding/certification-licenses/certification-licenses';
import { toJS } from 'mobx';
import UsersName from '../new-profile/personal_details.tsx/users_name';
import { toast } from 'react-toastify';
import { text } from 'stream/consumers';

export const ResumeModalComponent = observer((props) => {
  const {
    userStore,
    dashboardStore,
    resumeStore,
    userProfileStore,
    sopProfileSelectionStore,
    b2bStore,
  } = useStore();
  const { pre, post } = resumeStore.resume_progress_stepper_status();
  const [selectedCard, setSelectedCard] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      try {
        await userProfileStore.get_user_list();
        const getFirstUser = localStorage.getItem('userFirstLogin');
        if (
          userProfileStore.userProfilejsonData.isFirstLogin &&
          !userStore.isenterprise &&
          getFirstUser == 'true'
        ) {
          b2bStore.show_b2b_selection_creation_modal();
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);
  const matchedUser = () => {
    let matchedUserName: boolean;
    const enteredUserName = userProfileStore.userProfilejsonData.user_name.toUpperCase();
    const list = sopProfileSelectionStore.sopProfileSelectionValues;
    matchedUserName = list.some((item) => {
      return item.user_name?.toUpperCase() == enteredUserName;
    });
    return matchedUserName;
  };
  const checkForSopSelectionModel = () => {
    if (sopProfileSelectionStore.sopSelectionEdit) {
      // sopProfileSelectionStore.show_sop_selection_creation_modal();
      sopProfileSelectionStore.setSopSelectionEdit(false);
    }
  };

  //Adding this as part of Onboarding improvements
  //Once the Resume is created, set state to refresh available profiles
  const sop_profile_store_state_sync = async () => {
    try {
      if (userStore.isenterprise) {
        if (sopProfileSelectionStore.showSopSelectionCreationModal) {
          await sopProfileSelectionStore.getSopProfileSelectionDropdownValues();

          sopProfileSelectionStore.select_student(userProfileStore.profile_id);
          if (
            userProfileStore.userProfilejsonData.user_name !== '' &&
            resumeStore.current_step_num === 6
          ) {
            sopProfileSelectionStore.sop_selection_progress_nav_next();
          }
        } else {
          //B2C scenario
        }
      }
    } catch (e) {}
  };

  const hide_resume_creation_modal_wrapper = async () => {
    resumeStore.hide_resume_creation_modal();
    await sop_profile_store_state_sync();
    resumeStore.setSelectedCard('');
    resumeStore.setProcessing_complete(false);
    userProfileStore.setSkillsSelected(false);
  };

  const openDialog = () => {
    setDialogOpen(true);
  };

  const closeDialog = () => {
    setDialogOpen(false);
  };
  useEffect(()=>{
    if(resumeStore.current_step_num === 0){
      sopProfileSelectionStore. current_sop_selection_step['name'] ='Select Student'
    }
  },[resumeStore.current_step_num])
  return (
    <>
      <Transition.Root show={resumeStore.showResumeCreationModal} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50"
          static
          onClose={() => {
            // hide_resume_creation_modal_wrapper();
            // resumeStore.resetState();
            // checkForSopSelectionModel();
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel
                  className="flex relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:w-full sm:max-w-sm "
                  style={{ minWidth: 600, maxHeight: 650 }}
                >
                  <div
                    className={`${
                      userProfileStore.profileScreen
                        ? 'fixed right-[25px] top-[25px] text-[24px] cursor-pointer'
                        : 'resume-modal-close'
                    }`}
                    onClick={() => {
                      openDialog();
                    }}
                  >
                    <i className="fa-solid fa-xmark "></i>
                  </div>
                  <Transition.Root show={dialogOpen} as={Fragment}>
                    <Dialog
                      as="div"
                      className="fixed inset-0 z-50 overflow-y-auto"
                      onClose={() => {
                        // closeDialog();
                      }}
                    >
                      <div className="flex items-center justify-center min-h-screen p-6 ">
                        <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />{' '}
                        <Transition.Child
                          as={Fragment}
                          enter="ease-out duration-300"
                          enterFrom="opacity-0 scale-95"
                          enterTo="opacity-100 scale-100"
                          leave="ease-in duration-200"
                          leaveFrom="opacity-100 scale-100"
                          leaveTo="opacity-0 scale-95"
                        >
                          <div className="relative bg-white rounded-lg shadow-lg max-w-md w-full mr-[0rem]">
                            <div className="p-4">
                              <h3 className="text-[30px] font-semibold mb-4">Confirmation</h3>
                              <p className="text-[25px]">
                                Are you sure you want to close the dialogue ? If you do, you might
                                lose your data.
                              </p>
                              <div className="mt-4 flex justify-end">
                                <button
                                  onClick={ () => {
                                    resumeStore.setSelectedCard('');
                                    sopProfileSelectionStore.sop_selection_progress_nav_previous(); 
                                    sopProfileSelectionStore.sopProfileSelection = null;
                                    closeDialog();
                                    hide_resume_creation_modal_wrapper();
                                    // resumeStore.resetState();
                                    userProfileStore.get_user_profile_name_id_list();
                                    checkForSopSelectionModel();
                                    //  userProfileStore.resetUserData();
                                    if (
                                      resumeStore.current_step_num === 1 &&
                                      resumeStore.upload_screen === 'initial-choice'
                                    ) {
                                      resumeStore.setProcessing_complete(false);
                                    }
                                  }}
                                  className="px-4 py-2 bg-[#A033FF] text-white rounded hover:bg-purple-700 text-[25px]"
                                >
                                  Yes
                                </button>
                                <button
                                  onClick={closeDialog}
                                  className="px-4 py-2 bg-gray-200 text-gray-700 rounded hover:bg-gray-300 ml-2 text-[25px]"
                                >
                                  No
                                </button>
                              </div>
                            </div>
                          </div>
                        </Transition.Child>
                      </div>
                    </Dialog>
                  </Transition.Root>
                  <div className="resume-modal flex flex-col grow">
                    <div className="modal-header flex flex-row flex-no-wrap w-full items-center px-2 justify-between py-5">
                      <div
                        className={`${
                          userProfileStore.profileScreen && 'hidden'
                        } text-xs text-gray-500 mr-0`}
                      >
                        {resumeStore.resume_progress_get_current_state()}
                      </div>
                      <div
                        className={` ${
                          userProfileStore.profileScreen && 'flex justify-center w-full'
                        }`}
                        style={{
                          fontWeight: 500,
                          fontSize: '24px',
                          lineHeight: '32px',
                          color: '#3C424C',
                        }}
                      >
                        {resumeStore.current_step['name']}
                      </div>
                      <div
                        className={`${
                          userProfileStore.profileScreen && 'hidden'
                        } uppercase text-[#A033FF] cursor-pointer pr-8`}
                        onClick={async () => {
                          resumeStore.backButton = false;
                          if (resumeStore.resume_progress_is_last_step()) {
                            hide_resume_creation_modal_wrapper();
                          } else {
                            resumeStore.resume_progress_nav_next();
                          }

                          if (!resumeStore.showResumeCreationModal) {
                            checkForSopSelectionModel();
                          }
                          if (resumeStore.current_step_num === 6) {
                            resumeStore.setProcessing_complete(false);
                            resumeStore.setSelectedCard('');
                          }
                          userProfileStore.get_user_profile_name_id_list();
                        }}
                      >
                        {resumeStore.current_step_num == 0 || resumeStore.current_step_num == 1
                          ? ''
                          : 'Skip'}
                      </div>
                    </div>
                    <div className="modal-stepper">
                      <nav aria-label="Progress">
                        <ol role="list" className="space-y-4 md:flex md:space-x-1 md:space-y-0">
                          {Array.from(Array(pre), (e, i) => {
                            return (
                              <li className="md:flex-1">
                                <a className="group flex flex-col border-l-4 border-[#5EAEFF] py-2 pl-4 hover:border-indigo-800 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4" />
                              </li>
                            );
                          })}

                          <li className="md:flex-1">
                            <a
                              className="flex flex-col border-l-4 border-[#5EAEFF] py-2 pl-4 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4"
                              aria-current="step"
                            />
                          </li>

                          {Array.from(Array(post), (e, i) => {
                            return (
                              <li className="md:flex-1">
                                <a className="group flex flex-col border-l-4 border-gray-500 py-2 pl-4 hover:border-gray-300 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4" />
                              </li>
                            );
                          })}
                        </ol>
                      </nav>
                    </div>

                    <div
                      className={`${
                        resumeStore.current_step_num !== 5 &&
                        'flex flex-col justify-center items-center '
                      }modal-body grow`}
                      style={{ minHeight: 500, maxHeight: 590 }}
                    >
                      {resumeStore.current_step_num == 1 ? (
                        <ResumeModalStepUploadCV
                          setSelectedCard={resumeStore.setSelectedCard}
                          selectedCard={resumeStore.selectedCard}
                        />
                      ) : null}
                      {/* {resumeStore.current_step_num == 1 ? <ResumeModalStepAddress/> : null} */}
                      {resumeStore.current_step_num == 0 ? <UsersName /> : null}
                      {resumeStore.current_step_num == 2 ? <Location /> : null}
                      {resumeStore.current_step_num == 3 ? <Education /> : null}
                      {resumeStore.current_step_num == 4 ? <WorkExperience /> : null}
                      {resumeStore.current_step_num == 5 ? <Skills /> : null}
                      {resumeStore.current_step_num == 6 ? <CertificationLicenses /> : null}
                    </div>
                    <div
                      className={` ${
                        userProfileStore.profileScreen ? 'justify-center' : 'justify-around'
                      } modal-footer  flex flex-row items-center py-3 border-t-2 border-gray-200 `}
                    >
                      <span className="mr-0">
                        <div>
                          {resumeStore.current_step_num === 0 ? (
                            ''
                          ) : (
                            <button
                              className={`${
                                userProfileStore.profileScreen && 'hidden'
                              } uppercase  rounded-lg px-12 py-2 text-center text-[14px] leading-[18px] w-fit border border-solid border-[#737E93] rounded-md p-4 m-[-4px] ${
                                !resumeStore.navigate &&
                                resumeStore.current_step_num === 0 &&
                                !resumeStore.continueClicked
                                  ? 'bg-[#F8F9FA] cursor-not-allowed text-[#737E93] h-10 w-24 rounded-[4px]  flex items-center justify-center'
                                  : resumeStore.backButton
                                  ? // || (!resumeStore.backButton &&
                                    //   resumeStore.current_step_num == 1 &&
                                    //   !resumeStore.navigate)
                                    // || (resumeStore.current_step_num === 1 &&
                                    //   userProfileStore.userProfilejsonData.user_name != '' &&
                                    //   resumeStore.upload_screen == 'initial-choice' &&
                                    //   selectedCard !== '')
                                    'border border-purple-500 ml-6 h-10 w-24 rounded-[4px] text-white  bg-[#737E93] cursor-pointer flex items-center justify-center'
                                  : 'border ml-6 h-10 w-24 rounded-[4px] text-purple-600  border-purple-500 bg-purple-50 cursor-pointer flex items-center justify-center'
                              }`}
                              disabled={
                                resumeStore.backButton ||
                                // (!resumeStore.backButton &&
                                //   resumeStore.current_step_num == 1 &&
                                //   !resumeStore.navigate) ||
                                (resumeStore.current_step_num === 0 && !resumeStore.navigate)
                                // || (resumeStore.current_step_num === 1 &&
                                //   userProfileStore.userProfilejsonData.user_name != '' &&
                                //   resumeStore.upload_screen == 'initial-choice' &&
                                //   selectedCard !== '')
                              }
                              onClick={() => {
                                if (
                                  resumeStore.upload_screen === 'upload-doc' &&
                                  resumeStore.current_step_num === 1 &&
                                  resumeStore.processing_complete === false
                                ) {
                                  resumeStore.setContinueClicked(true);
                                }
                                if (resumeStore.current_step_num === 2 && resumeStore.navigate) {
                                  resumeStore.setUploadScreen('upload-doc');
                                  resumeStore.setContinueClicked(true);
                                  resumeStore.resume_progress_nav_previous();
                                } else if (
                                  resumeStore.current_step_num === 1 &&
                                  resumeStore.navigate
                                ) {
                                  resumeStore.setUploadScreen('initial-choice');
                                  resumeStore.setContinueClicked(false);
                                  resumeStore.setNavigate(false);
                                  // resumeStore.setSelectedCard('');
                                } else {
                                  resumeStore.resume_progress_nav_previous();
                                  resumeStore.setContinueClicked(false);
                                  //resumeStore.setSelectedCard('');
                                }
                                resumeStore.setContinueButton(false);
                                if (
                                  resumeStore.current_step_num === 1 &&
                                  resumeStore.upload_screen === 'initial-choice'
                                ) {
                                  resumeStore.setProcessing_complete(false);
                                }
                                if (
                                  resumeStore.current_step_num === 1 &&
                                  resumeStore.clearResumeData === false
                                ) {
                                  userProfileStore.userProfilejsonData = {
                                    ...userProfileStore.defaultUserProfileJsonData,
                                    user_name: userProfileStore.userProfilejsonData.user_name,
                                  };
                                }
                              }}
                            >
                              Back
                            </button>
                          )}
                        </div>
                      </span>
                      <span
                        className={` ${
                          userProfileStore.profileScreen ? 'mx-[90px] w-full' : 'ml-40'
                        }`}
                      >
                        <div>
                          {!userProfileStore.profileScreen ? (
                            <button
                              className={`uppercase text-white rounded-lg px-12 py-2 text-center text-[14px] leading-[18px] w-fit m-[-4px]  ${
                                userProfileStore.loading ||
                                (resumeStore.current_step_num === 1 &&
                                  !resumeStore.navigate &&
                                  resumeStore.selectedCard === '') ||
                                (resumeStore.upload_screen == 'upload-doc' &&
                                  resumeStore.navigate &&
                                  resumeStore.continueButton == true) ||
                                (resumeStore.current_step_num === 0 &&
                                  userProfileStore.userProfilejsonData.user_name === '') ||
                                (resumeStore.current_step_num === 1 &&
                                  resumeStore.navigate &&
                                  resumeStore.processing_complete === false)
                                  ? ' border h-10 w-26 rounded-[4px]  flex items-center justify-center cursor-not-allowed bg-[#737E93]'
                                  : 'border h-10 w-26 rounded-[4px]  flex items-center justify-center bg-[#A033FF] text-white '
                              }`}
                              disabled={
                                userProfileStore.loading ||
                                (!userProfileStore.profileScreen &&
                                  resumeStore.current_step_num === 1 &&
                                  !resumeStore.navigate &&
                                  resumeStore.selectedCard === '') ||
                                (resumeStore.upload_screen == 'upload-doc' &&
                                  resumeStore.navigate &&
                                  resumeStore.continueButton == true) ||
                                (resumeStore.current_step_num === 0 &&
                                  userProfileStore.userProfilejsonData.user_name === '') ||
                                (resumeStore.current_step_num === 1 &&
                                  resumeStore.navigate &&
                                  resumeStore.processing_complete === false)
                              }
                              onClick={async () => {
                                // To set state of continue button
                                if (resumeStore.current_step_num !== 0) {
                                  if (resumeStore.current_step_num !== 1) {
                                    resumeStore.setClearResumeData(true);
                                  }
                                }
                                if (resumeStore.current_step_num > 0) {
                                  resumeStore.setContinueClicked(true);
                                }
                                if (resumeStore.current_step_num === 0 && !matchedUser()) {
                                  //userProfileStore.get_user_list();
                                  resumeStore.setUploadScreen('initial-choice');
                                }

                                if (
                                  (resumeStore.current_step_num === 0 && !matchedUser()) ||
                                  resumeStore.current_step_num === 6
                                ) {
                                  userProfileStore.update_profile('');
                                }

                                if (resumeStore.resume_progress_is_last_step()) {
                                  hide_resume_creation_modal_wrapper();
                                  // resumeStore.resetState();
                                }
                                // To call next nav when we click the continue button of when we select cambri resume upload card
                                else if (
                                  (resumeStore.upload_screen == 'initial-choice' &&
                                    !matchedUser() &&
                                    resumeStore.selectedCard !== 'upload') ||
                                  (resumeStore.current_step_num === 0 &&
                                    resumeStore.upload_screen == 'initial-choice' &&
                                    !matchedUser())
                                ) {
                                  resumeStore.resume_progress_nav_next();
                                }

                                // To call next nav when we click the continue button of when we selected the upload resume card in that after uploading the resume
                                else if (resumeStore.navigate && !matchedUser()) {
                                  resumeStore.resume_progress_nav_next();
                                }

                                if (!resumeStore.showResumeCreationModal) {
                                  checkForSopSelectionModel();
                                }
                                if (resumeStore.current_step_num == 6) {
                                  userProfileStore.get_user_profile_name_id_list();
                                  sopProfileSelectionStore.setSopProfileSelectionValues(userProfileStore.userprofile)
                                  
                                }
                                if (resumeStore.current_step) {
                                  resumeStore.backButton = false;
                                }
                                if (resumeStore.current_step) {
                                  //setSelectedCard('');
                                }
                                if (matchedUser() && !(sopProfileSelectionStore. current_sop_selection_step['name'] === 'Name of SOP/LOR')) {
                                  toast.error('User Already Exist');
                                }
                                if (resumeStore.current_step_num === 6 ) {
                                  sopProfileSelectionStore. current_sop_selection_step['name'] ='Name of SOP/LOR'
                                  // resumeStore.setProcessing_complete(false);
                                  // setSelectedCard('');
                                }
                                if(resumeStore.current_step_num === 0){
                                  sopProfileSelectionStore. current_sop_selection_step['name'] ='Select Student'
                                }
                                if (
                                  resumeStore.selectedCard === 'upload' &&
                                  resumeStore.current_step_num === 1
                                ) {
                                  resumeStore.setUploadScreen('upload-doc');
                                }
                                if (
                                  resumeStore.selectedCard === 'upload' &&
                                  resumeStore.current_step_num === 1 &&
                                  resumeStore.processing_complete === false &&
                                  resumeStore.upload_screen === 'initial-choice'
                                ) {
                                  resumeStore.setUploadScreen('upload-doc');
                                }
                                // if (resumeStore.current_step_num == 0) {
                                // resumeStore.setResumeLastStep(false);
                                // }

                              }}
                            >
                              {/* To set the continue button for add resume screen and save next button for cambri resume steps screens  */}
                              {resumeStore.current_step_num == 0 ||
                              resumeStore.current_step_num == 1
                                ? 'CONTINUE'
                                : 'SAVE AND NEXT'}
                            </button>
                          ) : (
                            <button
                              onClick={async () => {
                                if (
                                  resumeStore.current_step_num == 4 ||
                                  resumeStore.current_step_num == 3 ||
                                  resumeStore.current_step_num == 5 ||
                                  resumeStore.current_step_num == 6
                                ) {
                                  await userProfileStore.update_profile(
                                    hide_resume_creation_modal_wrapper()
                                  );
                                }
                                if (!matchedUser()) {
                                  await userProfileStore.rename_user(
                                    hide_resume_creation_modal_wrapper()
                                  );
                                }

                                checkForSopSelectionModel();
                                if (resumeStore.current_step_num === 0) {
                                  await userProfileStore.get_user_profile_name_id_list();
                                }
                                if (userProfileStore.isFetchUsersData) {
                                  userProfileStore.FetchUsersData(false);
                                }
                                if (matchedUser()) {
                                  if (resumeStore.current_step_num === 0 &&!(sopProfileSelectionStore.current_sop_selection_step['name'] === 'Name of SOP/LOR')) {
                                    toast.error('User Already Exist');
                                  }
                                }
                                 if (resumeStore.current_step_num == 6) {
                                  sopProfileSelectionStore. current_sop_selection_step['name'] ='Name of SOP/LOR'
                                  }
                                  if(resumeStore.current_step_num === 0){
                                    sopProfileSelectionStore. current_sop_selection_step['name'] ='Select Student'
                                  }
                              }}
                              disabled={userProfileStore.userProfilejsonData.user_name === ''}
                              className={
                                userProfileStore.userProfilejsonData.user_name === ''
                                  ? 'uppercase text-black rounded-lg px-12 py-2 text-center text-[14px] leading-[18px] w-full m-[-4px] bg-[#737E93]'
                                  : 'bg-[#A033FF] uppercase text-white rounded-lg px-12 py-2 text-center text-[14px] leading-[18px] w-full m-[-4px]'
                              }
                            >
                              SAVE
                            </button>
                          )}
                        </div>
                      </span>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
});
