import apiSauce from "./api-sauce";

export class ToolsApi {

  async get_tools_list(payload): Promise<any> {
    return await apiSauce.apisauce.post("db/toolset", JSON.stringify(payload))
  }

  async get_my_tools_list(payload): Promise<any> {
    return await apiSauce.apisauce.post("db/mytoolset", JSON.stringify(payload))
  }

  async get_tool_details(payload): Promise<any> {
    return await apiSauce.apisauce.post("db/tool", JSON.stringify(payload))
  }

  async compose(payload): Promise<any> {
    return await apiSauce.apisauce.post("gen/compose", JSON.stringify(payload))
  }

  async tokenCount(payload): Promise<any> {
    return await apiSauce.apisauce.post("gen/token_count", JSON.stringify(payload))
  }

  async saveTool(payload): Promise<any> {
    return await apiSauce.apisauce.post("db/tool/update", JSON.stringify(payload))
  }

  async deleteTool(payload): Promise<any> {
    return await apiSauce.apisauce.post("db/tool/delete", JSON.stringify(payload))
  }

  async cloneTool(payload): Promise<any> {
    return await apiSauce.apisauce.post("db/tool/clone", JSON.stringify(payload))
  }

  async add_new_tool(): Promise<any> {
    return await apiSauce.apisauce.post("db/tool/add")
  }

  async get_compose_history(payload): Promise<any> {
    return await apiSauce.apisauce.post("gen/history", JSON.stringify(payload))
  }

}
