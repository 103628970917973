import { Fragment,  useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { useStore } from '../../hooks/use-store';
import { observer } from 'mobx-react-lite';
import { SelectB2b } from './b2b-selection-cards';
import { ConfirmB2b } from './b2b-confirm';
import { useLocation } from 'react-router-dom';

export const B2bSelectionModal = observer((props) => {
  const { userStore, b2bStore, resumeStore, userProfileStore } = useStore();
  const { pre, post } = b2bStore.b2b_selection_progress_stepper_status();
  const [selectedCard, setSelectedCard] = useState('');


 
  const location = useLocation();
  return (
    <>
      <Transition.Root show={b2bStore.showB2bSelectionCreationModal} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={() => {}}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel
                  className="flex relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:w-full sm:max-w-sm "
                  style={{ minWidth: 800, maxHeight: 525, maxWidth: 800, width: 800, height: 525 }}
                >
                  <div className="resume-modal flex flex-col grow">
                    <div className="modal-header flex flex-row flex-no-wrap w-full items-center px-2 justify-between py-5">
                      <div className={` text-xs text-gray-500 mr-0`}>
                        {b2bStore.b2b_selection_progress_get_current_state()}
                      </div>
                      <div
                        style={{
                          fontWeight: 500,
                          fontSize: '24px',
                          lineHeight: '32px',
                          color: '#3C424C',
                        }}
                      >
                        {b2bStore.current_b2b_selection_step['name']}
                      </div>
                      <div className={`uppercase text-[#A033FF] cursor-pointer pr-8`}></div>
                    </div>
                    <div className="modal-stepper h-0">
                      <nav aria-label="Progress">
                        <ol role="list" className="space-y-4 md:flex md:space-x-1 md:space-y-0">
                          {Array.from(Array(pre), (e, i) => {
                            return (
                              <li className="md:flex-1">
                                <a className="group flex flex-col border-l-4 border-[#5EAEFF] py-2 pl-4 hover:border-indigo-800 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4" />
                              </li>
                            );
                          })}

                          <li className="md:flex-1">
                            <a
                              className="flex flex-col border-l-4 border-[#5EAEFF] py-2 pl-4 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4"
                              aria-current="step"
                            />
                          </li>

                          {Array.from(Array(post), (e, i) => {
                            return (
                              <li className="md:flex-1">
                                <a className="group flex flex-col border-l-4 border-gray-500 py-2 pl-4 hover:border-gray-300 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4" />
                              </li>
                            );
                          })}
                        </ol>
                      </nav>
                    </div>

                    <div
                      className={`flex flex-col justify-center items-center modal-body grow pt-8`}
                      style={{ minHeight: 385, maxHeight: 385, width: 800, height: 385 }}
                    >
                      {b2bStore.current_b2b_selection_step_num == 0 ? (
                        <SelectB2b selectedCard={selectedCard} setSelectedCard={setSelectedCard} />
                      ) : null}
                      {b2bStore.current_b2b_selection_step_num == 1 ? <ConfirmB2b /> : null}
                    </div>
                    <div
                      className={` justify-around  modal-footer  flex flex-row items-center py-3 border-t-2 border-gray-200 `}
                    >
                      <span className="mr-0">
                        <div>
                          <button
                            className={` uppercase  rounded-lg px-8 py-3 text-center text-[14px] leading-[18px]  border border-solid border-[#737E93] rounded-md p-4 m-[-4px] w-[150px]  ${
                              b2bStore.current_b2b_selection_step_num === 0
                                ? 'bg-[#F8F9FA] cursor-not-allowed text-[#737E93] h-10 w-24 rounded-[4px]  flex items-center justify-center'
                                : b2bStore.current_b2b_selection_step_num === 1
                                ? 'border  h-10 w-30 rounded-[4px] text-purple-600 border-purple-500 bg-purple-50 cursor-pointer flex items-center justify-center'
                                : 'border  h-10 w-30 rounded-[4px] text-purple-600 border-purple-500 bg-purple-50 cursor-pointer flex items-center justify-center'
                            }`}
                            disabled={b2bStore.current_b2b_selection_step_num === 0}
                            onClick={() => {
                              if (b2bStore.current_b2b_selection_step_num === 1) {
                                b2bStore.b2b_selection_progress_nav_previous();
                                b2bStore.setB2bSelectionContinueClicked(false);
                                b2bStore.setB2bSelectionContinueButton(false);
                              }
                            }}
                          >
                            Back
                          </button>
                        </div>
                      </span>
                      <span className="ml-40">
                        <div>
                          {
                            <button
                              className={`uppercase text-white rounded-lg px-8 py-3 text-center text-[14px] leading-[18px] m-[-4px] w-[150px]   ${
                                b2bStore.current_b2b_selection_step_num === 0 &&
                                !b2bStore.selectedCard
                                  ? ' border h-10 w-30 rounded-[4px]  flex items-center justify-center bg-[#737E93] cursor-not-allowed'
                                  : 'border h-10 rounded-[4px]  flex items-center justify-center bg-[#A033FF] text-white '
                              }`}
                                disabled={!b2bStore.selectedCard}
                              onClick={async () => {
                                // To set state of continue button

                                b2bStore.setB2bSelectionContinueClicked(true);
                                if (b2bStore.b2b_selection_progress_is_last_step()) {
                                }
                                if (
                                  b2bStore.current_b2b_selection_step_num === 0 ||
                                  b2bStore.current_b2b_selection_step_num === 1
                                ) {
                                  b2bStore.setB2bSelectioNavigate(true);
                                }

                                if (b2bStore.b2b_selection_progress_is_last_step()) {
                                  localStorage.setItem('userFirstLogin', JSON.stringify(false));
                                  if (b2bStore.selectedCard == 'business') {
                                    await userStore.enable_enterprise(true);
                                    userStore.setUserFirstLogin(false);
                                  } else {
                                    b2bStore.setB2bSwitch(false);
                                  }
                                  b2bStore.hide_b2b_selection_creation_modal();
                                  if (
                                    userProfileStore.userProfilejsonData.isFirstLogin &&
                                    !userStore.isenterprise &&
                                    location.pathname != '/profile'
                                  ) {
                                    resumeStore.show_resume_creation_modal();
                                  }

                                  b2bStore.resetState();
                                }
                                // To call next nav when we click the continue button of when we selected the upload resume card in that after uploading the resume
                                else if (b2bStore.b2bSelectionNavigate) {
                                  b2bStore.b2b_selection_progress_nav_next();
                                }
                              }}
                            >
                              {/* To set the continue button for add resume screen and save next button for cambri resume steps screens  */}
                              {b2bStore.current_b2b_selection_step_num == 0
                                ? 'CONTINUE'
                                : 'CONFIRM'}
                            </button>
                          }
                        </div>
                      </span>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
});
