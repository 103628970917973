import { observer } from 'mobx-react-lite';
import PencilIcon from '../../icons/pencil.svg';
import eyeIcon from '../../icons/Eye.svg';
import invisibleIcon from '../../icons/invisible.svg';

const CustomTextField = observer((props: any) => {
  
  const truncatedValue = props.value && props.value.length > 20 ? `${props.value.slice(0, 20)}...` : props.value;
  const shouldTruncate = props.shouldTruncate !== undefined ? props.shouldTruncate : true;
    return (
    <div className="w-full relative">
      <label className={`${props.color ? 'text-[#4E5664]' : 'text-gray-400'} relative focus-within:text-gray-600 block text-sm md:text-base`}>
        {props.label} {props.isMandatory && <span className='text-red-500'>*</span>}
        <input
          readOnly={props.sop}
          disabled={props.sop}
          value={shouldTruncate ? truncatedValue : props.value}
          onChange={props.onChangeHandler}
          type={props.type}
          name={props.name}
          placeholder={props.placeholder}
          onKeyUp={props.onKeyUp}
          className={`text-ellipsis whitespace-nowrap block rounded-lg ${props.color ? 'mt-3' : 'mt-[4px]'} bg-[#FEFEFE] mb-[30px] md:mb-[15px]s px-[10px] peer h-8 md:h-10 w-full border border-[#C7CBD4] text-gray-900 focus:outline-none focus:border-indigo-600`}
        />
        {!props.passwordVisible ? (
          <img
            id={props.slot}
            onClick={props.showPasswordVisibility}
            src={invisibleIcon}
            className={`${
              props.name !== 'password' && 'hidden'
            } cursor-pointer absolute top-[70%] transform -translate-y-1/2 right-3`}
          />
        ) : (
          <img
            id={props.slot}
            onClick={props.showPasswordVisibility}
            src={eyeIcon}
            className={`${
              props.name !== 'password' && 'hidden'
            } cursor-pointer absolute top-[70%] transform -translate-y-1/2 right-3`}
          />
        )}
        {props.slot !== 'student_name' && props.showInfoIcon && (
          <img
            id={props.slot}
            onClick={() => props.onEditClicked(props.slot,props.id,props.name)}
            src={PencilIcon}
            className="cursor-pointer absolute top-[78%] transform -translate-y-1/2 right-[1.5rem]"
          />
        )}
      </label>
    </div>
  );
});

export default CustomTextField;
