import { observer } from 'mobx-react-lite';
import { useStore } from '../../hooks/use-store';
import { useState } from 'react';

const Text_area = observer((props: any) => {
  const { toolsStore, sopStore } = useStore();
  const [sug, setSug] = useState('');

  const textareaHandler = (evt) => {
    setSug(evt.target.value);
    toolsStore.updateComposeObject(props.slot, evt.target.value.split(','));
  };

  const suggestionClickedHandler = (sugg) => {
    setSug(sugg);
    toolsStore.updateComposeObject(props.slot, sugg);
  };

  return (
    <>
      <div className="flex flex-col py-4">
        <label className="relative items-center py-2 font-bold">
          {props.display}
          <textarea
            value={sug}
            onChange={textareaHandler}
            className="block border-2 p-2 rounded-md"
            id={props.title}
            name={props.category}
            rows={props.rows}
            cols={props.cols}
            placeholder={props.placeholder}
          ></textarea>
        </label>
      </div>
      {props.suggestions && (
        <>
          <label className="justify-center py-2 ">Examples</label>
          <div className="p-2">
            {props.suggestions.map((sug) => (
              <div
                key={sug}
                className="my-2 py-1 w-fit px-2 bg-blue-100 text-sm cursor-pointer border rounded-lg flex-shrink object-none"
                onClick={() => suggestionClickedHandler(sug)}
              >
                {sug}
              </div>
            ))}
          </div>
        </>
      )}
    </>
  );
});
export default Text_area;
