import AddButton from '../../components/addButton/add_button';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../hooks/use-store';
import GetDataBasedOnSlot from './getDataBasedOnSlot';

const Education = observer((props: any) => {
  const handleChange = (e, slot, itemId, keyValue) => {
    userProfileStore.updateUserJsonDataArr(slot, itemId, keyValue, e.target.value);
  };  
  const { userProfileStore } = useStore();
  const template = {
    Level_of_Education: {
      placeholder: 'Enter',
      name: 'Level of Education',
      type: 'text',
      label: 'Level of Education',
    },
    Field_of_Study: {
      placeholder: 'Enter',
      name: 'Field of Study',
      type: 'text',
      label: 'Field of Study',
    },
    School_Name: {
      placeholder: 'Enter',
      name: 'School Name',
      type: 'text',
      label: 'School Name',
    },

    From: {
      placeholder: 'Enter dd/mm/yyyy',
      name: 'From',
      type: 'date',
      label: 'From',
    },

    To: {
      placeholder: 'Enter dd/mm/yyyy',
      name: 'To',
      type: 'date',
      label: 'To',
    },
  };

  const handleClick = () => {
    userProfileStore.addEducationList();
  };
console.log(userProfileStore.userProfilejsonData.education,'education')
  return (
    <div className="mt-2 w-full overflow-y-auto px-24">
      <div className="flex justify-between flex-col w-full">
        <GetDataBasedOnSlot
          slot={'education'}
          data={userProfileStore.userProfilejsonData.education}
          template={template}
          handleChange={handleChange}
        />
      </div>
      <div className="flex items-center justify-center">
        <AddButton onClick={handleClick} onBoard={true}>
          {'Add New'}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-5 h-5 ml-6"
          >
            <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
          </svg>
        </AddButton>
      </div>
    </div>
  );
});

export default Education;