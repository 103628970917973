import { action, makeAutoObservable, runInAction, toJS } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
import { SopApi } from '../api/sop-api';
import htmlDocx from 'html-docx-js/dist/html-docx';
import { EditorApi } from '../api/editor.api';
import { saveAs } from 'file-saver';
import { useState } from 'react';
import _ from 'lodash';
import { safe_get } from '../pages/utils';
import { build_save_payload } from '../features/editor/editor-state-hydration-utils';
import { toast } from 'react-toastify';


const default_stepper_states = [
  { id: 0, label: 'Draft with Cambri AI', active: false, completed: true },
  { id: 1, label: 'Refine with Cambri AI', active: false, completed: false },
  { id: 2, label: 'Check for Plagiarism', active: false, completed: false },
  { id: 3, label: 'Export Document', active: false, completed: false }
];

export interface EditorState {
  editor_document: {
    comments: any,
    document_id: number,
    editor_state: any,
    editor_text: string,
    extras: any,
    sop_text: string,
  };
  availableCredits: number;
  premium_user: boolean;
}

export interface EditorPayload {
  documentID: string | null | undefined;
  folderID: string | null | undefined;
}

interface PlagiarismResult {
  plagiarism_result: {
    status: number;
    results: {
      batch: any[];
      score: {
        identicalWords: number;
        aggregatedScore: number;
        minorChangedWords: number;
        relatedMeaningWords: number;
      };
      database: any[];
      internet: {
        id: string;
        url: string;
        tags: any[];
        title: string;
        metadata: {
          filename: string;
        };
        totalWords: number;
        introduction: string;
        matchedWords: number;
        similarWords: number;
        identicalWords: number;
        paraphrasedWords: number;
      }[];
      repositories: any[];
    };
    notifications: {
      alerts: any[];
    };
    scannedDocument: {
      scanId: string;
      credits: number;
      enabled: {
        aiDetection: boolean;
        plagiarismDetection: boolean;
      };
      metadata: {
        filename: string;
      };
      totalWords: number;
      creationTime: string;
      totalExcluded: number;
      expectedCredits: number;
    };
    developerPayload: {file_id:number,user_id:number};
  };
  availableCredits: number;
}

const initialPlagiarismObject: PlagiarismResult = {
  plagiarism_result: {
    status: 0,
    results: {
      batch: [],
      score: {
        identicalWords: 0,
        aggregatedScore: 999,
        minorChangedWords: 0,
        relatedMeaningWords: 0,
      },
      database: [],
      internet: [
        {
          id: 'dummy-id',
          url: 'http://example.com/',
          tags: [],
          title: 'Dummy Internet Source',
          metadata: {
            filename: 'dummy-source',
          },
          totalWords: 0,
          introduction: 'This is a dummy internet source.',
          matchedWords: 0,
          similarWords: 0,
          identicalWords: 0,
          paraphrasedWords: 0,
        },
      ],
      repositories: [],
    },
    notifications: {
      alerts: [],
    },
    scannedDocument: {
      scanId: 'dummy-scan-id',
      credits: 0,
      enabled: {
        aiDetection: false,
        plagiarismDetection: true,
      },
      metadata: {
        filename: 'dummy-file.txt',
      },
      totalWords: 0,
      creationTime: '2023-08-05T11:43:58.683606Z',
      totalExcluded: 0,
      expectedCredits: 0,
    },
    developerPayload: {'user_id': 0, 'file_id': 0},
  },
  availableCredits: 0,
};
export class EditorStore {

  loading = false;
  documentId = '';
  folderId = '';
  generatedOutput = '';
  editorState: EditorState;
  sopText = '';

  payload: EditorPayload | null = null;

  stepper_states = _.clone(default_stepper_states);
  stepper_show_toggle = true;

  history_pane_overaly_status = false;
  history = [];
save:boolean = false;
pdfDetails :any;
showPlagiarism = false;
  plagiarismTotalRequestDuration = 200000;
  plagiarismIntervalDuration = 30000;
  plagiarismRequestInProgress = false;
  plagiarism_result: PlagiarismResult = initialPlagiarismObject;
  plagiarismRequestLoading = false;
  plagiarismErrorOccured = false;
  availableCredits: number;

  constructor(private sopApi: SopApi, public editorApi: EditorApi) {
    makeAutoObservable(this);
    makePersistable(this, { name: 'EditorStore', properties: [], storage: window.localStorage });
  }
  @action
  async single_palgarism_request() {
    if (this.plagiarismRequestInProgress) {
      return {};
    }

    this.plagiarismRequestInProgress = true;
    const response = await this.sopApi.get_palgarism_details(this.documentId);
    if (response.status === 200) {
      this.plagiarismRequestInProgress = false;
      return response.data;
    } else {
      this.plagiarismRequestInProgress = false;
      toast.error(response.data, { position: 'top-center' });
      return {};
    }
  }

  @action
  async get_palgarism_details() {
    const requestStartTime = Date.now();
    const requestEndTime = requestStartTime + this.plagiarismTotalRequestDuration;

    while (Date.now() < requestEndTime) {
      const response = await this.single_palgarism_request();
      if (response && response.plagiarism_result) {
        this.plagiarism_result = response;
        this.availableCredits=response.availableCredits;
        break;
      }

      await new Promise((resolve) => setTimeout(resolve, this.plagiarismIntervalDuration));
    }
  }
  

  @action
  async sendPlagiarismRequest() {
    this.plagiarismRequestLoading = true;
    this.resetPlagiarsim();
    let payload ={input_text: this.sopText, file_id: this.documentId} ;
    //Remove for testing let payload = { input_text: p, file_id: this.sopId };
    const resp = await this.sopApi.submit_plagiarsim_scan(payload);
    if (resp.status === 200) {
      await this.get_palgarism_details();
      console.log(this.plagiarism_result.plagiarism_result.results.score,"AGGREGATESCORE1");
      if (
        safe_get(this.plagiarism_result.plagiarism_result.results.score, 'aggregatedScore', 999) ===
        999
      ) {
        console.log(this.plagiarism_result.plagiarism_result.results.score,"AGGREGATESCORE");
        this.plagiarismErrorOccured = true;
      }
    } else {
      this.plagiarismRequestLoading = false;
      toast.error(resp.data, { position: 'top-center' });
      this.plagiarism_result =initialPlagiarismObject;
    }
    this.plagiarismRequestLoading = false;
  }


@action
setPdfDetails(value){
  this.pdfDetails = value;
}
@action
setSave(value){
  this.save = value;
}
@action
resetPlagiarsim(){
  this.plagiarismRequestInProgress = false;
  this.plagiarismErrorOccured = false;
  this.plagiarism_result = initialPlagiarismObject;
}

  @action
  setDocumentID(id) {
    this.documentId = id;
  }

  @action
  setFolderId(folder_id) {
    this.folderId = folder_id;
  }

  @action
  setEditorPayload(payload) {
    this.payload = payload;
  }

  @action
  setLoading(loadstate) {
    this.loading = loadstate;
  }

  @action
  async get_generated_sop(sopId, folderId) {
    this.loading = true;
    const resp = await this.sopApi.get_generated_sop(sopId, folderId);
    this.generatedOutput = resp.data.output;
    if(resp.status === 200){
      this.loading = false;
    }
  }
  @action 
  setGeneratedOutput(value){
    this.generatedOutput = value
  }
  @action
  async get_editor(document_id) {
    this.loading = true;
    this.documentId = document_id;
    const resp = await this.editorApi.get_editor(document_id);
    this.editorState = resp.data.editor_document;
    this.sopText = resp.data.editor_document.sop_text;
    if(resp.status === 200){
    this.loading = false;
    }
    await this.fetch_document_history(document_id);
    return this.editorState;
  }

  @action
  async save_editor(editor) {
    this.loading = true;
    const payload = build_save_payload(editor, this.payload?.documentID, { },{} )
    console.log(payload)
    const resp = await this.editorApi.save_editor(payload);
    this.pdfDetails = resp.data.sop_text;
    this.loading = false;
    //this.editorState = resp.data.output;
    return resp.data.sop_text
  }

  @action
  stepper_display_toggle() {
    this.stepper_show_toggle = !this.stepper_show_toggle;
  }

  @action
  history_pane_toggle() {
    this.history_pane_overaly_status = !this.history_pane_overaly_status;
  }

  @action
  history_pane_show() {
    this.history_pane_overaly_status = true;
  }

  @action
  history_pane_hide() {
    this.history_pane_overaly_status = false;
  }

  @action
  stepper_update_refine() {
    const new_state = _.clone(this.stepper_states);
    new_state[1].completed = true;
    this.stepper_states = new_state;
  }

  @action
  stepper_update_originality() {
    const new_state = _.clone(this.stepper_states);
    new_state[2].completed = true;
    this.stepper_states = new_state;
  }

  @action
  stepper_update_export() {
    const new_state = _.clone(this.stepper_states);
    new_state[3].completed = true;
    this.stepper_states = new_state;
  }

  @action
  async fetch_document_history(documentID) {
    const response = await this.editorApi.get_history(documentID);
    if (response.status != 200) {
      this.history = [];
      return;
    }
    console.log(response.data);
    this.history = response.data;
  }

  @action
  document_text_replace(txt) {
    this.sopText = txt;
  }

  @action
  document_text_insert_at_start(txt) {
    this.sopText = `${txt}\n${this.sopText}`;
  }

  @action
  document_text_insert_at_end(txt) {
    this.sopText = `${this.sopText}\n${txt}`;
  }

  @action
  export_as_word(editor, html_nodes) {
    const fileBuffer = htmlDocx.asBlob(html_nodes);
    const path = window.location.pathname;
    let filename = path.includes('/lor') ? 'LOR.docx' : 'SOP.docx';
    saveAs(fileBuffer, filename);
  }
  @action
  clearEditorState() {
    this.loading = false;
    this.documentId = '';
    this.folderId = '';
    this.generatedOutput = '';
    //this.editorState = null; // Assuming editorState should be cleared/reset
    this.sopText = '';
    this.payload = null;
    this.stepper_states = _.clone(default_stepper_states);
    this.stepper_show_toggle = true;
    this.history_pane_overaly_status = false;
    this.history = [];
  }
}
