import React from "react";
import {action, makeAutoObservable} from "mobx";
import {DashboardApi} from "../api/dashboard-api";
import {HomeIcon, UsersIcon} from "@heroicons/react/24/outline";
import {makePersistable} from "mobx-persist-store";
import _ from "lodash";
import SOPTool from "../icons/Tool.svg"
import YourLibrary from "../icons/Library.svg"
import Reports from "../icons/reports.svg"
import ManageUsers from "../icons/Manage_users.svg"
import ManageTemplates from "../icons/manage_templates.svg"
export class DashboardStore {
  
  dashboardNavigation = [
    {name: 'Tools', href: '/dashboard', icon: SOPTool, current: true, isVisible : true},
    {name: 'Your Library', href: '/library', icon: YourLibrary, current: false , isVisible: true },
    {
      name: 'Reports',
      href: '/Reports',
      icon: Reports,
      current: false,
      isVisible : true
    },
    {
      name: 'Manage Templates',
      href: '/adminquestioneditor',
      icon: ManageTemplates,
      current: false,
      isVisible : false
    },
    {
      name: 'Manage Users',
      href: '/admincreditseditor',
      icon: ManageUsers,
      current: false,
      isVisible : false
    }
    
    // {name: 'SOP Tool', href: '/sop', icon: SOPTool, current: false},
    // {name: 'UK SOP Tool', href: '/uksop', icon: SOPTool, current: false},
    // {name: 'Canada SOP Tool', href: '/canadasop', icon: SOPTool, current: false},
  ]

  profileNavigation = [
    {name: 'Users List',current: true},
    {name: 'Plans & Pricing',current: false},
    // {name: 'Settings',current: false},
  ]

  showDashboardSideBar: boolean = false
  screenName: string = 'Tools'
  modalWarningDialog: boolean = false
  modalConfirmNavigatePath: string = ''


  constructor(private dashboardApi: DashboardApi) {
    makeAutoObservable(this);
    makePersistable(this, {name: 'DashboardStore', properties: [], storage: window.localStorage});
  }

  @action
  updateCurrentStatusForDashboardNav(stepName) {
    this.dashboardNavigation.map((step) => {
      if (step.name === stepName) {
        step.current = true
        this.screenName = step.name
      } else {
        step.current = false
      }
    })
  }

  @action
  updateCurrentStatusForProfileNav(stepName) {
    this.profileNavigation.map((step) => {
      if (step.name === stepName) {
        step.current = true
        this.screenName = step.name
      } else {
        step.current = false
      }
    })
  }

  @action
  setShowDashboardSideBar(isShow) {
    this.showDashboardSideBar = isShow
  }

  @action
  toggleShowDashboardSideBar() {
    this.showDashboardSideBar = !(this.showDashboardSideBar)
  }

  @action
  setScreenName(screen_name) {
    this.screenName = screen_name
  }

  @action
  getActiveOption() {
    const matches = _.filter(this.dashboardNavigation, {current: true})
    return matches[0]?.name
  }

  @action
  getActiveProfileOption() {
    const matches = _.filter(this.profileNavigation, {current: true})
    return matches[0].name
  }

  @action
  setModalWarningDialog(flag) {
    this.modalWarningDialog = flag
  }

  @action
  setModalConfirmNavigatePath(path) {
    this.modalConfirmNavigatePath = path
  }

}
