import {action, makeAutoObservable, runInAction, toJS} from "mobx";
import {AdminApi} from "../api/admin-api";
import {makePersistable, clearPersistedStore} from 'mobx-persist-store';
import {toast} from "react-toastify";



export class AdminStore {
  templateData = {
    toolid: '',
    screenid: '',
    questionid: '',
    templateId: '',
    description:'',
    content: '',
  };

  toolOptions = [];
  screenOptions = [];
  questionOptions: { label: string; value: string }[] = [];
  templateOptions: { label: string; value: string }[] = [];
  templates = [];

  selectedScreen = '';

  selctedQuestion = '';

  templatesMetadata = {};

  constructor(private adminApi: AdminApi) {
    makeAutoObservable(this);
    makePersistable(this, {
      name: 'AdminStore',
      properties: ['templateData', 'templatesMetadata'],
      storage: window.localStorage,
    });
  }

  @action 
  async clear_questions_templates(){
    this.selctedQuestion =''
  }

  @action
  async get_templates_metadata() {
    const resp = await this.adminApi.get_templates_metadata();
    // runInAction(() => {
    //   this.templatesMetadata = data;
    //   this.getToolOptions();
    //   this.getScreenOptios();
    //   console.log(toJS(this.screenOptions), toJS(this.toolOptions));
    // });
    runInAction(() => {
      if (resp.status === 200) {
        if (resp.data) {
          this.templatesMetadata = resp.data;
          this.getToolOptions();
          this.getScreenOptios();
        }
      }
    });
  }

  @action 
  async get_template(){
    return await this.adminApi.get_template();
  }

  @action
  async create_update_templates() {
    const resp = await this.adminApi.create_update_templates(this.templateData);
    // runInAction(() => {
    //   this.templatesMetadata = data;
    //   this.getToolOptions();
    //   this.getScreenOptios();
    //   console.log(toJS(this.screenOptions), toJS(this.toolOptions));
    // });
    runInAction(() => {
      if (resp.status === 200) {
        if (resp.data) {
          this.selctedQuestion = ''
          toast.success(resp.data)
        }
      }
      else {
        toast.error(resp.data)
      }
    });
  }

  @action
  async update_selected_screen(key: string) {
    this.selectedScreen = key;
  }

  @action
  async updateContentAndDescription(key: string){
    if (
        this.templatesMetadata['templates'][this.selctedQuestion] &&
        this.templatesMetadata['templates'][this.selctedQuestion].length > 0 && key !== ''
      ) {
        let templateSelected = this.templatesMetadata['templates'][this.selctedQuestion].filter(
          (element) => {
            //let key = Object.keys(element)[0];
            return   element['templateId'] === key;
           // return { label: this.trimString(element['content']), value: element['templateId'] };
          }

        );

        this.updateTemplateData('description', templateSelected[0]['description']);
        this.updateTemplateData('content', templateSelected[0]['content']);
      } else {
        
        this.updateTemplateData('description', '');
        this.updateTemplateData('content', '');
      }
  }

  @action
  async update_selected_question(key: string) {
    this.selctedQuestion = key;
  }

  @action
  async getToolOptions() {
    this.toolOptions = this.templatesMetadata['templateId'].map((element) => {
      let key = Object.keys(element)[0];
      return { label: element[key], value: key };
    });
  }

  @action
  updateTemplateData(key, value) {
    this.templateData = {
      ...this.templateData,
      [key]: value,
    };
  }

  @action
  async getScreenOptios() {
    this.screenOptions = this.templatesMetadata['screens'].map((element) => {
      let key = Object.keys(element)[0];
      return { label: element[key], value: key };
    });
  }
  @action
  async delete_template(id) {
    return await this.adminApi.delete_template(id);
}

 
  @action
  async getQuestionOptios() {
    if (this.selectedScreen && this.selectedScreen !== '') {
      let questionOptions = this.templatesMetadata['Questions'].filter((el) => {
        let key = Object.keys(el)[0];
        return key === this.selectedScreen;
      });

      this.questionOptions = questionOptions[0][this.selectedScreen].map((element) => {
        let key = Object.keys(element)[0];
        return { label: element[key], value: key };
      });
    }
  }

  trimString(str) {
    if (str.length > 100) {
      return str.substring(0, 100) + '...';
    }
    return str;
  }

  @action
  async getTemplateOptios() {
    if (this.selctedQuestion && this.selctedQuestion !== '') {

      if (
        this.templatesMetadata['templates'][this.selctedQuestion] &&
        this.templatesMetadata['templates'][this.selctedQuestion].length > 0
      ) {
        this.templateOptions = this.templatesMetadata['templates'][this.selctedQuestion].map(
          (element) => {
            let key = Object.keys(element)[0];
            return { label: this.trimString(element['content']), value: element['templateId'] };
          }
        );
      } else {
        this.templateOptions = [];
      }
    }
  }
}
