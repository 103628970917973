import { useState } from 'react';
import { PlusButton } from '../../../components/plus-button/plus-button';
import { MinusButton } from '../../../components/plus-button/minus-button/minus-button';
import { v4 as uuidv4 } from 'uuid';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../hooks/use-store';
import { safe_get } from '../../utils';
const FetchSkills = observer((props: any) => {
  const { userProfileStore , resumeStore } = useStore();
  const [stateData, setStateData] = useState<any[]>([]);
  const selectedSkills = userProfileStore.userProfilejsonData.skills as string[] || [];
    const defaultState = [
    {
      id: uuidv4(),
      name: 'Level of Education',
    },
    {
      id: uuidv4(),
      name: 'Field of Study',
    },
    {
      id: uuidv4(),
      name: 'Leadership',
    },
    {
      id: uuidv4(),
      name: 'Managing',
    },
    {
      id: uuidv4(),
      name: 'SQL',
    },
    {
      id: uuidv4(),
      name: 'Java Script',
    },
  ];
  const handleClick = (name: string) => {
    userProfileStore.setSkillsSelected(true);
    // Update the store state
    userProfileStore.updateUserProfileJsonData('skills', [...selectedSkills, name]);
  };

  const handleUndo = (selectedSkill: string) => {
    // Update the store state
    const updatedSkills = selectedSkills.filter((skill: string) => skill !== selectedSkill);
    userProfileStore.updateUserProfileJsonData('skills', updatedSkills);
  };

  // if (selectedSkills.length === 0 ||selectedSkills.length === 1 ) {
  //   // Set default state if selectedSkills is empty
  //   userProfileStore.updateUserProfileJsonData('skills', defaultState.map(skill => skill.name));
  // }
  
  return (
    <>
      {defaultState.map((item, index) => (
        
        <div className="flex items-center" key={item.id}>
          <button
            className={`flex border border-[#C7CBD4] rounded-md px-3 py-2 w-full my-2 text-[#4E5664] text-sm ${
              selectedSkills.includes(item.name) ? 'bg-gray-200' : ''
            }`}
          >
            {item.name}
          </button>
          <div className="flex items-center justify-center">
            {!selectedSkills.includes(item.name) && (
              <PlusButton
                name={item.name}
                handleClick={() => handleClick(item.name)}
                disabled={!userProfileStore.skillsSelected && selectedSkills.length > 0}
              />
            )}
            {selectedSkills.includes(item.name) && (
              <MinusButton
                index={index}
                handleUndo={() => handleUndo(item.name)}
                disabled={!userProfileStore.skillsSelected}
              />
            )}
          </div>
        </div>
      ))}
    </>
  );
});

export default FetchSkills;