import { observer } from 'mobx-react-lite';
import FetchSkills from './fetch-skills';
import { useStore } from '../../../hooks/use-store';
import { useState, useEffect } from 'react';
import { toJS } from 'mobx';

const Skills = observer((props: any) => {
  const { userProfileStore , resumeStore } = useStore();
  const [value, setvalue] = useState('');
  useEffect(() => {
    let skills = userProfileStore.userProfilejsonData.skills?.join(',') ?? '';
    if (skills.startsWith(',')) {
      skills = skills.substring(1); // Remove leading comma and space
    }
    
    setvalue(skills);
  }, [userProfileStore.userProfilejsonData.skills, userProfileStore]);
  
  

  const onChangeHandler = (e) => {
    setvalue(e.target.value);
    const skills = e.target.value.split(',')
    //const skillsFiltered = skills.filter((el)=> el.trim()!=='')
    //setvalue(skills);
userProfileStore.updateUserProfileJsonData('skills', skills);
  };
  

  return (
    <>
      <div className="mt-1 px-24 overflow-y-auto">
        <div className="w-full">
          <label className="left-0 -top-3.5 text-[#737E93] mb-[4px] text-[20px] leading-[28px] peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400 peer-placeholder-shown:top-2 peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm">
            Add a skill (Maximum 5)
          </label>
          <input
            name="skills"
            type="text"
            className="rounded-lg mt-[4px] bg-[#FEFEFE] mb-[24px] px-[10px] peer h-10 w-full border border-[#C7CBD4] text-gray-900 focus:outline-none focus:border-indigo-600"
            placeholder="Enter skills ex: sql, java, nodejs"
            onChange={onChangeHandler}
            value={!userProfileStore.skillsSelected ? "" :value}
            slot="skills"
          />
        </div>
        <p className="text-[20px] leading-[28px] text-[#4E5664] font-bold mb-2 ">
          Do you want to add any of these skills?
        </p>
        <div className="flex justify-between flex-col w-full">
          <FetchSkills />
        </div>
      </div>
    </>
  );
});

export default Skills;
