import { useState, useEffect } from 'react';
import { useStore } from '../../hooks/use-store';
import { observer } from 'mobx-react-lite';
import { upload_file_to_s3 } from '../utils';
import { FileUploader } from 'react-drag-drop-files';
import {toast} from "react-toastify";
import LOGO from "../../icons/cambrilogo.svg"
import RadioButton from '../../components/radio-button/radio-button';
import UploadResume from'../../icons/Upload Resume.svg';
export const ResumeModalStepUploadCV = observer((props:any) => {
  const { userStore, resumeStore, userProfileStore } = useStore();
  const [processing, set_processing] = useState(false);
  const [processing_failed, set_processing_failed] = useState(false);
  const [display_progress, set_display_progress] = useState('');
  const [upload_error, set_upload_error] = useState(false);

  const handle_file_upload = async (new_file) => {
    resumeStore.setContinueButton(true)
    resumeStore.setBackButton(true)
    try {
      set_upload_error(false);
      set_processing(true);
      set_display_progress('Uploading your resume...');
      const s3path = await upload_file_to_s3('cv', new_file, `u_${userStore.id}_cv`);
      const public_url = s3path.location;
      set_display_progress(
        'Upload complete. Hang on while we process your resume. This might take a minute or two...'
      );
      const response = await resumeStore.api.get_structured_data_from_cv({url:public_url,user_name:userProfileStore.userProfilejsonData.user_name});
      if(response.status===400)
      {
        toast.error(response.data, {position: "top-center"})
        set_display_progress('');
        set_processing(false);
        set_processing_failed(true);
      }
      else{
      const structured_data = response.data;
      resumeStore.set_extracted_resume(structured_data);
      userProfileStore.set_extracted_resume(structured_data);

      set_display_progress('');
      set_processing(false);
      set_processing_failed(false);
      resumeStore.setProcessing_complete(true);
      resumeStore.setContinueButton(false)
      resumeStore.setBackButton(false)
      }
    } catch (e) {
      set_upload_error(true);
      resumeStore.setContinueButton(false)
      resumeStore.setBackButton(false)
    }
  };

  const handle_form_submit = async (event) => {
    console.log(event);
  };

  const InitialChoice = (innerprops) => {
    const card_style = 'shadow-lg rounded-xl mx-auto px-2 py-2 cursor-pointer';
    const handleCardClick = (cardName) => {
      props.setSelectedCard(cardName);
      resumeStore.setBackButton(false);
      resumeStore.setClearResumeData(true);
    };

    return (
      <>
        <div
          className={`${
            props.selectedCard == 'cambri' && 'border border-[#A033FF] bg-[#FCF3FF]'
          } ${card_style} mb-10 `}
          style={{ width: 300, height: 150 }}
          onClick={() => {
            resumeStore.setContinueButton(false);
            handleCardClick('cambri');
            resumeStore.setUploadScreen('initial-choice');
            if(resumeStore.clearResumeData === true){
              userProfileStore.userProfilejsonData = {
                ...userProfileStore.defaultUserProfileJsonData,
                user_name: userProfileStore.userProfilejsonData.user_name
              };
              
            }
          }}
        >
          <div className="flex justify-end relative">
          <RadioButton checked={props.selectedCard == 'cambri'} />
          </div>
          <div className="flex flex-col">
            <img
              className="mx-auto"
              src={LOGO}
              style={{ height: '30px' }}
              alt="Cambri"
            />
            <div className="text-[24px] leading-[32px] mx-auto pt-2 text-[#3C424C]">Build a Cambri Resume</div>
          </div>
        </div>

        <div
          className={`${
            props.selectedCard == 'upload' && 'border border-[#A033FF] bg-[#FCF3FF]'
          } ${card_style} mb-10 `}
          style={{ width: 300, height: 150 }}
          onClick={() => {
            resumeStore.setContinueButton(true);
            handleCardClick('upload');
            resumeStore.setUploadScreen('upload-doc');
            if(resumeStore.clearResumeData === true){
              userProfileStore.userProfilejsonData = {
                ...userProfileStore.defaultUserProfileJsonData,
                user_name: userProfileStore.userProfilejsonData.user_name
              };
            }
          }}
        >
          <div className="flex justify-end relative">
            <RadioButton checked={props.selectedCard == 'upload'} />
          </div>
          <div className="flex flex-col">
          <img src={UploadResume} className='w-[18.25rem] h-9 -mt-[2rem]'/>
            <div className="text-[24px] leading-[32px] mx-auto pt-2 text-[#3C424C]">Upload Resume</div>
          </div>
        </div>
      </>
    );
  };

  const UploadScreen = (innerprops) => {
    useEffect(() => {
      resumeStore.setNavigate(true);
    });
    return (
      <>
        <div className="flex flex-col mx-auto my-auto justify-center " style={{ width: 400 }}>
          <div className="text-gray-500 py-4 text-[16px] leading-[22px]">Attach Resume</div>
          <form name="cv-upload" onSubmit={handle_form_submit}>
            <FileUploader
              handleChange={handle_file_upload}
              name="cv-upload"
              multiple={false}
              maxSize={5}
              types={['pdf','docx']}
            />
          </form>
          <div className="text-gray-400 text-[12px] leading-[16px] italic  py-4 ">
            Note: File Format should be .pdf and .doc only and file size should be less than 5 MB.
          </div>

          {upload_error ? (
            <>
              <div className="text-red-500 italic text-sm">
                Error occurred while uploading your resume. Please try again.
              </div>
            </>
          ) : null}

          {processing ? (
            <>
              <div className="flex flex-row my-2 items-center">
                <i className="fa-solid fa-spinner fa-spin fa-2x mx-2" />
                <div className="text-gray-600 italic ">{display_progress}</div>
              </div>
            </>
          ) : null}

          {resumeStore.processing_complete ? (
            <>
              <div className="flex flex-row my-2 items-center">
                <i className="fa-duotone fa-circle-check fa-2x mx-2 text-green-600"></i>
                <div className="text-gray-600">
                  Your resume has now been extracted. Please proceed to next screens to verify and
                  update your details.
                </div>
              </div>
            </>
          ) : null}
          
          {processing_failed ? (
            <>
              <div className="flex flex-row my-2 items-center">
                <i className="fa-duotone fa-circle-xmark fa-2x mx-2 text-red-600"></i>
                <div className="text-gray-600">
                  Your resume has not been extracted
                </div>
              </div>
            </>
          ) : null}
        </div>
      </>
    );
  };

  return (
    <>
      {/* Call the representive screens based on  what card we selected.  */}
      {resumeStore.upload_screen == 'initial-choice' ? (
        <InitialChoice />
      ) : resumeStore.upload_screen == 'upload-doc' && resumeStore.continueClicked ? (
        <UploadScreen />
      ) : (
        <InitialChoice />
      )}
    </>
  );
});
