import { action, makeAutoObservable, runInAction, toJS } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
import { safe_get } from '../pages/utils';
import _, { String, clone, cloneDeep } from 'lodash';
import { toast } from 'react-toastify';
import { ResumeAPI } from '../api/resume-api';
import { UserProfileApi } from '../api/user-profile-api';
import dayjs from 'dayjs';
import { act } from 'react-dom/test-utils';
import { ContentApi } from '../api/content-api';

const sop_selection_progress_stepper_states = [
  {
    num: 0,
    key: 'users',
    name: 'Select Student'
  },
  {
    num: 1,
    key: 'name',
    name: 'Name of SOP/LOR'
  }

];

export class SopProfileSelectionStore {
  current_sop_selection_step_num: number = sop_selection_progress_stepper_states[0]['num'];
  current_sop_selection_step: any = sop_selection_progress_stepper_states[0];
  showSopSelectionCreationModal: boolean = false;
  sopSelectionContinueClicked: boolean = false;
  sopSelectionContinueButton: boolean = true;
  sopSelectionBackButton: boolean = true;
  sopSelectionNavigate: boolean = false;

  matches: any;
  sopProfileSelection: { label: string; folder_id: string; profile_id: string } | null = null;
  sopProfileSelectionDropdownValues: { label: string; value: string; folder_id: string }[] = [];
  sopProfileSelectionValues: { user_name: string; profile_id: string; folder_id: string }[] = [];
  sopProfileSelectionLorDropdownValues: { label: string; value: string }[] = [];

  sopSelection: { id: string; name: string; parent_id: string; tool_id: string; lastActiveStep: string; isEmpty: boolean; } | null = null;
  sopSelectionDropdownValues: { label: string; value: string; id: string; }[] = [];
  sopSelectionValues: { id: string; name: string; parentid: string; tool_id: string; lastActiveStep: string; isEmpty: boolean; }[] = [];
  sopSelectionLorDropdownValues: { label: string; value: string; }[] = [];
  current_sop_name: string = '';

  sopSelectionEdit: boolean = false;
  sopProfileFolderId: any = null;
  sopSelectionParentId: boolean = false;

  userprofile = [];
  userDetails: any = [];
  userSops: any = [];
  availableCredits: number;
  userName: string;
  countryName: any;
    activate_tool_id: any = -1;
  sop_selected: any = null;
  userSelectionClick: boolean = false;
  clearSopStep: boolean = false;
  sopName:any;
  sopNameInput:any;

  @action setClearSopStep(value) {
    this.clearSopStep = value;
  }


  @action
  setUserSelectionClick(value) {
    this.userSelectionClick = value;
  }

  @action
  setSelectionParentId(value: any) {
    this.sopSelectionParentId = value;
}

  @action
  setProfileSelection(profile: any) {
    this.sopProfileSelection = profile;
  }

  @action
  set_current_sop_name(sop: any) {
    this.current_sop_name = sop;
  }

  @action
  reset_current_sop_name() {
    this.current_sop_name = '';
  }

  @action
  setSopSelectionEdit(value: any) {
    this.sopSelectionEdit = value;
  }

  constructor(private userProfileApi: UserProfileApi, private contentApi: ContentApi) {
    makeAutoObservable(this);
    makePersistable(this, {
      name: 'SopProfileSelectionStore',
      properties: [],
      storage: window.localStorage
    });
  }

  @action
  resetSopSelection() {
    this.sopSelectionEdit = false;
  }

  @action set_current_step(value, name) {
    this.current_sop_selection_step_num = value;
    this.current_sop_selection_step['num'] = value;

    if (this.sopProfileSelectionValues.length <= 0) {
      this.current_sop_selection_step['name'] = 'Enter Your Details For Creating SOP';
    } else {
      this.current_sop_selection_step['name'] = name;
    }
  }

  @action
  setSopSelectioNavigate(value) {
    this.sopSelectionNavigate = value;
  }

  @action
  async getSopProfileSelectionDropdownValues() {
    const response = await this.userProfileApi.get_user_profile_name_id_list();
    if (response.status == 200) {
      this.sopProfileSelectionValues = response.data.user_profiles;
      let currentSopName;
      this.sopProfileSelectionValues.forEach((item) => {
        currentSopName = item.user_name;
      });
      this.sopName = currentSopName;
      const date = dayjs().format('DD-MM-YY hh_mm A');
      this.sopNameInput = `${currentSopName} - ${this.countryName} - ${date}`;
      this.setSopProfileSelectionDropdownValues();
    } else {
      this.sopProfileSelectionValues = [];
      this.sopProfileSelectionDropdownValues = [];
    }
  }

@action
setSopNameInput(value){
  this.sopNameInput = value
}

  @action
  setSopProfileSelectionDropdownValues() {
    this.sopProfileSelectionDropdownValues = this.sopProfileSelectionValues.map((item) => {
      return {
        label: item.user_name !== null ? item.user_name : '',
        value: item.profile_id,
        folder_id: item.folder_id
      };
    });
    this.sopProfileSelectionLorDropdownValues = this.sopProfileSelectionValues.map((item) => {
      return {
        label: item.user_name !== null ? item.user_name : '',
        value: item.profile_id
      };
    });
  }

  @action
  setSopSelectionDropdownValues() {
    this.sopSelectionDropdownValues = this.sopSelectionValues.map((item) => {
      return {
        label: item.name !== null ? item.name : '',
        value: item.id,
        id: item.id
      };
    });
    this.sopSelectionLorDropdownValues = this.sopSelectionValues.map((item) => {
      return {
        label: item.name !== null ? item.name : '',
        value: item.id
      };
    });
  }

  @action
  async checkUser() {
    if (this.sopProfileSelection !== null) {
      const payload = {
        name: this.sopProfileSelection.label,
        profile_id: this.sopProfileSelection.profile_id
      };
      const resp = await this.userProfileApi.check_profile(payload);
      this.userName = resp.data.user_name;
    }
  }

  @action
  setSopSelectionContinueClicked(value) {
    this.sopSelectionContinueClicked = value;
  }

  @action
  setSopSelectionContinueButton(value) {
    this.sopSelectionContinueButton = value;
  }

  @action
  setSopSelectionBackButton(value) {
    this.sopSelectionBackButton = value;
  }

  @action
  show_sop_selection_creation_modal() {
    this.showSopSelectionCreationModal = true;
  }

  @action
  hide_sop_selection_creation_modal() {
    this.showSopSelectionCreationModal = false;
  }
@action 
setSopProfileSelectionValues(value:any){
  this.sopProfileSelectionValues = value;
}
  @action
  sop_selection_progress_nav_next() {
    const new_idx = this.current_sop_selection_step_num + 1;
    if (new_idx >= sop_selection_progress_stepper_states.length) return;
    this.current_sop_selection_step_num = new_idx;
    this.current_sop_selection_step = sop_selection_progress_stepper_states[new_idx];
    console.log('Step:', this.current_sop_selection_step_num);
  }

  @action
  async get_user_profile_name_id_list() {
    const resp = await this.userProfileApi.get_user_profile_name_id_list();
    this.sopProfileSelectionValues = resp.data.user_profiles;
    this.userprofile = resp.data.user_profiles;
    let radiobuttons = this.userprofile
      .filter((user: any) => user.user_name !== null && user.user_name !== undefined)
      .map((user: any) => {
        return { 'user_name': user.user_name, 'profile_id': user.profile_id };
      });
      let currentSopName;
      this.userprofile.forEach((item:any) => {
        currentSopName = item.user_name;
      });
    this.userDetails = radiobuttons;
    this.availableCredits = resp.data.availableCredits;
  }

  @action
  sop_selection_progress_nav_previous() {
    const new_idx = this.current_sop_selection_step_num - 1;
    if (new_idx < 0) return;
    this.current_sop_selection_step_num = new_idx;
    this.current_sop_selection_step = sop_selection_progress_stepper_states[new_idx];
  }

  @action
  sop_selection_progress_is_last_step() {
    return this.current_sop_selection_step_num === sop_selection_progress_stepper_states.length - 1;
  }

  @action
  sop_selection_progress_get_current_state() {
    return `${this.current_sop_selection_step_num + 1}/${
      sop_selection_progress_stepper_states.length
    }`;
  }

  @action
  sop_selection_progress_get_previous_state() {
    return `${this.current_sop_selection_step_num - 1}/${
      sop_selection_progress_stepper_states.length
    }`;
  }

  @action
  sop_selection_progress_stepper_status() {
    const pre = this.current_sop_selection_step_num;
    const post =
      sop_selection_progress_stepper_states.length - this.current_sop_selection_step_num - 1;
    return { pre, post };
  }

  @action
  resetState() {
    this.showSopSelectionCreationModal = false;
    this.current_sop_selection_step_num = sop_selection_progress_stepper_states[0]['num'];
    this.current_sop_selection_step = sop_selection_progress_stepper_states[0];
    this.sopSelectionContinueClicked = false;
    this.sopSelectionNavigate = false;
    this.sopSelectionContinueButton = true;
    this.sopSelectionBackButton = true;
    //this.current_sop_name='';
  }

  @action
  getSelectValues() {
    //const resp = await this.userProfileApi.get_user_profile_name_id_list();
    const payload = [];
    for (let x of toJS(this.sopProfileSelectionValues)) {
      // @ts-ignore
      if (x['profile_id'] && x['user_name']) payload.push({ 'label': x['user_name'], 'value': x['profile_id'] });
    }
    return payload;
  }

  @action
  getSelectedValues() {
    //const resp = await this.userProfileApi.get_user_profile_name_id_list();
    const payload = [];
    console.log('toJS(this.sopSelectionValues)', toJS(this.sopSelectionValues));
    for (let x of toJS(this.sopSelectionValues)) {
      // @ts-ignore
      if (x['id'] && x['name']) payload.push({ 'label': x['name'], 'value': x['id'] });
    }
    return payload;
  }

  @action
  async select_student(userid) {
    try {
      const matches = _.filter(this.sopProfileSelectionValues, x => x['profile_id'] == userid);
      const user_profile_meta = toJS(matches[0]);
      user_profile_meta['label'] = user_profile_meta['user_name'];
      // @ts-ignore
      this.sopProfileSelection = user_profile_meta;
      this.default_sop_name_generator();
      this.build_document_list();

      /*
      const response = await this.userProfileApi.get_profile_by_id(user_profile_meta['profile_id']);
      if (response.status == 200) {
        const profile = response.data;
        this.sopProfileSelection = profile;
      } else {
      }
      */
    } catch (e) {
      this.sopProfileSelection = null;
      this.default_sop_name_generator();
    }

  }

  @action
  async select_sop(id) {
    try {
      const matches = _.filter(this.sopSelectionValues, x => x['id'] == id);
      this.sop_selected = matches[0];
      console.log(this.sop_selected);
      this.current_sop_name = '';
    } catch (e) {
      this.sop_selected = null;
      this.sopSelection = null;
    }
  }

  @action
  get_selected_sop_obj_by_name(name) {
    const matches = _.filter(this.sopSelectionValues, x => x['name'] === name);
    return matches[0];
  }

  @action
  get_selected_sop_obj_by_id(id) {
    const matches = _.filter(this.sopSelectionValues, x => x['id'] === id);
    return matches[0];
  }

  @action
  reset_selected_sop() {
    this.sop_selected = null;
  }

  @action
  default_sop_name_generator() {
    try {
      const date = dayjs().format('DD-MM-YY hh_mm A');
      let name = this.sopProfileSelection?.label;
      console.log(toJS(this.sopProfileSelection), 'PATHNAME');
      // @ts-ignore
      name = name.charAt(0).toUpperCase() + name.slice(1);
      this.current_sop_name = `${name} - ${this.countryName} - ${date}`;
    } catch (e) {
      this.current_sop_name = '';
    }
  }

  @action
  isStepperDisabled(isEnterprise) {
    if (isEnterprise) {
      switch (this.current_sop_selection_step_num) {
        case 0:
          return (this.sopProfileSelection === null);

        case 1:
          return this.current_sop_name.trim().length === 0 && (this.sop_selected === null || this.sop_selected === undefined);

        default:
          return false;
      }
    } else {
      return this.current_sop_name.trim().length === 0 && (this.sop_selected === null || this.sop_selected === undefined);
      //return true
    }

  }

  @action
  getSelectedUserProfileValueForSelectDropDownState() {
    try {
      if (this.sopProfileSelection === null) return null;
      const profile_id = this.sopProfileSelection['profile_id'];
      const values = this.getSelectValues();
      const matches = _.filter(values, x => x['value'] == profile_id);
      return matches[0];
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  @action
  getSelectedSopForSelectDropDownState() {
    try {
      if (this.sopSelection === null) return null;
      const id = this.sopSelection['id'];
      const values = this.getSelectedValues();
      const matches = _.filter(values, x => x['value'] == id);
      return matches[0];
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  @action
  async build_document_list() {
    if (this.sopProfileSelection == null) {
      return;
    }

    const response = await this.userProfileApi.fetch_sop_by_userprofile(this.sopProfileSelection.profile_id, this.activate_tool_id);
    if (response.status == 200) {
      this.sopSelectionValues = response.data.sops;
      this.setSopProfileSelectionDropdownValues();
    } else {
      this.sopProfileSelectionValues = [];
      this.sopProfileSelectionDropdownValues = [];
    }
    let radiobuttons = this.userSops
      .filter((sop: any) => sop.name !== null && sop.name !== undefined)
      .map((sop: any) => {
        return { 'user_name': sop.name, 'profile_id': sop.id };
      });
    this.userSops = radiobuttons;
    this.availableCredits = response.data.availableCredits;
    /*const collector = [];

    const parse_file_folder_node = (node) => {
      const isfile = safe_get(node, 'isfile', false)
      if(isfile){
        // @ts-ignore
        collector.push(node)
      }
      const children = safe_get(node, 'children', [])
      for(let x of children) {
        parse_file_folder_node(x);
      }
    }*/

    if (response.status === 200) {
      /*for(let x of response.data['contents']) {
        parse_file_folder_node(x);
      }*/
      console.log(response.data);
    } else {

    }
  }

  @action
  set_active_tool_id(tool_id) {
    this.activate_tool_id = tool_id;
  }

}
