import { AutoFocusPlugin } from '@lexical/react/LexicalAutoFocusPlugin';
import { CharacterLimitPlugin } from '@lexical/react/LexicalCharacterLimitPlugin';
import { CheckListPlugin } from '@lexical/react/LexicalCheckListPlugin';
import { ClearEditorPlugin } from '@lexical/react/LexicalClearEditorPlugin';
import LexicalClickableLinkPlugin from '@lexical/react/LexicalClickableLinkPlugin';
import { CollaborationPlugin } from '@lexical/react/LexicalCollaborationPlugin';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { HashtagPlugin } from '@lexical/react/LexicalHashtagPlugin';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { HorizontalRulePlugin } from '@lexical/react/LexicalHorizontalRulePlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { PlainTextPlugin } from '@lexical/react/LexicalPlainTextPlugin';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { TabIndentationPlugin } from '@lexical/react/LexicalTabIndentationPlugin';
import { TablePlugin } from '@lexical/react/LexicalTablePlugin';
import useLexicalEditable from '@lexical/react/useLexicalEditable';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { CAN_USE_DOM } from './shared/canUseDOM';
import { LexicalComposer } from '@lexical/react/LexicalComposer';

//import {createWebsocketProvider} from './collaboration';
import { useSettings } from './context/SettingsContext';
import { useSharedHistoryContext } from './context/SharedHistoryContext';
import TableCellNodes from './nodes/TableCellNodes';
import ActionsPlugin from './plugins/ActionsPlugin';
import AutocompletePlugin from './plugins/AutocompletePlugin';
import AutoEmbedPlugin from './plugins/AutoEmbedPlugin';
import AutoLinkPlugin from './plugins/AutoLinkPlugin';
import CodeActionMenuPlugin from './plugins/CodeActionMenuPlugin';
import CodeHighlightPlugin from './plugins/CodeHighlightPlugin';
import CollapsiblePlugin from './plugins/CollapsiblePlugin';
import CommentPlugin from './plugins/CommentPlugin';
import ComponentPickerPlugin from './plugins/ComponentPickerPlugin';
import ContextMenuPlugin from './plugins/ContextMenuPlugin';
import DragDropPaste from './plugins/DragDropPastePlugin';
import DraggableBlockPlugin from './plugins/DraggableBlockPlugin';
import EmojiPickerPlugin from './plugins/EmojiPickerPlugin';
import EmojisPlugin from './plugins/EmojisPlugin';
// import EquationsPlugin from './plugins/EquationsPlugin';
import ExcalidrawPlugin from './plugins/ExcalidrawPlugin';
import FigmaPlugin from './plugins/FigmaPlugin';
import FloatingLinkEditorPlugin from './plugins/FloatingLinkEditorPlugin';
import FloatingTextFormatToolbarPlugin from './plugins/FloatingTextFormatToolbarPlugin';
import ImagesPlugin from './plugins/ImagesPlugin';
import InlineImagePlugin from './plugins/InlineImagePlugin';
import KeywordsPlugin from './plugins/KeywordsPlugin';
import LinkPlugin from './plugins/LinkPlugin';
import ListMaxIndentLevelPlugin from './plugins/ListMaxIndentLevelPlugin';
import MarkdownShortcutPlugin from './plugins/MarkdownShortcutPlugin';
import { MaxLengthPlugin } from './plugins/MaxLengthPlugin';
import MentionsPlugin from './plugins/MentionsPlugin';
import PollPlugin from './plugins/PollPlugin';
import SpeechToTextPlugin from './plugins/SpeechToTextPlugin';
import TabFocusPlugin from './plugins/TabFocusPlugin';
import TableCellActionMenuPlugin from './plugins/TableActionMenuPlugin';
import TableCellResizer from './plugins/TableCellResizer';
import TableOfContentsPlugin from './plugins/TableOfContentsPlugin';
import { TablePlugin as NewTablePlugin } from './plugins/TablePlugin';
import ToolbarPlugin from './plugins/ToolbarPlugin';
import TreeViewPlugin from './plugins/TreeViewPlugin';
import TwitterPlugin from './plugins/TwitterPlugin';
import YouTubePlugin from './plugins/YouTubePlugin';
import PlaygroundEditorTheme from './themes/PlaygroundEditorTheme';
import ContentEditable from './ui/ContentEditable';
import Placeholder from './ui/Placeholder';
import AIAssistHomeScreen from './aiassist/ai-assist-home-screen';
import { FloatingMenuPlugin } from './plugins/FloatingMenuPlugin';
import { createCommand } from 'lexical';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { useStore } from '../../hooks/use-store';
import { SharedHistoryContext } from './context/SharedHistoryContext';
import {
  $createParagraphNode,
  $createTextNode,
  $getRoot,
  $createLineBreakNode
} from "lexical";
import EditorNodes from './nodes/EditorNodes';
import { EditorState } from '../../store/editor-store';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

const skipCollaborationInit =
  // @ts-ignore
  window.parent != null && window.parent.frames.right === window;

export const SHOW_AI_ASSIST_HOME = createCommand<void>();
export const HIDE_AI_ASSIST_HOME = createCommand<void>();

// interface EditorState {
//   editor_document: {
//     sop_text: {
//       choices: string[];
//     }
//     editor_text: string;
//     document_id: number;
//   };
//   availableCredits: number;
//   premium_user: boolean;
// }

const SimpleEditor = observer((props): JSX.Element => {
  const { historyState } = useSharedHistoryContext();
  const { editorStore}= useStore();
  const [editor] = useLexicalComposerContext();
  let { id, folderid  } = useParams();
  const initialConfig = {
    editorState: null,
    namespace: 'Cambri',
    nodes: [...EditorNodes],
    onError: (error: Error) => {
      throw error;
    },
    theme: PlaygroundEditorTheme
  };
  const {
    settings: {
      isCollab,
      isAutocomplete,
      isMaxLength,
      isCharLimit,
      isCharLimitUtf8,
      isRichText,
      showTreeView,
      showTableOfContents,
      shouldUseLexicalContextMenu,
      tableCellMerge,
      tableCellBackgroundColor
    }
  } = useSettings();
  const isEditable = useLexicalEditable();
  const text = '';
  const placeholder = <Placeholder>{text}</Placeholder>;
  const [floatingAnchorElem, setFloatingAnchorElem] =
    useState<HTMLDivElement | null>(null);
  const [isSmallWidthViewport, setIsSmallWidthViewport] =
    useState<boolean>(false);

  const onRef = (_floatingAnchorElem: HTMLDivElement) => {
    if (_floatingAnchorElem !== null) {
      setFloatingAnchorElem(_floatingAnchorElem);
    }
  };

  const cellEditorConfig = {
    namespace: 'Cambri',
    nodes: [...TableCellNodes],
    onError: (error: Error) => {
      throw error;
    },
    theme: PlaygroundEditorTheme
  };

  useEffect(()=>{

  },[])
  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        //await editorStore.get_generated_sop(editorStore.documentId, editorStore.folderId);
        let editorState :EditorState = await editorStore.get_editor(id);
       // await editorStore.put_editor(editorStore.documentId);
        console.log(editorStore.editorState);
        if(editorState.editor_document && editorState.editor_document.editor_text && editorState.editor_document.editor_text !==''  ){

          const editorparsedState = editor.parseEditorState(editorState.editor_document.editor_text);
          editor.setEditorState(editorparsedState);
        }
        else if(editorState.editor_document && editorState.editor_document.sop_text  && editorState.editor_document.sop_text != '' ){
          editor.update(() => {
            // Get the RootNode from the EditorState
            const root = $getRoot();
  
            // Get the selection from the EditorState
            // const selection = $getSelection();
  
         
          
            let sopText = editorState.editor_document.sop_text;
  
            sopText.replaceAll(/<\/br>/g, '');
            let splitByNewLine = sopText.split('\n');
  
            splitByNewLine.forEach((el)=>{
                 // Create a new ParagraphNode
                 if(el != ''){
                  const paragraphNode = $createParagraphNode();
                  // Create a new TextNode
                  const textNode = $createTextNode(el);
                  //const lineBreaknode = $createLineBreakNode();
  
                  // Append the text node to the paragraph
                  paragraphNode.append(textNode);
                  root.append(paragraphNode);
                 }
       
            })
  
          
  
            // Finally, append the paragraph to the root
            
          });
  
        }
        else {
          editor.update(() => {
            // Get the RootNode from the EditorState
            const root = $getRoot();
  
            // Get the selection from the EditorState
            // const selection = $getSelection();
  
            const paragraphNode = $createParagraphNode();
            const textNode = $createTextNode('');
            paragraphNode.append(textNode);
            root.append(paragraphNode);
            
          });
        }
      }
      const stringifiedEditorState = JSON.stringify(
        editor.getEditorState().toJSON(),
      );
      const parsedEditorState = editor.parseEditorState(stringifiedEditorState);
      
      const editorStateTextString = parsedEditorState.read(() => $getRoot().getTextContent())

      editor.setEditable(false);
      
    };
    fetchData();

  }, [id]);

  useEffect(() => {
    const updateViewPortWidth = () => {
      const isNextSmallWidthViewport =
        CAN_USE_DOM && window.matchMedia('(max-width: 1025px)').matches;

      if (isNextSmallWidthViewport !== isSmallWidthViewport) {
        setIsSmallWidthViewport(isNextSmallWidthViewport);
      }
    };
    updateViewPortWidth();
    window.addEventListener('resize', updateViewPortWidth);

    return () => {
      window.removeEventListener('resize', updateViewPortWidth);
    };
  }, [isSmallWidthViewport]);

  return (
    <>


            <RichTextPlugin
              contentEditable={
                 <div className="editor-scroller">
                  <div className="editor" ref={onRef}>
                    <ContentEditable/>
                  </div>
                </div>
              }
              placeholder={placeholder}
              ErrorBoundary={LexicalErrorBoundary}
            />



    </>
  );
})

export default SimpleEditor;
