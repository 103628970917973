import AddButton from '../../components/addButton/add_button';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../hooks/use-store';
import GetDataBasedOnSlot from './getDataBasedOnSlot';

const WorkExperience = observer((props: any) => {
  const handleChange = (e, slot, itemId, keyValue) => {
    userProfileStore.updateUserJsonDataArr(slot, itemId, keyValue, e.target.value);
  };
  
  const { userProfileStore } = useStore();

  const template = {
    Company: {
      placeholder: 'Enter',
      name: 'Job Title',
      type: 'text',
      label: 'Job Title',
    },
    Job_Title: {
      placeholder: 'Enter',
      name: 'Company',
      type: 'text',
      label: 'Company',
    },
    Country: {
      placeholder: 'Enter',
      name: 'Country',
      type: 'text',
      label: 'Country',
    },

    From: {
      placeholder: 'Enter dd/mm/yyyy',
      name: 'From',
      type: 'date',
      label: 'From',
    },

    To: {
      placeholder: 'Enter dd/mm/yyyy',
      name: 'To',
      type: 'date',
      label: 'To',
    },

    isCurrentlyWorking: {
      placeholder: '',
      name: 'Time Period',
      type: 'radio',
      label: 'Time Period',
    },
  };

  const handleClick = () => {
    userProfileStore.addWorkExperienceList();
  };

  return (
    <>
      <div className="mt-5 w-full overflow-y-auto px-24">
        <div className="flex justify-between flex-col w-full">
          <GetDataBasedOnSlot
            slot={'work_experience'}
            template={template}
            handleChange={handleChange}
            data={userProfileStore.userProfilejsonData.work_experience}
          />
        </div>
        <div className="flex items-center justify-center">
          <AddButton onClick={handleClick} onBoard={true}>
            {'Add New'}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-5 h-5 ml-6"
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
            </svg>
          </AddButton>
        </div>
      </div>
    </>
  );
});

export default WorkExperience;
