import { observer } from 'mobx-react-lite';
import { useStore } from '../../hooks/use-store';

const SopDescription = observer((props: any) => {
  const { lorStore } = useStore();
  const { sopStore } = props;
  const {isLor} = lorStore
  return (
    <>
      <div className="flex justify-between items-center w-full flex-wrap h-auto">
        <div className="bg-[#F8F9FA] flex items-center border border-[#E3E5E9] rounded-lg my-4 mr-0 px-[6px] py-3 w-full">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="#737E93"
            className="w-8 h-8 mx-3"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
            />
          </svg>
          <p className="text-[#4E5664] text-base">{ sopStore.sop_description}</p>
        </div>
        {/* <div className="flex justify-evenly border border-[#E3E5E9] mt-4 p-5 w-[15%] rounded-lg">
            <img src={translatorIcon} />
            <span className="border border-l-[#E3E5E9] mx-3"></span>
            <i className="fa-light fa-spell-check text-[#A033FF] text-xl" />
            <span className="border border-l-[#E3E5E9] mx-3"></span>
            <img src={micIcon} />
          </div> */}
      </div>
    </>
  );
});

export default SopDescription;
