import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router';
import { observer } from 'mobx-react-lite';
import editor from '../../icons/editor.svg';
import copy from '../../icons/Copy.svg';
import SimpleRichEditor from '../../features/editor/simple-editor-root';
import PlagarismIcon from '../../icons/Plagarism.svg';
import Plagiarism from '../sops/usa_sop/final_output_screen/Plagiarism/plagiarism';
import { SideOverlayPlagiarsim } from '../../side-overlay-plagiarsim';
import CambriRichEditor from '../../features/editor/editor-root';

const GeneratedOutput = observer((props: any) => {
  const { sopStore, lorStore } = props;
  const navigate = useNavigate();

  const { id, folderid } = useParams();
  const handleEditor = () => {
    navigate(`/editor/${id}/${folderid}`);
  };
  const outputLines = sopStore?.generatedOutput.split('\n');
  const onPlagiarismClicked = () => {
    sopStore.toggle_show_plagiarism();
  };

  const plagiarism_close_handler = () => {
    sopStore.toggle_show_plagiarism();
  };

  const old_render_object = (
    <>
      <div
        className={`text-[#4E5664] bg-white border border-b-[#C7CBD4] rounded-xl leading-6 text-base`}
      >
        <div className="flex justify-between p-2 bg-[#FCF3FF] rounded-t-lg border border-[#C7CBD4]">
          <div className="flex items-center pl-2">
            <p className="text-xl">Output</p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="#A033FF"
              className="w-6 h-6 ml-2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
              />
            </svg>
          </div>
          <div className="flex items-center">
          <span
            onClick={() => {
              onPlagiarismClicked();
            }}
            className="group cursor-pointer relative"
          >
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke-width={1.5}
    stroke="#A033FF"
    className="w-7 h-7 group-hover:opacity-50"
  >
    <path
      stroke-linecap="round"
      stroke-linejoin="round"
      d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m5.231 13.481L15 17.25m-4.5-15H5.625c-.621 0-1.125.504-1.125 1.125v16.5c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9zm3.75 11.625a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z"
    />
  </svg>
  <div className="group-hover:opacity-100 w-[136px] z-50 bottom-0 px-4 py-2 transition-opacity bg-gray-800 text-sm text-gray-100 rounded-md absolute left-1/2
    -translate-x-1/2 translate-y-full opacity-0 mx-auto">
    Plagiarism Checker
  </div>
</span>

            <span
              className="cursor-pointer mr-6 ml-2"
              onClick={() => {
                navigator.clipboard.writeText(sopStore?.generatedOutput);
                toast.success('Copied');
              }}
            >
             <div className="group ">
      <img src={copy} alt="Copy" className="group-hover:opacity-50"/>
      <div className="hidden group-hover:block absolute bg-gray-800 text-white p-2 ml-[-3rem] mt-4 rounded-md">
        Copy
      </div>
    </div>
              {/* // className="fa-regular fa-copy text-[#A033FF] text-[25px] mx-8 cursor-pointer" */}
            </span>
            <span className="cursor-pointer" onClick={handleEditor}>
              <img src={editor}/>
            </span>
            <div>
              <button className="uppercase text-[#A033FF] text-xs font-bold" onClick={handleEditor}>
                Open in Cambri Editor
              </button>
            </div>
          </div>
        </div>
        <div className="relative p-8 leading-9 text-base">
          <SimpleRichEditor/>
        </div>
        {sopStore.isLor
          ? null
          : !sopStore.is_premium_user && (
          <div
            className="rounded-lg text-center"
            style={{
              height: '350px',
              boxShadow: '0px 13px 20px 0px rgba(143, 152, 169, 0.20)',
              background: 'linear-gradient(180deg, rgba(255, 165, 215, 0.00) 0%, #FFE1F2 100%)'
            }}
          >
            <p className="font-extrabold text-2xl mt-40 text-[#3C424C]">
              Get the access to the complete SOP
            </p>
            <p className="text-base text-[#737E93] mt-4">
              Want to get full access along with Cambri AI Editor? Go{' '}
              <span className="font-extrabold">Pro</span> now!
            </p>
            <button
              onClick={() => navigate('/paymentspage')}
              className="bg-[#F151AB] mt-8 rounded-3xl text-white py-[11px] px-[24px]"
            >
              UPGRADE TO PRO NOW
            </button>
          </div>

        )}

      </div>
      <SideOverlayPlagiarsim
        children={
          <div>
            <Plagiarism sopStore={sopStore}/>
          </div>
        }
        onClose={plagiarism_close_handler}
        show={sopStore.showPlagiarism}
        title="Plagiarism Checker"
      />
    </>
  );

  const editor_render = <CambriRichEditor {...[id, folderid]} />;

  return editor_render;
});

export default GeneratedOutput;
