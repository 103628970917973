import { PlusButton } from '../../../components/plus-button/plus-button';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../hooks/use-store';
import ProfileCustomTextField from '../../../components/profile-text-field/profile-text-field';

const Cerifications = observer(() => {
  const template = {
    placeholder: 'Enter',
    name: 'Add a Certification',
    type: 'text',
    label: 'Add a Certification',
  };
  const { userProfileStore } = useStore();
  const addAnotherCertifcate = (e, id) => {
    userProfileStore.addCertificateList();
  };

  const handleChange = (e, itemId) => {
    userProfileStore.updateUserJsonDataArr('certifications', itemId, 'name', e.target.value);
  };

  return (
    <>
      <div>
        {userProfileStore.userProfilejsonData.certifications &&
          userProfileStore.userProfilejsonData.certifications.length > 0 &&
          userProfileStore.userProfilejsonData.certifications.map((item, index) => {
            return (
              <div className="flex items-center">
                <ProfileCustomTextField
                  placeholder={template['placeholder']}
                  name={template['name']}
                  type={template['type']}
                  label={template['label'] + '  ' + (index + 1).toString()}
                  slot={'certifications'}
                  itemId={item['id']}
                  keyValue={'name'}
                  value={item['name']}
                  onChange={(e) => handleChange(e, item['id'])}
                />
                {index === 0 && <PlusButton name={item.name} handleClick={addAnotherCertifcate} />}
              </div>
            );
          })}
      </div>
    </>
  );
});

export default Cerifications;
