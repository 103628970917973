import { useEffect, useState } from 'react';
import CustomTextField from '../../../../../components/text-field/text-field';
import { safe_get } from '../../../../utils';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../../../hooks/use-store';
import Autocomplete from '../../../../tools/auto-complete';
import ListSelector from '../../../../tools/list_selector';
import ContentEditableTextArea from '../../../../tools/editable-textarea/editable-textarea';
import { emitObject } from '../../../../tools/interfaces/interfaces';
import { SideOverlay } from '../../../../../side-overlay';
import FetchExamples from '../../../../sop-components/fetch_examples/fetch_examples';
import EditPopover from '../../../final-output-screen-components/edit_popover';
import FetchTemplates from '../../../../sop-components/fetch_templates/fetch_templates';
import { useParams } from 'react-router';

const AboutCollege = observer((props: any) => {
  const { sopStore, newContentStore } = useStore();
  const [aboutCollegeData, setAboutCollegeData] = useState({});
  const [infoIconClicked, setInfoIconClicked] = useState(false);
  const [suggestion, setSuggestion] = useState({});
  const [value, setValue] = useState();
  const [selectedOption, setSelectedOption] = useState();
  const {id} = useParams()

console.log(id,"AUSTRALIAID");
  useEffect(() => {
    if(sopStore.isAustraliaSop){
      sopStore.getTemplates({
        tool_id: 155,
        screen: 'S1',
        fileid:sopStore.sopId!=155 ? sopStore.sopId : 0
      });
      sopStore.getExamples({
        tool_id: 155,
        screen: 'S1',
      });
    }
    else{
      sopStore.getTemplates({
        tool_id: 152,
        screen: 'S1',
        fileid:sopStore.sopId!=152 ? sopStore.sopId : 0
      });
      sopStore.getExamples({
        tool_id: 152,
        screen: 'S1',
      });
    }
  }, []);

  const [pos, setPos] = useState({
    x: 0,
    y: 0,
  });
  const regex = /enter your text/;

  const onEditClicked = (slot) => {
    sopStore.getTemplates({
      tool_id: 152,
      screen: 'S1',
      fileid:id?id: sopStore.sopId!=152 ? sopStore.sopId : 0
    });
    sopStore.getExamples({
      tool_id: 152,
      screen: 'S1',
    });
    const temp = sopStore.sopjsonData[slot];
    setAboutCollegeData({ ...aboutCollegeData, [slot]: temp });
    sopStore.setEditClicked(true);
    sopStore.setSlot(slot);
  };

  const onBlurTextArea = (textAreaEvent: emitObject) => {
    if (sopStore.final_output_screen) {
      setAboutCollegeData({ ...aboutCollegeData, [textAreaEvent.key]: textAreaEvent.value });
    } else {
      sopStore.updateSopJsonData(textAreaEvent.key, textAreaEvent.value);
    }
  };

  const template_close_handler = () => {
    sopStore.toggle_show_template();
  };

  const onTemplateClicked = (question, slot) => {
    sopStore.setSlot(slot);
    sopStore.set_selected_textarea(question, '', '');
    sopStore.toggle_show_template();
  };

  const arr = ['reason_pursue_course', 'degree', 'course'];

  const handleClickUpdate = (slot, value) => {
    const sopStoreUpdate = (property, data) => {
      sopStore[`updateSelected${property}`](data);
      sopStore.updateSopJsonData(slot, { label: data, value: data });
      sopStore.update_sop_workspace(newContentStore.activeFolder, sopStore.sopId);
    };

    if (slot === 'university' || slot === 'course') {
      const dataToUpdate = value || suggestion;
      sopStoreUpdate(slot.charAt(0).toUpperCase() + slot.slice(1), dataToUpdate);
    } else if (slot === 'degree' && value) {
      sopStore.updateSopJsonData(slot, value);
      sopStore.updateSelectedDegree(value);
      sopStore.update_sop_workspace(newContentStore.activeFolder, sopStore.sopId);
    } else {
      setAboutCollegeData({ ...aboutCollegeData, [slot]: value });
      sopStore.updateSopJsonData(slot, value);
      sopStore.update_sop_workspace(newContentStore.activeFolder, sopStore.sopId);
    }
  };

  return (
    <>
      <div className="relative">
        <CustomTextField
          label="Collge/University"
          name=""
          type="text"
          sop={true}
          placeholder={'Enter College / University'}
          slot={'university'}
          value={typeof (sopStore.sopjsonData.university as { label?: string })?.label === "string" ? safe_get(sopStore.sopjsonData, 'university.label' , '') : ""}
          onEditClicked={onEditClicked}
          showInfoIcon={true}
          shouldTruncate={true}
        />
        <CustomTextField
          placeholder={'Select Degree'}
          slot={'degree'}
          label={'Degree'}
          value={safe_get(sopStore.sopjsonData, 'degree.label', '')}
          type="text"
          sop={true}
          onEditClicked={onEditClicked}
          showInfoIcon={true}
          shouldTruncate={true}
        />
        <CustomTextField
          name=""
          type="text"
          sop={true}
          placeholder={'Enter Course'}
          slot={'course'}
          label={'Course'}
          value={typeof (sopStore.sopjsonData.university as { label?: string })?.label === "string" ? safe_get(sopStore.sopjsonData, 'course.label' , '') : ""}
          onEditClicked={onEditClicked}
          showInfoIcon={true}
          shouldTruncate={true}
        />
        <CustomTextField
          label="What makes you want to pursue this specialization?"
          name=""
          type="text"
          placeholder={
            'E.g. - I want to master ‘your field’, so to expand my domain knowledge and make better choices for my career.'.slice(
              0,
              50
            ) + '...'
          }
          sop={true}
          slot={'reason_pursue_course'}
          onEditClicked={onEditClicked}
          value={safe_get(sopStore.sopjsonData, 'reason_pursue_course', '')}
          showInfoIcon={true}
          shouldTruncate={true}
        />
        <CustomTextField
          label="Why do you want to study in this country? (List compelling reasons why you chose this country)"
          name=""
          type="text"
          placeholder={
            'E.g. - I want to master ‘your field’, so to expand my domain knowledge and make better choices for my career.'.slice(
              0,
              50
            ) + '...'
          }
          sop={true}
          slot={'reason_for_chosen_country'}
          onEditClicked={onEditClicked}
          value={safe_get(sopStore.sopjsonData, 'reason_for_chosen_country', '')}
          showInfoIcon={true}
          shouldTruncate={true}
        />

        {sopStore.slot == 'university' && (
          <EditPopover
            sopStore={sopStore}
            pos={pos}
            handleCancel={() => {}}
            handleClickUpdate={() => handleClickUpdate('university', value)}
          >
            <Autocomplete
              placeholder={'Enter College / University'}
              slot={'university'}
              label={'College / University'}
              val={typeof (sopStore.sopjsonData.university as { label?: string })?.label === "string" ? safe_get(sopStore.sopjsonData, 'university.label' , '') : ""}
              sopStore={sopStore}
              setSuggestion={setSuggestion}
              value={value}
              setValue={setValue}
            />
          </EditPopover>
        )}

        {sopStore.slot == 'degree' && (
          <EditPopover
            sopStore={sopStore}
            handleCancel={() => {}}
            handleClickUpdate={() => handleClickUpdate('degree', selectedOption)}
          >
            <ListSelector
              placeholder={'Select Degree'}
              slot={'degree'}
              label={'Degree'}
              val={safe_get(sopStore.sopjsonData, 'degree', {})}
              sopStore={sopStore}
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption}
            />
          </EditPopover>
        )}
        {sopStore.slot == 'course' && (
          <EditPopover
            sopStore={sopStore}
            handleCancel={() => {}}
            handleClickUpdate={() => handleClickUpdate('course', value)}
          >
            <Autocomplete
              placeholder={'Enter Course'}
              slot={'course'}
              label={'Course'}
              val={typeof (sopStore.sopjsonData.university as { label?: string })?.label === "string" ? safe_get(sopStore.sopjsonData, 'course.label' , '') : ""}
              sopStore={sopStore}
              setSuggestion={setSuggestion}
              value={value}
              setValue={setValue}
            />
          </EditPopover>
        )}
        {sopStore.slot == 'reason_pursue_course' && (
          <EditPopover
            sopStore={sopStore}
            handleCancel={() => {}}
            handleClickUpdate={() =>
              handleClickUpdate('reason_pursue_course', aboutCollegeData['reason_pursue_course'])
            }
          >
            <ContentEditableTextArea
              placeholder={
                'E.g. - I want to master ‘your field’, so to expand my domain knowledge and make better choices for my career.'
              }
              onTemplateClicked={() =>
                onTemplateClicked(
                  'What makes you want to pursue this specialization?',
                  'reason_pursue_course'
                )
              }
              htmlText={aboutCollegeData['reason_pursue_course']}
              onBlur={onBlurTextArea}
              heightInRem={8}
              display={'What makes you want to pursue this specialization?'}
              slot={'reason_pursue_course'}
              regex={regex}
              showTemplateIcon={sopStore.templateExists(
                'What makes you want to pursue this specialization?'
              )}
              // onChange={handleChange}
            />
          </EditPopover>
        )}
        {sopStore.slot == 'reason_for_chosen_country' && (
          <EditPopover
            sopStore={sopStore}
            handleCancel={() => {}}
            handleClickUpdate={() =>
              handleClickUpdate(
                'reason_for_chosen_country',
                aboutCollegeData['reason_for_chosen_country']
              )
            }
          >
            <ContentEditableTextArea
              placeholder={
                'E.g. - I want to master ‘your field’, so to expand my domain knowledge and make better choices for my career.'
              }
              onTemplateClicked={() =>
                onTemplateClicked(
                  'Why do you want to study in this country? (List compelling reasons why you chose this country)',
                  'reason_for_chosen_country'
                )
              }
              htmlText={aboutCollegeData['reason_for_chosen_country']}
              onBlur={onBlurTextArea}
              heightInRem={8}
              display={
                'Why do you want to study in this country? (List compelling reasons why you chose this country)'
              }
              slot={'reason_for_chosen_country'}
              regex={regex}
              showTemplateIcon={sopStore.templateExists(
                'Why do you want to study in this country? (List compelling reasons why you chose this country)'
              )}
              //  onChange={handleChange}
            />
          </EditPopover>
        )}

        <SideOverlay
          children={
            <div>
              {infoIconClicked ? (
                <FetchExamples sopStore={sopStore} />
              ) : (
                <FetchTemplates setState={setAboutCollegeData} state={aboutCollegeData} sopStore={sopStore}
                />
              )}
            </div>
          }
          onClose={template_close_handler}
          show={sopStore.showTemplate}
          title={infoIconClicked ? 'Examples' : 'Templates'}
          length={0}
        />
      </div>
    </>
  );
});

export default AboutCollege;
