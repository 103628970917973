import { useState, useEffect } from 'react';
import AddButton from '../../../../components/addButton/add_button';
import { useStore } from '../../../../hooks/use-store';
import { observer } from 'mobx-react-lite';
import { v4 as uuidv4 } from 'uuid';
import { safe_get } from '../../../utils';
import { clone } from 'lodash';
import { emitObject } from '../../../tools/interfaces/interfaces';
import ContentEditableTextArea from '../../../tools/editable-textarea/editable-textarea';
import { SideOverlay } from '../../../../side-overlay';
import FetchExamples from '../../../sop-components/fetch_examples/fetch_examples';
import EditPopover from '../../final-output-screen-components/edit_popover';
import FetchTemplates from '../../../sop-components/fetch_templates/fetch_templates';
import { useParams } from 'react-router';

const Academics = observer((props: any) => {
  const { sopStore, newContentStore } = useStore();
  const [academicsData, setAcademicsData] = useState(
    safe_get(sopStore.sopjsonData, 'qualifications', [])
  );
  const [qualifications, setQualifications] = useState({});
  const [infoIconClicked, setInfoIconClicked] = useState(false);
  const {id} = useParams()

  useEffect(() => {
    setQualifications(safe_get(sopStore.sopjsonData, 'qualifications', []));
  }, [sopStore.sopjsonData.qualifications]);

  const regex = /enter your text/;
  const handleDelete = (id) => {
    const temp = clone(sopStore.sopjsonData);
    const qualArr = [...safe_get(temp, 'qualifications', [])];
    const newArr = qualArr.filter((key) => key['id'] !== id);
    setAcademicsData(newArr);
    if (temp['qualifications']) {
      // @ts-ignore
      temp['qualifications'] = newArr;
    }
    sopStore.update_sop_json(temp);
  };

  const template_close_handler = () => {
    sopStore.toggle_show_template();
  };
  useEffect(() => {
    if(id=='155'){
      sopStore.getTemplates({
        tool_id: 155,
        screen: 'S4',
        fileid:sopStore.sopId!=155 ? sopStore.sopId : 0
      });
      sopStore.getExamples({
        tool_id: 155,
        screen: 'S4',
      });
    }
    else{
      sopStore.getTemplates({
        tool_id: 152,
        screen: 'S4',
        fileid:sopStore.sopId!=152 ? sopStore.sopId : 0
      });
      sopStore.getExamples({
        tool_id: 152,
        screen: 'S4',
      });
    }
  }, [sopStore.slot]);

  const onEditClicked = (slot, id = '') => {
    const temp = sopStore.sopjsonData[slot];
    sopStore.getTemplates({
      tool_id: 152,
      screen: 'S2',
      fileid:sopStore.sopId!=152?sopStore.sopId:0
    });
    sopStore.getExamples({
      tool_id: 152,
      screen: 'S2',
    });
    if (slot == 'qualifications') {
      const item = temp.find((item) => item.id === id);
      setQualifications({ ...qualifications, [slot]: item?.['value'] });
    } else {
      setQualifications({ ...qualifications, [slot]: temp });
    }
    sopStore.setSlot(slot);
    sopStore.setEditClicked(true);
    sopStore.setSlotId(id);
  };

  const onBlurTextArea = (textAreaEvent: emitObject) => {
    if (sopStore.final_output_screen) {
      setQualifications({ ...qualifications, [textAreaEvent.key]: textAreaEvent.value });
    } else if (!sopStore.final_output_screen && textAreaEvent.key !== 'qualifications') {
      sopStore.updateSopJsonData(textAreaEvent.key, textAreaEvent.value);
    } else {
      sopStore.updateSopJsonDataArr(textAreaEvent.key, textAreaEvent.id, textAreaEvent.value);
    }
  };

  const onTemplateClicked = (question, slot) => {
    sopStore.setSlot(slot);
    sopStore.set_selected_textarea(question, '', '');
    sopStore.toggle_show_template();
  };

  const handleClickUpdate = (slot, value, id?: string) => {
    if (slot == 'qualifications') {
      sopStore.updateSopJsonDataArr(slot, id, value);
    } else {
      sopStore.updateSopJsonData(slot, value);
    }
    sopStore.update_sop_workspace(newContentStore.activeFolder, sopStore.sopId);
  };

  const templatesAndExamplesLength = () => {
    if (infoIconClicked) {
      if (
        sopStore.examplesForScreen &&
        sopStore.examplesForScreen[sopStore.selected_text_area.name].length > 0
      ) {
        return sopStore.examplesForScreen[sopStore.selected_text_area.name].length;
      }
    } else {
      if (
        sopStore.templatesForScreen?.[sopStore.selected_text_area.name] &&
        sopStore.templatesForScreen[sopStore.selected_text_area.name].length > 0
      ) {
        return sopStore.templatesForScreen[sopStore.selected_text_area.name].length;
      } else return 0;
    }
  };

  return (
    <>
      <div className="flex flex-col justify-center relative">
        {sopStore?.questions?.map((item: any) => {
          if (item?.screen == 'S4' && item?.slots !== null) {
                        return (
              <ContentEditableTextArea
              placeholder={sopStore.templateExists(item['question']) ? 'Write your content here or use our template':"Write your content here"}
                onTemplateClicked={() => onTemplateClicked(item.question, item.slots)}
                setInfoIconClicked={setInfoIconClicked}
                onBlur={onBlurTextArea}
                htmlText={sopStore.sopjsonData[item['slots']]}
                heightInRem={sopStore.final_output_screen ? 2 : 8}
                display={item['question']}
                slot={item['slots']}
                regex={regex}
                finalOutputScreen={sopStore.final_output_screen}
                onEditClicked={onEditClicked}
                showpencilIcon={true}
                showTemplateIcon={
                  sopStore.final_output_screen
                    ? false
                    : true && sopStore.templateExists(item['question'])
                }
                showInfoIcon={
                  sopStore.final_output_screen
                    ? false
                    : true && sopStore.exampleExists(item['question'])
                }
              />
            );
          }
        })}
        {academicsData.map((item) => {
          return (
            <div key={item.id} className="relative mt-3">
              <ContentEditableTextArea
                id={item.id}
                placeholder={"Write your content here"}
                // onTemplateClicked={() => onTemplateClicked(item.question, item.slots)}
                setInfoIconClicked={setInfoIconClicked}
                onBlur={onBlurTextArea}
                htmlText={item.value}
                heightInRem={sopStore.final_output_screen ? 2 : 8}
                display="Share your academic qualification"
                slot="qualifications"
                regex={regex}
                onEditClicked={onEditClicked}
                showpencilIcon={true}
                showTemplateIcon={false}
                showInfoIcon={false}
                onDelete={handleDelete}
                showDeleteIcon={true}
              />
              {sopStore.slot == 'qualifications' && sopStore.slotId == item.id ? (
                <EditPopover sopStore={sopStore}>
                  <ContentEditableTextArea
                    id={item.id}
                    placeholder={'Write your content here or use our template'}
                    // onTemplateClicked={() => onTemplateClicked(item.question, item.slots)}
                    setInfoIconClicked={setInfoIconClicked}
                    onBlur={onBlurTextArea}
                    htmlText={qualifications?.['qualifications']}
                    heightInRem={sopStore.final_output_screen ? 2 : 8}
                    display="Share your academic qualification"
                    slot="qualifications"
                    regex={regex}
                    onEditClicked={onEditClicked}
                    showpencilIcon={true}
                    showTemplateIcon={false}
                    showInfoIcon={false}
                  />
                </EditPopover>
              ) : (
                ''
              )}
            </div>
          );
        })}

        <div className="flex justify-center">
          <AddButton
            onClick={() => {
              const obj = {
                id: uuidv4(),
                value: '',
              };
              setAcademicsData([...academicsData, obj]);
              sopStore.updateSopJsonData('qualifications', [
                ...safe_get(sopStore.sopjsonData, 'qualifications', []),
                obj,
              ]);
            }}
            onBoard={false}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-5 h-5 mr-1"
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
            </svg>
            {academicsData.length !== 0
              ? 'Add another academic qualification'
              : 'Add another qualification'}
          </AddButton>
        </div>

        {sopStore.slot == 'switching_fields_from_prev_education' && (
          <EditPopover
            sopStore={sopStore}
            handleCancel={() => {}}
            handleClickUpdate={() =>
              handleClickUpdate(
                'switching_fields_from_prev_education',
                qualifications['switching_fields_from_prev_education']
              )
            }
          >
            <ContentEditableTextArea
              placeholder={'Write your content here or use our template'}
              onTemplateClicked={() =>
                onTemplateClicked(
                  'Are you switching fields from your previous education? If so, why?',
                  'switching_fields_from_prev_education'
                )
              }
              setInfoIconClicked={setInfoIconClicked}
              onBlur={onBlurTextArea}
              htmlText={qualifications['switching_fields_from_prev_education']}
              heightInRem={sopStore.final_output_screen ? 2 : 8}
              display={'Are you switching fields from your previous education? If so, why?'}
              slot={'switching_fields_from_prev_education'}
              regex={regex}
              // finalOutputScreen={sopStore.final_output_screen}
              onEditClicked={onEditClicked}
              showTemplateIcon={false}
            />
          </EditPopover>
        )}

        {sopStore.slot == 'academic_background' && (
          <EditPopover
            sopStore={sopStore}
            handleCancel={() => {}}
            handleClickUpdate={() =>
              handleClickUpdate('academic_background', qualifications['academic_background'])
            }
          >
            <ContentEditableTextArea
              placeholder={'Write your content here or use our template'}
              onTemplateClicked={() =>
                onTemplateClicked(
                  'Describe how has your academic background prepared you for your intended program?',
                  'academic_background'
                )
              }
              setInfoIconClicked={setInfoIconClicked}
              onBlur={onBlurTextArea}
              htmlText={qualifications['academic_background']}
              heightInRem={sopStore.final_output_screen ? 2 : 8}
              display="Describe how has your academic background prepared you for your intended program?"
              slot={'academic_background'}
              regex={regex}
              // finalOutputScreen={sopStore.final_output_screen}
              onEditClicked={onEditClicked}
              showTemplateIcon={false}
            />
          </EditPopover>
        )}

        {sopStore.slot == 'undergraduate_projects' && (
          <EditPopover
            sopStore={sopStore}
            handleCancel={() => {}}
            handleClickUpdate={() =>
              handleClickUpdate('undergraduate_projects', qualifications['undergraduate_projects'])
            }
          >
            <ContentEditableTextArea
              placeholder={'Write your content here or use our template'}
              onTemplateClicked={() =>
                onTemplateClicked(
                  'Discuss your undergraduate projects, including titles, objectives, constraints, challenges faced, and solutions.',
                  'undergraduate_projects'
                )
              }
              setInfoIconClicked={setInfoIconClicked}
              onBlur={onBlurTextArea}
              htmlText={qualifications['undergraduate_projects']}
              heightInRem={sopStore.final_output_screen ? 2 : 8}
              display="Discuss your undergraduate projects, including titles, objectives, constraints, challenges faced, and solutions."
              slot={'undergraduate_projects'}
              regex={regex}
              // finalOutputScreen={sopStore.final_output_screen}
              onEditClicked={onEditClicked}
              showTemplateIcon={false}
            />
          </EditPopover>
        )}

        {sopStore.slot == 'internships' && (
          <EditPopover
            sopStore={sopStore}
            handleCancel={() => {}}
            handleClickUpdate={() =>
              handleClickUpdate('internships', qualifications['internships'])
            }
          >
            <ContentEditableTextArea
              placeholder={'Write your content here or use our template'}
              onTemplateClicked={() =>
                onTemplateClicked(
                  'Did you do any internships? If yes, what were your responsibilities and projects?',
                  'internships'
                )
              }
              setInfoIconClicked={setInfoIconClicked}
              onBlur={onBlurTextArea}
              htmlText={qualifications['internships']}
              heightInRem={sopStore.final_output_screen ? 2 : 8}
              display="Did you do any internships? If yes, what were your responsibilities and projects?"
              slot="internships"
              regex={regex}
              // finalOutputScreen={sopStore.final_output_screen}
              onEditClicked={onEditClicked}
              showTemplateIcon={true}
            />
          </EditPopover>
        )}

        {sopStore.slot == 'work_experience' && (
          <EditPopover
            sopStore={sopStore}
            handleCancel={() => {}}
            handleClickUpdate={() =>
              handleClickUpdate('work_experience', qualifications['work_experience'])
            }
          >
            <ContentEditableTextArea
              placeholder={'Write your content here or use our template'}
              onTemplateClicked={() =>
                onTemplateClicked(
                  'Describe your work experience, including tasks, projects, and notable achievements.',
                  'work_experience'
                )
              }
              setInfoIconClicked={setInfoIconClicked}
              onBlur={onBlurTextArea}
              htmlText={qualifications['work_experience']}
              heightInRem={sopStore.final_output_screen ? 2 : 8}
              display="Describe your work experience, including tasks, projects, and notable achievements."
              slot="work_experience"
              regex={regex}
              // finalOutputScreen={sopStore.final_output_screen}
              onEditClicked={onEditClicked}
              showTemplateIcon={false}
            />
          </EditPopover>
        )}
      </div>
      <SideOverlay
        children={
          <div>
            {infoIconClicked ? (
              <FetchExamples infoIconClicked={infoIconClicked} sopStore={sopStore} />
            ) : (
              <FetchTemplates setState={setQualifications} state={qualifications} sopStore={sopStore}
              />
            )}
          </div>
        }
        onClose={template_close_handler}
        show={sopStore.showTemplate}
        title={infoIconClicked ? 'Examples' : 'Templates'}
        length={templatesAndExamplesLength()}
      />
    </>
  );
});

export default Academics;
