import { action, makeAutoObservable, runInAction, toJS } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
import { SopApi } from '../api/sop-api';
import { safe_get } from '../pages/utils';
import _, { clone, cloneDeep } from 'lodash';
import { makeid_alpa_numeric } from '../utils';
import reason_for_chosen_university_templates from '../pages/sops/usa_sop/templates/reason_for_chosen_university_templates.json';
import reason_pursue_course from '../pages/sops/usa_sop/templates/reason_pursue_course_templates.json';
import short_term_goals from '../pages/sops/usa_sop/templates/short_term_goals.json';
import long_term_goals from '../pages/sops/usa_sop/templates/long_term_goals.json';
import life_experiences from '../pages/sops/usa_sop/templates/life_experience_long_form.json';
import role_model from '../pages/sops/usa_sop/templates/role_model_idol_celebrity_long_form.json';
import literature from '../pages/sops/usa_sop/templates/Literature.json';
import abstract from '../pages/sops/usa_sop/templates/abstract.json';

import { toast } from 'react-toastify';

import sanitizeHtml from 'sanitize-html';
export const sanitizeConf = {
  allowedTags: [],
  allowedClasses: {
    p: [],
  },
  allowedAttributes: {
    p: [],
  },
};

interface PlagiarismResult {
  plagiarism_result: {
    status: number;
    results: {
      batch: any[];
      score: {
        identicalWords: number;
        aggregatedScore: number;
        minorChangedWords: number;
        relatedMeaningWords: number;
      };
      database: any[];
      internet: {
        id: string;
        url: string;
        tags: any[];
        title: string;
        metadata: {
          filename: string;
        };
        totalWords: number;
        introduction: string;
        matchedWords: number;
        similarWords: number;
        identicalWords: number;
        paraphrasedWords: number;
      }[];
      repositories: any[];
    };
    notifications: {
      alerts: any[];
    };
    scannedDocument: {
      scanId: string;
      credits: number;
      enabled: {
        aiDetection: boolean;
        plagiarismDetection: boolean;
      };
      metadata: {
        filename: string;
      };
      totalWords: number;
      creationTime: string;
      totalExcluded: number;
      expectedCredits: number;
    };
    developerPayload: { file_id: number; user_id: number };
  };
  availableCredits: number;
}

const initialPlagiarismObject: PlagiarismResult = {
  plagiarism_result: {
    status: 0,
    results: {
      batch: [],
      score: {
        identicalWords: 0,
        aggregatedScore: 999,
        minorChangedWords: 0,
        relatedMeaningWords: 0,
      },
      database: [],
      internet: [
        {
          id: 'dummy-id',
          url: 'http://example.com/',
          tags: [],
          title: 'Dummy Internet Source',
          metadata: {
            filename: 'dummy-source',
          },
          totalWords: 0,
          introduction: 'This is a dummy internet source.',
          matchedWords: 0,
          similarWords: 0,
          identicalWords: 0,
          paraphrasedWords: 0,
        },
      ],
      repositories: [],
    },
    notifications: {
      alerts: [],
    },
    scannedDocument: {
      scanId: 'dummy-scan-id',
      credits: 0,
      enabled: {
        aiDetection: false,
        plagiarismDetection: true,
      },
      metadata: {
        filename: 'dummy-file.txt',
      },
      totalWords: 0,
      creationTime: '2023-08-05T11:43:58.683606Z',
      totalExcluded: 0,
      expectedCredits: 0,
    },
    developerPayload: { user_id: 0, file_id: 0 },
  },
  availableCredits: 0,
};

export class SopStore {
  activeStep = 'Step 1';
  isFileCreated = false;
  selecteduniversitiy = null;
  selectedDegree = null;
  selectedCourse = null;
  jsonDataLoaded = false;
  selectedCard = -1;
  cardSelected = false;
  selectedCardDescription = '';
  newComposeObject = {};
  generatedOutput = '';
  loading = false;
  loadingbutton: boolean = false;
  showTemplate = false;
  editor_data = { blocks: [], entityMap: {} };
  editor_id: string = makeid_alpa_numeric(6);
  sopId: number;
  current_folder_id: number;
  sopName: string = '';
  isMovingSopDocDialogOpen: false;
  breadCrumbs: { name: string; isfile: boolean }[];
  availableCredits: number;
  requiredCredits: number;
  college_summary = '';
  folderid = null;
  sop_description =
    'Choose the course and university you wish to apply to. Let us know your preferences, including specific programs or departments. This information will help us tailor your SOP to highlight your alignment with their offerings.';
  final_output_screen = false;
  edit_clicked = false;
  slot = '';
  slotId = '';
  toolId: number;
  is_premium_user: boolean = false;
  questions: [];
  stepsArr = ['Step 1', 'Step 2', 'Step 3', 'Step 4', 'Step 5'];
  showPlagiarism = false;
  plagiarismTotalRequestDuration = 500000;
  plagiarismIntervalDuration = 30000;
  plagiarismRequestInProgress = false;
  plagiarism_result: PlagiarismResult = initialPlagiarismObject;
  plagiarismRequestLoading = false;
  plagiarismErrorOccured = false;
  isFromCreateSop = false;
  isCanadaSop = false;
  isAustraliaSop = false;
  templateIds: any = [];
  state: any;
  lastActiveStepClick: any;
  libraryClick: boolean = false;
  documentId: any;
  parentId: any;
  universityDataLor: any;
  loader:boolean=false;
  saveAndNextButton:boolean=false;
  loaderEditor:boolean=false;
  toolsClick:boolean=false;

@action 
setToolsClick(value){
  this.toolsClick = value
}
  @action
  setUniversityDataLor(value) {
    this.universityDataLor = value;
  }
  @action
  setLibraryClick(value) {
    this.libraryClick = value;
  }

  @action setTemplateIndex(id) {
    this.templateIds.push(id);
  }

  @action setToolId(id) {
    this.toolId = id;
  }

  @action setSlot(slot) {
    this.slot = slot;
  }

  @action setEditClicked(bool) {
    this.edit_clicked = bool;
  }

  @action setFinalOutputScreen(bool) {
    this.final_output_screen = bool;
  }
  @action setSlotId(id) {
    this.slotId = id;
  }

  @action setIsFromCreateSop(bool) {
    this.isFromCreateSop = bool;
  }

  steps = [
    {
      id: 'Step 1',
      name: 'About College',
      href: '#',
      current: true,
      completed: false,
      desc: 'Choose the course and university you wish to apply to. Let us know your preferences, including specific programs or departments. This information will help us tailor your SOP to highlight your alignment with their offerings.',
      selectedTemplate: 0,
    },
    {
      id: 'Step 2',
      name: 'Motivation',
      href: '#',
      current: false,
      completed: false,
      desc: 'Share your motivation and inspiration for pursuing this particular course of study. Express your passion, personal experiences, and aspirations that drive your academic goals. Paint a clear picture of why this field resonates with you.',
      selectedTemplate: 0,
    },
    {
      id: 'Step 3',
      name: 'Goals/Aspirations',
      href: '#',
      current: false,
      completed: false,
      desc: 'Outline your specific objectives and goals you aim to achieve through this course. Whether its career advancement, research opportunities, or personal development, articulate your ambitions clearly. ',
      selectedTemplate: 0,
    },
    {
      id: 'Step 4',
      name: 'Qualification',
      href: '#',
      current: false,
      completed: false,
      desc: 'Highlight your qualifications, skills, and academic background that make you a strong candidate for the chosen course. Showcase your unique strengths, experiences, and achievements that set you apart from other applicants.',
      selectedTemplate: 0,
    },
    {
      id: 'Step 5',
      name: 'Fit/Contribution',
      href: '#',
      current: false,
      completed: false,
      desc: 'Go above and beyond by sharing how you can contribute to the college or university community beyond your course of study. Emphasize your leadership abilities, community involvement, or any other relevant skills that make you a valuable asset.',
      selectedTemplate: 0,
    },
  ];

  defaultSopJsonData = {
    course: {},
    university: {},
    degree: {},
    word_count: '1000',
    reason_for_doing_course: '',
    reason_pursue_course: '',
    reason_for_chosen_university: '',
    reason_for_chosen_country: '',
    motivation: {},
    short_term_goals: '',
    long_term_goals: '',
    qualifications: [],
    contributions: [],
    switching_fields_from_prev_education: '',
    academic_background: '',
    undergraduate_projects: '',
    internships: '',
    work_experience: '',
    curricular_activities: '',
    reasons_for_admissions_committee: '',
    reason_stronger_candidate: '',
    skills: '',
    obstacles_or_hardships: '',
    defaultQualifications: [],
    profile_id: null,
    templates: [] as number[],
    lastActiveStep: this.activeStep,
  };

  templates = {
    reason_pursue_course: [reason_pursue_course],
    reason_for_chosen_university: [reason_for_chosen_university_templates],
    short_term_goals: [short_term_goals],
    long_term_goals: [long_term_goals],
    life_experiences: [life_experiences],
    role_model: [role_model],
    literature: [literature],
    abstract: [abstract],
    motivation: [],
  };

  templatesForScreen = {};
  examplesForScreen = {};
  premium: boolean = false;
  @action setTemplates(templates) {
    this.templates['motivation'] = templates;
  }

  selected_text_area = {
    name: '',
    template_id: '',
    template_data: '',
  };
  sopjsonData = { ...this.defaultSopJsonData };
  loaingbutton: boolean = false;
  constructor(public sopApi: SopApi) {
    makeAutoObservable(this);
    makePersistable(this, { name: 'SopStore', properties: [], storage: window.localStorage });
  }

  @action
  setSopDescription(content) {
    this.sop_description = content;
  }

  @action
  updateSopJsonData(key, value) {
    if (key === 'motivation') {
      this.sopjsonData = {
        ...this.sopjsonData,
        [key]: {
          value: value,
          key: this.selectedCard ? this.selectedCard : this.sopjsonData.motivation['key'],
          cardTitle: this.selectedCardDescription
            ? this.selectedCardDescription
            : this.sopjsonData.motivation['cardTitle'],
        },
      };
    } else {
      this.sopjsonData = {
        ...this.sopjsonData,
        [key]: value,
      };
    }
  }

  @action
  templateExists(question) {
    return (
      (this.templatesForScreen[question] && this.templatesForScreen[question].length > 0) || false
    );
  }

  @action
  exampleExists(question) {
    return (
      (this.examplesForScreen[question] && this.examplesForScreen[question].length > 0) || false
    );
  }

  @action
  setIsSopDocMovingDialogOpen(payload) {
    this.isMovingSopDocDialogOpen = payload;
  }

  @action
  updateSopJsonDataArr(slot, key, value) {
    const temp = clone(this.sopjsonData);
    safe_get(temp, slot, []).map((item) => {
      if (item.id === key) {
        item.value = value;
      }
      this.sopjsonData = temp;
    });
  }

  @action
  updateSopJsonObj(slot, value) {
    const temp = clone(this.sopjsonData);
    temp[slot] = {};
    if (slot == 'motivation') {
      temp[slot] = {
        key: this.selectedCard,
        value: value,
        cardTitle: this.selectedCardDescription,
      };
    } else {
      temp[slot] = {
        key: this.selectedCard,
        value: value,
      };
    }
    this.sopjsonData = temp;
  }

  @action
  updateCurrentStatusForStep(stepName) {
    this.steps.map((step) => {
      if (step.id === stepName) {
        step.current = true;
      } else {
        step.current = false;
      }
    });
  }

  @action
  updateSelectedTemplate(stepName, id) {
    this.steps.forEach((step) => {
      if (step.id == stepName) {
        step.selectedTemplate = id;
      }
    });
  }

  @action
  updateSelectedTextarea(textareaName) {
    console.log(textareaName, 'textAreaname');
  }

  @action
  set_selected_textarea(textareaName, id, content) {
    this.selected_text_area.name = textareaName;
    this.selected_text_area.template_id = id;
    this.selected_text_area.template_data = content;
    this.updateSelectedTextarea(textareaName);
  }

  @action
  set_active_step(stepName) {
    this.activeStep = stepName;
    this.sopjsonData.lastActiveStep = stepName;
    this.updateCurrentStatusForStep(stepName);
  }

  @action
  async get_university_details() {
    const universityId = this.isAustraliaSop ? 155 : 152;
    const resp = await this.sopApi.get_university_details(universityId);
    return resp;
  }

  @action
  get_course_details(payload) {
    const resp = this.sopApi.get_course_details(payload);
    return resp;
  }
  @action
  get_course_detailsALL() {
    const resp = this.sopApi.get_course_detailsALL();
    return resp;
  }
  @action
  updateSelectedUniversity(value) {
    this.selecteduniversitiy = value;
  }

  @action
  updateSelectedDegree(value) {
    this.selectedDegree = value;
  }

  @action
  updateSelectedCourse(value) {
    this.selectedCourse = value;
  }

  @action
  clear_newCompose_object() {
    this.newComposeObject = {};
  }

  @action
  updateNewComposeObject(key, value) {
    this.newComposeObject[key] = value;
  }

  @action
  set_new_composition_object(data: object) {
    this.newComposeObject = data;
  }

  @action
  update_sop_json(json) {
    this.sopjsonData = json;
  }

  @action
  set_australia_sop(bool) {
    this.isAustraliaSop = bool;
  }

  getTemplateIds = () => {
    const ids: number[] = [];
    this.steps.forEach((step) => {
      if (step.selectedTemplate !== 0) {
        ids.push(step.selectedTemplate);
      }
    });
    this.sopjsonData.templates = ids;
  };

  @action
  async update_sop_workspace(folderId, fileId) {
    this.saveAndNextButton = true
    this.set_selected_textarea(
      this.selected_text_area.name,
      this.selected_text_area.template_id,
      ''
    );
    this.getTemplateIds();
    const cloneSopJsonData = cloneDeep(toJS(this.sopjsonData));
    for (let key in cloneSopJsonData) {
      if (
        key === 'reason_pursue_course' ||
        key === 'reason_for_chosen_university' ||
        key === 'short_term_goals' ||
        key === 'long_term_goals' ||
        key === 'curricular_activities' ||
        key === 'obstacles_or_hardships' ||
        key === 'skills' ||
        key === 'reason_stronger_candidate' ||
        key === 'reasons_for_admissions_committee' ||
        key === 'switching_fields_from_prev_education' ||
        key === 'internships' ||
        key === 'academic_background' ||
        key === 'undergraduate_projects' ||
        key === 'work_experience'
      ) {
        cloneSopJsonData[key] = sanitizeHtml(cloneSopJsonData[key], sanitizeConf);
      } else if (key === 'motivation') {
        for (let keyMotivation in cloneSopJsonData[key]) {
          if (keyMotivation === 'value') {
            cloneSopJsonData[key][keyMotivation] = sanitizeHtml(
              cloneSopJsonData[key][keyMotivation],
              sanitizeConf
            );
          }
        }
      } else if (key === 'qualifications' || key === 'contributions') {
        for (let i = 0; i < cloneSopJsonData[key].length; i++) {
          for (let keyElement in cloneSopJsonData[key][i]) {
            if (keyElement === 'value') {
              // @ts-ignore
              cloneSopJsonData[key][i][keyElement] = sanitizeHtml(
                cloneSopJsonData[key][i][keyElement],
                sanitizeConf
              );
            }
          }
        }
      }
    }

    this.sopjsonData = cloneSopJsonData;

    const payloadData = {
      gentool: this.isAustraliaSop ? '155':'152',
      folderid: folderId ? folderId : 0,
      documentid: fileId ? fileId : 0,
      payload: this.sopjsonData,
      issubmitted: false,
      profile_id: this.sopjsonData.profile_id,
    };
    const resp = await this.sopApi.update_workspace(payloadData);
    if (resp.status == 200) {
      this.saveAndNextButton = false
      this.sopId = resp.data.documentid;
      this.state = resp.data.state;
      this.edit_clicked = false;
      this.loadingbutton = false;
    }
    return resp;
  }

  @action
  async save_sop_file(activeFolder, filename, parentId) {
    const payloadData = {
      parent: activeFolder,
      name: filename ? filename : '',
      tool_id: this.toolId,
    };
    this.loadingbutton = true;
    const resp = await this.sopApi.create_file(payloadData);

    this.loadingbutton = false;
    runInAction(() => {
      if (resp.status === 200) {
        this.loadingbutton = true;
        this.update_sop_workspace(resp.data.folder_id, resp.data.file_id);
        this.sopId = resp.data.file_id;
        this.folderid = resp.data.folder_id;
        this.loadingbutton = false;
      }
    });
  }

  @action
  async getTemplates(payload) {
    const resp = await this.sopApi.get_templates(payload);
    runInAction(() => {
      if (resp.status === 200) {
        this.templatesForScreen = resp.data;
      }
    });
  }

  @action
  async getExamples(payload) {
    const resp = await this.sopApi.get_examples(payload);
    runInAction(() => {
      if (resp.status === 200) {
        this.examplesForScreen = resp.data;
      }
    });
  }

  @action
  async get_details_of_university() {
    const payloadData = {
      kid: safe_get(this.sopjsonData, 'university.value', ''),
    };
    const resp = await this.sopApi.get_details_of_university(payloadData);
    runInAction(() => {
      if (resp.status === 200) {
        if (resp.data && resp.data.length > 0 && resp.data[0]['summary']) {
          this.college_summary = resp.data[0]['summary'];
        }
      }
    });
  }

  @action
  toggle_show_template() {
    this.showTemplate = !this.showTemplate;
  }

  @action
  set_sop_id(id) {
    this.sopId = id;
  }

  @action
  set_current_folder_id(id) {
    this.current_folder_id = id;
  }

  @action async get_generated_sop(sopId, folderId) {
    this.loading = true;
    const resp = await this.sopApi.get_generated_sop(sopId, folderId);
    this.generatedOutput = resp.data.output;
    this.sopId = resp.data.documentid;
    this.append_paragraph_to_editor_json(resp.data.output);
    this.is_premium_user = resp.data.premium_user;
    this.loading = false;
  }
  @action 
  setGeneratedOutput(value){
    this.generatedOutput = value
  }

  @action async setPremiumUser(bool) {
    this.is_premium_user = bool;
  }

  @action async get_questions(toolId) {
    this.loading = true;
    const resp = await this.sopApi.get_questions(toolId);
    this.questions = resp.data.questions;
    this.is_premium_user = resp.data.premium_user;
    this.availableCredits = resp.data.availableCredits;
  }

  @action
  async get_sop_workspace(sopId, folderId) {
    this.loading = true;
    const payloadData = {
      toolid: this.isAustraliaSop?'155':'152',
      documentid: sopId ? sopId : 0,
      folderid: folderId ? folderId : 0,
      payload: {},
      issubmitted: false,
    };
    const resp = await this.sopApi.get_workspace(payloadData);
    runInAction(() => {
      if (resp.status === 200) {
        this.loading = false;
        if (resp.data && resp.data.state) {
          let sopJsonObj = { ...this.sopjsonData };
          this.sopjsonData = { ...sopJsonObj, ...safe_get(resp.data, 'state', this.sopjsonData) };
          this.sopName = resp.data.file_name;
          this.breadCrumbs = resp.data.bread_crums;
          this.folderid = resp.data.folderid;
          this.stepsArr.map((step) => {
            this.markCompleted(step);
          });
        }
        this.jsonDataLoaded = true;
        this.availableCredits = resp.data.availableCredits;
        this.requiredCredits = resp.data.requiredCredits;
        this.premium = resp.data.premium_user;
        this.lastActiveStepClick = resp.data.state.lastActiveStep;
      }
    });
  }

  @action
  updateCardSelected(value) {
    this.cardSelected = value;
  }

  @action
  updateCardDescription(value) {
    this.selectedCardDescription = value;
  }
  @action
  setFolderId(value) {
    this.folderid = value;
  }

  @action
  update_selected_card(id) {
    this.selectedCard = id;
  }
  @action
  updateSopName(name) {
    this.sopName = name;
  }
  @action
  setProfileId(value) {
    this.sopjsonData.profile_id = value;
  }
  @action
  async generatecontent(profile_id: string) {
    this.loading = true;
    const qualArr: any = [];
    const contArr = [];

    if (safe_get(this.sopjsonData, 'qualifications', []).length > 0) {
      this.sopjsonData.qualifications.map((item) => qualArr.push(item['value']));
    }
    if (safe_get(this.sopjsonData, 'contributions', []).length > 0) {
      this.sopjsonData.contributions.map((item) => {
        contArr.push(item['value']);
      });
    }
    const payload = {
      toolid: this.isAustraliaSop?155:152,
      fileid: this.sopId,
      folderid: this.folderid,
      profileid: profile_id,
      questions: {
        course: safe_get(this.sopjsonData, 'course.label', ''),
        university: safe_get(this.sopjsonData, 'university.label', ''),
        summary: this.college_summary,
        degree: safe_get(this.sopjsonData, 'degree.label', ''),
        word_count: '1000',
        reason_for_doing_course: safe_get(this.sopjsonData, 'reason_for_doing_course', ''),
        reason_pursue_course: safe_get(this.sopjsonData, 'reason_pursue_course', ''),
        reason_for_chosen_university: safe_get(
          this.sopjsonData,
          'reason_for_chosen_university',
          ''
        ),
        motivation: safe_get(this.sopjsonData, 'motivation.value', ''),
        short_term_goals: safe_get(this.sopjsonData, 'short_term_goals', ''),
        long_term_goals: safe_get(this.sopjsonData, 'long_term_goals', ''),
        qualifications: qualArr,
        contributions: contArr,
        switching_fields_from_prev_education: safe_get(
          this.sopjsonData,
          'switching_fields_from_prev_education',
          ''
        ),
        academic_background: safe_get(this.sopjsonData, 'academic_background', ''),
        undergraduate_projects: safe_get(this.sopjsonData, 'undergraduate_projects', ''),
        internships: safe_get(this.sopjsonData, 'internships', ''),
        work_experience: safe_get(this.sopjsonData, 'work_experience', ''),
        curricular_activities: safe_get(this.sopjsonData, 'curricular_activities', ''),
        reasons_for_admissions_committee: safe_get(
          this.sopjsonData,
          'reasons_for_admissions_committee',
          ''
        ),
        reason_stronger_candidate: safe_get(this.sopjsonData, 'reason_stronger_candidate', ''),
        skills: safe_get(this.sopjsonData, 'skills', ''),
        obstacles_or_hardships: safe_get(this.sopjsonData, 'obstacles_or_hardships', ''),
      },
    };

    const resp = await this.sopApi.generate_content(payload);
    this.loading = true
    if (resp.status === 200) {
      this.loaderEditor = true;
      this.loading=false
      this.loader=true
      this.append_paragraph_to_editor_json(resp.data.response);
      this.generatedOutput = resp.data.response;
      this.availableCredits = resp.data.availableCredits;
    } else {
      this.loading = false;
      toast.error(resp.data, { position: 'top-center' });
    }
    this.loading = false;
  }

  @action
  get_initial_json_for_editor() {
    return this.editor_data;
  }

  @action
  update_editor_data(data) {
    this.editor_data = data;
  }

  @action
  clear_sop_state() {
    this.sopjsonData = { ...this.defaultSopJsonData };
    this.generatedOutput = '';
    this.jsonDataLoaded = false;
    this.set_active_step('Step 1');
    this.editor_data = { blocks: [], entityMap: {} };
    this.steps.forEach((step) => {
      step.selectedTemplate = 0;
    });
  }
  @action
  library_sop_state() {
    this.sopjsonData = { ...this.sopjsonData };
    this.generatedOutput = '';
    this.jsonDataLoaded = false;
    this.editor_data = { blocks: [], entityMap: {} };
    //  // this.set_active_step('Step 1');
    //   this.editor_data = { blocks: [], entityMap: {} };
    //   this.steps.forEach((step) => {
    //     step.selectedTemplate = 0;
    // });
  }

  @action
  clearSop() {
    this.sopjsonData = { ...this.defaultSopJsonData };
    this.set_active_step('Step 1');
  }
  @action
  refresh_editor_id() {
    this.editor_id = makeid_alpa_numeric(6);
  }

  @action
  updateBreadcums(array) {
    this.breadCrumbs = array;
  }

  @action
  append_paragraph_to_editor_json(text) {
    const block = {
      data: {},
      depth: 0,
      entityRanges: [],
      inlineStyleRanges: [],
      key: makeid_alpa_numeric(5),
      text: text?.replace(/<\/br>/g, ''),
      type: 'paragraph',
    };
    const data = this.editor_data;
    // @ts-ignore
    data['blocks'] = [block];
    this.update_editor_data(data);
    this.refresh_editor_id();
  }

  @action
  updateCompletedStatusForStep(stepName, isCompleted) {
    this.steps.map((step) => {
      if (step.id === stepName) {
        if (isCompleted) {
          step.completed = true;
        } else {
          step.completed = false;
        }
      }
    });
  }

  @action
  markCompleted(stepName) {
    switch (stepName) {
      case 'Step 1':
        if (
          !_.isEmpty(safe_get(this.sopjsonData, 'course', {})) &&
          !_.isEmpty(safe_get(this.sopjsonData, 'university', {})) &&
          !_.isEmpty(safe_get(this.sopjsonData, 'degree', {})) &&
          safe_get(this.sopjsonData, 'reason_pursue_course', '') !== '' &&
          safe_get(this.sopjsonData, 'reason_for_chosen_country', '') !== ''
        ) {
          this.updateCompletedStatusForStep(stepName, true);
        } else {
          this.updateCompletedStatusForStep(stepName, false);
        }
        return;
      case 'Step 2':
        if (safe_get(this.sopjsonData, 'motivation.value', '')) {
          this.updateCompletedStatusForStep(stepName, true);
        } else {
          this.updateCompletedStatusForStep(stepName, false);
        }
        return;
      case 'Step 3':
        if (
          safe_get(this.sopjsonData, 'short_term_goals', '') !== '' &&
          safe_get(this.sopjsonData, 'long_term_goals', '') !== ''
        ) {
          this.updateCompletedStatusForStep(stepName, true);
        } else {
          this.updateCompletedStatusForStep(stepName, false);
        }
        return;
      case 'Step 4':
        if (
          safe_get(this.sopjsonData, 'switching_fields_from_prev_education', '') &&
          safe_get(this.sopjsonData, 'academic_background', '') &&
          safe_get(this.sopjsonData, 'undergraduate_projects', '') &&
          safe_get(this.sopjsonData, 'internships', '') &&
          safe_get(this.sopjsonData, 'work_experience', '')
        ) {
          this.updateCompletedStatusForStep(stepName, true);
        } else {
          this.updateCompletedStatusForStep(stepName, false);
        }
        return;
      case 'Step 5':
        if (safe_get(this.sopjsonData, 'contributions', []).length > 0) {
          this.updateCompletedStatusForStep(stepName, true);
        } else {
          this.updateCompletedStatusForStep(stepName, false);
        }
        return;
    }
  }

  @action
  async single_palgarism_request() {
    if (this.plagiarismRequestInProgress) {
      return {};
    }

    this.plagiarismRequestInProgress = true;
    const response = await this.sopApi.get_palgarism_details(this.sopId);
    if (response.status === 200) {
      this.plagiarismRequestInProgress = false;
      return response.data;
    } else {
      this.plagiarismRequestInProgress = false;
      toast.error(response.data, { position: 'top-center' });
      return {};
    }
  }

  @action
  async get_palgarism_details() {
    const requestStartTime = Date.now();
    const requestEndTime = requestStartTime + this.plagiarismTotalRequestDuration;

    while (Date.now() < requestEndTime) {
      const response = await this.single_palgarism_request();
      console.log(response,"PLAGARISMRESPONSE");
      if (response && response.plagiarism_result) {
        this.plagiarism_result = response;
        this.availableCredits = response.availableCredits;
        break;
      }

      await new Promise((resolve) => setTimeout(resolve, this.plagiarismIntervalDuration));
    }
  }

  @action
  async sendPlagiarismRequest() {
    this.plagiarismRequestLoading = true;
    this.resetPlagiarsim();
    let payload = { input_text: this.generatedOutput, file_id: this.sopId };
    //Remove for testing let payload = { input_text: p, file_id: this.sopId };
    const resp = await this.sopApi.submit_plagiarsim_scan(payload);
    if (resp.status === 200) {
      await this.get_palgarism_details();
      if (
        safe_get(this.plagiarism_result.plagiarism_result.results.score, 'aggregatedScore', 999) ===
        999
      ) {
        console.log(this.plagiarism_result.plagiarism_result.results.score,"SCORE");
        this.plagiarismErrorOccured = true;
      }
    } else {
      this.plagiarismRequestLoading = false;
      toast.error(resp.data, { position: 'top-center' });
      this.plagiarism_result = initialPlagiarismObject;
    }
    this.plagiarismRequestLoading = false;
  }

  @action
  async deletePlagarism(file_id) {
    return await this.sopApi.delete_plagarism_result(file_id);
  }

  @action
  resetPlagiarsim() {
    this.plagiarismRequestInProgress = false;
    this.plagiarismErrorOccured = false;
    this.plagiarism_result = initialPlagiarismObject;
  }

  @action
  toggle_show_plagiarism() {
    this.showPlagiarism = !this.showPlagiarism;
  }
}
