import {ContentApi} from "../api/content-api";
import {action, makeAutoObservable} from "mobx";
import {safe_get} from "../pages/utils";

export class ContentStore {
  activeFolder: any = null;
  content: {}[] = [];
  loading: boolean = false;
  selectedContentType: string = "";
  isAddFolderModalOpen: boolean = false;
  isDeleteFolderModalOpen: boolean = false;
  isAddFileModalOpen: boolean = false;
  isDeleteFileModalOpen: boolean = false;
  parentId: number | null = null;
  isSideNavOpen: boolean = false;
  activeFile = {};
  editMode: boolean = false;

  constructor(private contentApi: ContentApi) {
    makeAutoObservable(this)
  }

  @action
  set_loading(val) {
    this.loading = val;
  }

  @action
  async search_content(query) {
    return await this.contentApi.search_content({"name": query});
  }

  @action
  async load_folder(parentid) {
    const response = await this.contentApi.get_content({"parent": parentid});
    if (response.status === 200) {
      this.content = response.data.contents
      this.activeFolder = response.data.folder
    } else {
      this.content = []
    }
  }

  @action
  async get_content() {
    const parent = safe_get(this.activeFolder, "id", null)
    await this.load_folder(parent)
  }

  @action
  async navigate_downwards(folder: any) {
    const parent = safe_get(folder, 'id', null)
    await this.load_folder(parent)
  }

  @action
  async navigate_upwards() {
    const parent = safe_get(this.activeFolder, 'parentid', null)
    await this.load_folder(parent)
  }

  @action
  has_parent() {
    return !(this.activeFolder === null)
  }

  @action
  current_folder_is_root() {
    return safe_get(this.activeFolder, 'id', null) == null
  }

  @action
  current_folder_name() {
    return safe_get(this.activeFolder, 'name', 'My Projects')
  }

  @action
  async create_folder(payload) {
    return await this.contentApi.create_new_folder(payload)
  }

  @action
  async create_file(payload) {
    return await this.contentApi.create_new_file(payload)
  }

  @action
  async rename_folder(payload) {
    return this.contentApi.rename_folder(payload)
  }

  @action
  async rename_file(payload) {
    return this.contentApi.rename_file(payload)
  }

  @action
  async delete_folder() {
    const parent_to_load = safe_get(this.activeFolder, 'parentid', null)
    this.loading = true
    await this.contentApi.delete_folder(safe_get(this.activeFolder, "id", null))
    await this.load_folder(parent_to_load)
    this.loading = false
  }

  @action
  async delete_file() {
    return await this.contentApi.delete_file(safe_get(this.activeFile, "id", null))
  }

  @action
  set_parentid(id) {
    this.parentId = id;
  }

  @action
  set_selected_content_type(type) {
    this.selectedContentType = type;
  }

  @action
  set_active_folder(folder) {
    this.activeFolder = folder;
  }

  @action
  set_active_file(file) {
    this.activeFile = file;
  }

  @action
  toggle_modal_status(name: string, value: boolean) {
    switch (name) {
      case "add_folder":
        this.isAddFolderModalOpen = value;
        break;
      case "add_file" :
        this.isAddFileModalOpen = value;
        break;
      case "delete_folder" :
        this.isDeleteFolderModalOpen = value;
        break;
      case "delete_file" :
        this.isDeleteFileModalOpen = value;
        break;
      case "side_bar":
        this.isSideNavOpen = value;
        break;
      case "edit":
        this.editMode = value;
        break;
      default:
        return null;
    }
  }
}
