import React, { useEffect , useState } from 'react';
import { useStore } from '../../../hooks/use-store';
import { observer } from 'mobx-react-lite';
const UsersName = observer(() => {
  const { userProfileStore, resumeStore, sopProfileSelectionStore } = useStore();

  const [isEditMode, setIsEditMode] = useState(false);
   const handleEditIconClick = () => {
    setIsEditMode(!isEditMode);
  };

  const handleUserNameChange = (event) => {
    if (resumeStore.current_step_num == 0 && event.target.value != ' ') {
      userProfileStore.updateUserProfileJsonData('user_name', event.target.value);
    }
  };

  useEffect(() => {
    sopProfileSelectionStore.getSopProfileSelectionDropdownValues();
  }, []);
  return (
    <div>
      <div className="w-89 font-avenir font-light text-[16px] leading-[21.86px] text-[#737E93]">
        <span>User's Full Name</span>
        <span className="text-red-500 text-[18px] ml-2">*</span>
      </div>

      <div className="flex flex-row">
        <input
          type="text"
          placeholder="Enter name"
          className="w-full border border-[#737E93] rounded px-2 py-1 text-[16px] mr-4 bg-white text-black"
          value={userProfileStore.userProfilejsonData.user_name}
          onChange={handleUserNameChange}
          disabled={!resumeStore.continueButton && !isEditMode }
        />
        {userProfileStore.userProfilejsonData.user_name !== '' && (!resumeStore.continueButton&& !isEditMode) ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className='w-6 h-6 cursor-pointer'
            onClick={handleEditIconClick}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
            />
          </svg>
        ) : (
          ''
        )}
      </div>
    </div>
  );
});

export default UsersName;
