import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../hooks/use-store';

const PDF = observer((props: any) => {
  const { sopStore } = props;
  const { editorStore } = useStore();
  const styles = StyleSheet.create({
    page: {
      flexDirection: 'row',
      backgroundColor: 'white',
    },
    section: {
      margin: 10,
      padding: 10,
      flexGrow: 1,
    },
  });
  const pathName = window.location.pathname;
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          {editorStore.save === false && !pathName.includes('/lor') ? editorStore.save === false && !pathName.includes('/germanysop')?(
            <Text>{sopStore.generatedOutput}</Text>
          ):  editorStore.save === false && pathName.includes('/germanysop') ? (
            <Text>{editorStore.sopText}</Text>
          ) : (
            <Text>{editorStore.pdfDetails}</Text>
          ) : editorStore.save === false && pathName.includes('/lor') ? (
            <Text>{editorStore.sopText}</Text>
          ) : (
            <Text>{editorStore.pdfDetails}</Text>
          )}
        </View>
      </Page>
    </Document>
  );
});
export default PDF;
