import { useEffect, useState, useMemo } from 'react';
import TemplateCard from '../../../components/templete-card/templete-card';
import { observer } from 'mobx-react-lite';

const FetchTemplates = observer((props: any) => {
  const { sopStore } = props;
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredResults, setFilteredResults] = useState([]);

  // const handleCardClick = (index) => {
  //   setCopiedIndex(index);
  // };

  const handleCardClick = (index, id) => {
    sopStore.updateSelectedTemplate(sopStore.activeStep,id)
  };

  const handleInputChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSearch = () => {
    const query = searchQuery.trim().toLowerCase();
    if (query === '') {
      setFilteredResults([]);
    } else {
      const filteredResults = sopStore.templatesForScreen[sopStore.selected_text_area.name].filter(
        (item) =>
          query
            .split(' ')
            .every(
              (word) =>
                item.content.toLowerCase().includes(word) ||
                item.description.toLowerCase().includes(word)
            )
      );
      setFilteredResults(filteredResults);
    }
  };

  useEffect(() => {
    if (searchQuery === '') {
      setFilteredResults([]);
    }
  }, [searchQuery]);

  const templatesToRender = useMemo(() => {
    return searchQuery
      ? filteredResults
      : sopStore.templatesForScreen[sopStore.selected_text_area.name];
  }, [filteredResults]);

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  const handleClick = (id, content) => {
    if (sopStore.final_output_screen) {
      if (sopStore.slot === 'motivation') {
        props.setState({
          ...props.state,
          motivation: {
            value: content,
            key: sopStore.selectedCard
              ? sopStore.selectedCard
              : sopStore.sopjsonData?.motivation?.['key'],
            cardTitle: sopStore.selectedCardDescription
              ? sopStore.selectedCardDescription
              : sopStore.sopjsonData?.motivation?.['cardTitle'],
          },
        });
      } else {
        props.setState({ ...props.state, [sopStore.slot]: content });
      }
    } else {
      sopStore.updateSopJsonData(sopStore.slot, content);
    }
    sopStore.set_selected_textarea(sopStore.selected_text_area.name, id, content);
    // sopStore.toggle_show_template();
  };

  const isSelected = (id) => {
    return sopStore.steps.some((item) => item.selectedTemplate == id);
  };

  return (
    <>
      <div className="h-20">
        <p className="text-[#737E93] text-[12px] px-4 py-[6px] mb-4 border-b border-text-gray-300">
          Topic: {sopStore.selected_text_area.name}
        </p>
        <div className="flex items-center border border-gray-300 rounded-md mx-5 py-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6 mx-3 cursor-pointer"
            onClick={handleSearch}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
            />
          </svg>
          <input
            onChange={handleInputChange}
            value={searchQuery}
            type="text"
            placeholder="Search"
            className="outline-none flex-grow bg-white"
            onKeyDown={handleKeyDown}
          />
        </div>
      </div>
      <div className="px-4 pl-5 py-8">
        {templatesToRender && templatesToRender.length > 0 ? (
          templatesToRender.map((item,index) => (
            <TemplateCard
              key={item.templateId}
              coinsLeft={item.credits}
              label={item.label}
              content={item.content}
              footerLabel={'Add this template'}
              footerLabelTwo={'Added'}
              id={item.templateId}
              handleClick={handleClick}
              handleClicked={handleCardClick}
              index={index}
              selectedTemplateId={sopStore?.templateIndex}
              isSelected={isSelected(item.templateId) || item.is_added}
            />
          ))
        ) : (
          <p className="mt-10 text-center">No results found</p>
        )}
      </div>
    </>
  );
});

export default FetchTemplates;
