import { observer } from 'mobx-react-lite';
import Cerifications from './certifications';
import Licenses from './licenses';

const CertificationLicenses = observer((props:any) => {
  return (
    <>
      <div className="mt-1 overflow-y-auto px-24">
        <div className="flex justify-between flex-col w-full">
          <Cerifications />
          <Licenses />
        </div>
      </div>
    </>
  );
});

export default CertificationLicenses;
