import { AutoFocusPlugin } from '@lexical/react/LexicalAutoFocusPlugin';
import { CharacterLimitPlugin } from '@lexical/react/LexicalCharacterLimitPlugin';
import { CheckListPlugin } from '@lexical/react/LexicalCheckListPlugin';
import { ClearEditorPlugin } from '@lexical/react/LexicalClearEditorPlugin';
import LexicalClickableLinkPlugin from '@lexical/react/LexicalClickableLinkPlugin';
import { CollaborationPlugin } from '@lexical/react/LexicalCollaborationPlugin';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { HashtagPlugin } from '@lexical/react/LexicalHashtagPlugin';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { HorizontalRulePlugin } from '@lexical/react/LexicalHorizontalRulePlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { PlainTextPlugin } from '@lexical/react/LexicalPlainTextPlugin';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { TabIndentationPlugin } from '@lexical/react/LexicalTabIndentationPlugin';
import { TablePlugin } from '@lexical/react/LexicalTablePlugin';
import useLexicalEditable from '@lexical/react/useLexicalEditable';
import { useEffect, useState } from 'react';
import { CAN_USE_DOM } from './shared/canUseDOM';
import { useSettings } from './context/SettingsContext';
import { useSharedHistoryContext } from './context/SharedHistoryContext';
import TableCellNodes from './nodes/TableCellNodes';
import AutoEmbedPlugin from './plugins/AutoEmbedPlugin';
import AutoLinkPlugin from './plugins/AutoLinkPlugin';
import CodeActionMenuPlugin from './plugins/CodeActionMenuPlugin';
import CodeHighlightPlugin from './plugins/CodeHighlightPlugin';
import CollapsiblePlugin from './plugins/CollapsiblePlugin';
import ComponentPickerPlugin from './plugins/ComponentPickerPlugin';
import DragDropPaste from './plugins/DragDropPastePlugin';
import DraggableBlockPlugin from './plugins/DraggableBlockPlugin';
import EmojiPickerPlugin from './plugins/EmojiPickerPlugin';
import EmojisPlugin from './plugins/EmojisPlugin';
import ExcalidrawPlugin from './plugins/ExcalidrawPlugin';
import FigmaPlugin from './plugins/FigmaPlugin';
import FloatingLinkEditorPlugin from './plugins/FloatingLinkEditorPlugin';
import FloatingTextFormatToolbarPlugin from './plugins/FloatingTextFormatToolbarPlugin';
import ImagesPlugin from './plugins/ImagesPlugin';
import InlineImagePlugin from './plugins/InlineImagePlugin';
import LinkPlugin from './plugins/LinkPlugin';
import ListMaxIndentLevelPlugin from './plugins/ListMaxIndentLevelPlugin';
import MarkdownShortcutPlugin from './plugins/MarkdownShortcutPlugin';
import { MaxLengthPlugin } from './plugins/MaxLengthPlugin';
import PollPlugin from './plugins/PollPlugin';
import SpeechToTextPlugin from './plugins/SpeechToTextPlugin';
import TabFocusPlugin from './plugins/TabFocusPlugin';
import TableCellActionMenuPlugin from './plugins/TableActionMenuPlugin';
import TableCellResizer from './plugins/TableCellResizer';
import { TablePlugin as NewTablePlugin } from './plugins/TablePlugin';
import TwitterPlugin from './plugins/TwitterPlugin';
import YouTubePlugin from './plugins/YouTubePlugin';
import PlaygroundEditorTheme from './themes/PlaygroundEditorTheme';
import ContentEditable from './ui/ContentEditable';
import Placeholder from './ui/Placeholder';
import AIAssistHomeScreen from './aiassist/ai-assist-home-screen';
import { FloatingMenuPlugin } from './plugins/FloatingMenuPlugin';
import { createCommand } from 'lexical';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { useStore } from '../../hooks/use-store';
import { $createParagraphNode, $createTextNode, $getRoot, $createLineBreakNode } from 'lexical';
import { EditorState } from '../../store/editor-store';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { empty_editor, hydrate_editor_from_state } from './editor-state-hydration-utils';

const skipCollaborationInit =
  // @ts-ignoresimpl
  window.parent != null && window.parent.frames.right === window;

export const SHOW_AI_ASSIST_HOME = createCommand<void>();
export const HIDE_AI_ASSIST_HOME = createCommand<void>();

const Editor = observer((props): JSX.Element => {
  const { historyState } = useSharedHistoryContext();
  const {
    editorStore,
    sopStore,
    germanyStore,
    uKSopStore,
    canadaSopStore,
    userStore,
    lorStore,
    sopProfileSelectionStore,
    newContentStore,
  } = useStore();
  const [editor] = useLexicalComposerContext();
  const navigate = useNavigate();

  const {
    settings: {
      isMaxLength,
      isCharLimit,
      isCharLimitUtf8,
      isRichText,
      showTreeView,
      tableCellMerge,
      tableCellBackgroundColor,
    },
  } = useSettings();
  const params = useParams();
  console.log(params, 'PARAMETERS');
  const isEditable = useLexicalEditable();
  const text = '';
  const placeholder = <Placeholder>{text}</Placeholder>;
  const [floatingAnchorElem, setFloatingAnchorElem] = useState<HTMLDivElement | null>(null);
  const [isSmallWidthViewport, setIsSmallWidthViewport] = useState<boolean>(false);

  const onRef = (_floatingAnchorElem: HTMLDivElement) => {
    if (_floatingAnchorElem !== null) {
      setFloatingAnchorElem(_floatingAnchorElem);
    }
  };

  const cellEditorConfig = {
    namespace: 'Cambri',
    nodes: [...TableCellNodes],
    onError: (error: Error) => {
      throw error;
    },
    theme: PlaygroundEditorTheme,
  };

  useEffect(() => {
    const fetchdata = async () => {
      // calling the endpoint when the v1(generate_content) endpoint success
      if (
        germanyStore.loaderEditor ||
        sopStore.loaderEditor ||
        lorStore.loaderEditor ||
        uKSopStore.loaderEditor ||
        canadaSopStore.loaderEditor
      ) {
        let editorState: EditorState = await editorStore.get_editor(params?.id);
        hydrate_editor_from_state(editor, editorState);
        if (window.location.pathname.includes('/canadasop')) {
          canadaSopStore.loaderEditor = false;
          canadaSopStore.isFromCreateSop = false;
        }
        if (window.location.pathname.includes('/uksop')) {
          uKSopStore.loaderEditor = false;
          uKSopStore.isFromCreateSop = false;
        }
        if (window.location.pathname.includes('/sop')) {
          sopStore.loaderEditor = false;
          sopStore.isFromCreateSop = false;
        }
        if (window.location.pathname.includes('/lor')) {
          lorStore.loaderEditor = false;
          lorStore.isFromCreateSop = false;
        }
        if (window.location.pathname.includes('/australiasop')) {
          sopStore.loaderEditor = false;
          sopStore.isFromCreateSop = false;
        }
        if (window.location.pathname.includes('/germanysop')) {
          germanyStore.loaderEditor = false;
          germanyStore.isFromCreateSop = false;
        }
        sopProfileSelectionStore.setClearSopStep(false);
      }
    };
    fetchdata();
  }, [
    germanyStore.loaderEditor,
    sopStore.loaderEditor,
    lorStore.loaderEditor,
    uKSopStore.loaderEditor,
    canadaSopStore.loaderEditor,
  ]);

  useEffect(() => {
    const hydrate_editor = async () => {
      // calling the endpoint when refresh the finaloutput screen
      if (
        (!germanyStore.isFromCreateSop &&
          !germanyStore.toolsClick &&
          window.location.pathname.includes('/germanysop')) ||
        (!sopStore.loaderEditor &&
          !sopStore.toolsClick &&
          window.location.pathname.includes('/sop')) ||
        (!lorStore.loaderEditor &&
          !lorStore.toolsClick &&
          window.location.pathname.includes('/lor')) ||
        (!uKSopStore.loaderEditor &&
          !uKSopStore.toolsClick &&
          window.location.pathname.includes('/uksop')) ||
        (!canadaSopStore.loaderEditor &&
          !canadaSopStore.toolsClick &&
          window.location.pathname.includes('/canadasop')) ||
        (!sopStore.loaderEditor &&
          !sopStore.toolsClick &&
          window.location.pathname.includes('/australiasop'))
      ) {
        if (
          (params?.id && window.location.pathname.includes('/sop')) ||
          (params?.id && window.location.pathname.includes('/uksop')) ||
          (params?.id && window.location.pathname.includes('/canadasop')) ||
          (params?.id && window.location.pathname.includes('/germanysop')) ||
          (params?.id && window.location.pathname.includes('/australiasop')) ||
          (params?.id && window.location.pathname.includes('/lor'))
        ) {
          let editorState: EditorState = await editorStore.get_editor(params?.id);
          hydrate_editor_from_state(editor, editorState);
        } else if (
          (!germanyStore.isFromCreateSop &&
            !germanyStore.toolsClick &&
            newContentStore.libraryClick) ||
          (!sopStore.isFromCreateSop && !sopStore.toolsClick && newContentStore.libraryClick) ||
          (!uKSopStore.isFromCreateSop && !uKSopStore.toolsClick && newContentStore.libraryClick) ||
          (!canadaSopStore.isFromCreateSop &&
            !canadaSopStore.toolsClick &&
            newContentStore.libraryClick) ||
          (!lorStore.isFromCreateSop && !lorStore.toolsClick && newContentStore.libraryClick)
        ) {
          let editorState: EditorState = await editorStore.get_editor(params?.id);
          hydrate_editor_from_state(editor, editorState);
        }  else {
          empty_editor(editor);
        }
      }
      // calling the endpoint in library screen
      else if (
        ((newContentStore.libraryClick &&(!sopStore.loaderEditor && window.location.pathname.includes('/australiasop')))||(newContentStore.libraryClick &&(!germanyStore.loaderEditor && window.location.pathname.includes('/germanysop')))||(newContentStore.libraryClick &&(!sopStore.loaderEditor && window.location.pathname.includes('/sop')))||(newContentStore.libraryClick &&(!canadaSopStore.loaderEditor && window.location.pathname.includes('/canadasop')))||(newContentStore.libraryClick &&(!lorStore.loaderEditor && window.location.pathname.includes('/lor')))||(newContentStore.libraryClick &&(!uKSopStore.loaderEditor && window.location.pathname.includes('/uksop'))) )
      ) {
        let editorState: EditorState = await editorStore.get_editor(params?.id);
        hydrate_editor_from_state(editor, editorState);
      }
    };

    hydrate_editor();
  }, [params?.id]);

  useEffect(() => {
    editor.update(() => {
      const root = $getRoot();
      root.clear();
      console.log(editorStore.sopText, 'SOPTEXT');
      let sopText = editorStore.sopText;
      sopText.replaceAll(/<\/br>/g, '');
      let splitByNewLine = sopText.split('\n');

      splitByNewLine.forEach((el) => {
        // Create a new ParagraphNode
        if (el != '') {
          const paragraphNode = $createParagraphNode();
          // Create a new TextNode
          const textNode = $createTextNode(el);
          paragraphNode.append(textNode);
          root.append(paragraphNode);
        }
      });
    });
  }, [editorStore.sopText]);

  useEffect(() => {
    const updateViewPortWidth = () => {
      const isNextSmallWidthViewport =
        CAN_USE_DOM && window.matchMedia('(max-width: 1025px)').matches;

      if (isNextSmallWidthViewport !== isSmallWidthViewport) {
        setIsSmallWidthViewport(isNextSmallWidthViewport);
      }
    };
    updateViewPortWidth();
    window.addEventListener('resize', updateViewPortWidth);

    return () => {
      window.removeEventListener('resize', updateViewPortWidth);
    };
  }, [isSmallWidthViewport]);
  return (
    <>
      <div
        className={`editor-container editor-shell ${showTreeView ? 'tree-view' : ''} ${
          !isRichText ? 'plain-text' : ''
        }`}
      >
        {isMaxLength && <MaxLengthPlugin maxLength={30} />}

        <DragDropPaste />
        <AutoFocusPlugin />
        <ClearEditorPlugin />
        <ComponentPickerPlugin />
        <EmojiPickerPlugin />
        <AutoEmbedPlugin />

        {/*<MentionsPlugin/>*/}
        <EmojisPlugin />
        {/*<HashtagPlugin/>*/}
        {/*<KeywordsPlugin/>*/}
        <SpeechToTextPlugin />
        <AutoLinkPlugin />
        {/*<CommentPlugin providerFactory={undefined} />*/}
        {isRichText ? (
          <>
            <HistoryPlugin externalHistoryState={historyState} />
            <RichTextPlugin
              contentEditable={
                <div className="editor-scroller">
                  <div className="editor" ref={onRef}>
                    <ContentEditable />
                  </div>
                </div>
              }
              placeholder={placeholder}
              ErrorBoundary={LexicalErrorBoundary}
            />
            <MarkdownShortcutPlugin />
            <CodeHighlightPlugin />
            <ListPlugin />
            <CheckListPlugin />
            <ListMaxIndentLevelPlugin maxDepth={7} />
            <TablePlugin
              hasCellMerge={tableCellMerge}
              hasCellBackgroundColor={tableCellBackgroundColor}
            />
            <TableCellResizer />
            <NewTablePlugin cellEditorConfig={cellEditorConfig}>
              <AutoFocusPlugin />
              <RichTextPlugin
                contentEditable={<ContentEditable className="TableNode__contentEditable" />}
                placeholder={null}
                ErrorBoundary={LexicalErrorBoundary}
              />
              {/*<MentionsPlugin/>*/}
              <HistoryPlugin />
              <ImagesPlugin captionsEnabled={false} />
              <LinkPlugin />
              <LexicalClickableLinkPlugin />
              <FloatingTextFormatToolbarPlugin />
            </NewTablePlugin>
            <ImagesPlugin />
            <InlineImagePlugin />
            <LinkPlugin />
            <PollPlugin />
            <TwitterPlugin />
            <YouTubePlugin />
            <FigmaPlugin />
            {!isEditable && <LexicalClickableLinkPlugin />}
            <HorizontalRulePlugin />
            {/*<EquationsPlugin/>*/}
            <ExcalidrawPlugin />
            <TabFocusPlugin />
            <TabIndentationPlugin />
            <CollapsiblePlugin />
            {floatingAnchorElem && !isSmallWidthViewport && (
              <>
                <DraggableBlockPlugin anchorElem={floatingAnchorElem} />
                <CodeActionMenuPlugin anchorElem={floatingAnchorElem} />
                <FloatingLinkEditorPlugin anchorElem={floatingAnchorElem} />
                <TableCellActionMenuPlugin anchorElem={floatingAnchorElem} cellMerge={true} />
                <FloatingTextFormatToolbarPlugin anchorElem={floatingAnchorElem} />
              </>
            )}
          </>
        ) : (
          <>
            <PlainTextPlugin
              contentEditable={<ContentEditable />}
              placeholder={placeholder}
              ErrorBoundary={LexicalErrorBoundary}
            />
            <HistoryPlugin externalHistoryState={historyState} />
          </>
        )}
        {(isCharLimit || isCharLimitUtf8) && (
          <CharacterLimitPlugin charset={isCharLimit ? 'UTF-16' : 'UTF-8'} maxLength={5} />
        )}

        {/*{isAutocomplete && <AutocompletePlugin/>}*/}
        {/*<div>{showTableOfContents && <TableOfContentsPlugin/>}</div>*/}
        {/*{shouldUseLexicalContextMenu && <ContextMenuPlugin/>}*/}
        {/*<ActionsPlugin isRichText={isRichText} />*/}

        <FloatingMenuPlugin MenuComponent={AIAssistHomeScreen} element={document.body} />
        {/*<AIAssistHomeScreen />*/}
      </div>
      {/*{showTreeView && <TreeViewPlugin />}*/}

      {!userStore.premium && !window.location.pathname.includes('/lor') && (
        <div
          className="rounded-lg text-center flex"
          style={{
            height: '500px',
            boxShadow: '0px 13px 20px 0px rgba(143, 152, 169, 0.20)',
            background: 'linear-gradient(180deg, rgba(255, 165, 215, 0.00) 0%, #FFE1F2 100%)',
            position: 'relative',
            marginTop: '-512px',
            zIndex: 1,
            backgroundColor: 'white',
            width: '100%',
            borderLeft: '2px solid #C7CBD4',
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            borderRight: '2px solid #C7CBD4',
            borderBottom: '2px solid #C7CBD4',
          }}
        >
          <div className="w-full">
            <p className="font-extrabold text-2xl mt-40 text-[#3C424C]">
              Get the access to the complete SOP
            </p>
            <p className="text-base text-[#737E93] mt-4">
              Want to get full access along with Cambri AI Editor? Go{' '}
              <span className="font-extrabold">Pro</span> now!
            </p>
            <button
              onClick={() => navigate('/paymentspage')}
              className="bg-[#F151AB] mt-8 rounded-3xl text-white py-[11px] px-[24px]"
            >
              UPGRADE TO PRO NOW
            </button>
          </div>
        </div>
      )}
    </>
  );
});

export default Editor;
