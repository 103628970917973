import { UserProfileApi } from './../api/user-profile-api';
import { action, makeAutoObservable, runInAction, toJS } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
import { safe_get } from '../pages/utils';
import _, { cloneDeep } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

export class UserProfileStore {
  constructor(private userProfileApi: UserProfileApi) {
    makeAutoObservable(this);
    makePersistable(this, {
      name: 'UserProfileStore',
      properties: [],
      storage: window.localStorage,
    });
  }

  defaultUserProfileJsonData = {

    location: {
      country:'',
      street_address: '',
      city: '',
      state: '',
      pincode: '',
    },
    education: [
      {
        id: uuidv4(),
        Level_of_Education: '',
        Field_of_Study: '',
        School_Name: '',
        From: '',
        To: '',
      },
    ],
    work_experience: [
      {
        id: uuidv4(),
        Company: '',
        Job_Title: '',
        Country: '',
        isCurrentlyWorking: true,
        From: '',
        To: '',
      },
    ],
    skills: [],
    certifications: [
      {
        id: uuidv4(),
        name: '',
      },
    ],
    isFirstLogin:false,
    licenses: [
      {
        id: uuidv4(),
        name: '',
      },

    ],
    user_name : '',

  };
  userDetails:any=[];
  userprofile=[];
  selectedFile: object = {};
  resume: string = '';
  profileScreen:boolean=false
  profile_id:number=0;
  temp_profile_id:number;
  userProfilejsonData = { ...this.defaultUserProfileJsonData };
  userPersonalDetails = {...this.defaultUserProfileJsonData};
  setUserData:boolean=false;
  isFetchUsersData:boolean = false;
  text:string='';
  profile:number;
  selectedId:number;
  loading:boolean=false;
  availableCredits :number;
  skillsSelected:boolean = false;

  @action 
setSkillsSelected(value){
  this.skillsSelected = value
}

  @action setProfileScreen(bool){
    this.profileScreen = bool
  }

  @action
  async get_profile() {
    const resp = await this.userProfileApi.get_profile();
    runInAction(() => {
      if (resp.status === 200 && resp.data) {
          this.userProfilejsonData = resp.data;
          this.availableCredits = resp.data.availableCredits;
         // this.defaultUserProfileJsonData =resp.data;
      }
    });
  }

  @action
  async set_extracted_resume(data) {
    this.userProfilejsonData = data;
   //this.defaultUserProfileJsonData = data;
  }

  @action
  async update_profile(hide_resume_creation_modal) {
    const payload = {
      profile_id: this.profile_id,
      profile_info: this.userProfilejsonData
    };
    this.loading=true;
    const resp = await this.userProfileApi.update_profile(payload);
    this.loading = false;
    runInAction(() => {
      if (resp.status === 200) {
        if (resp.data) {
          if(this.setUserData){
            this.userProfilejsonData = resp.data;
          }
          else{
            this.userPersonalDetails=resp.data;
          }
         // this.defaultUserProfileJsonData = resp.data;

          this.profile_id=resp.data.profile_id;

        }
      }
    });
  }


  @action
  updateUserJsonDataArr(slot, id, keyValue, value) {
    const temp = cloneDeep(this.userProfilejsonData);
    const slotItems = safe_get(temp, slot, []);
    slotItems.forEach((item) => {
      if (item.id === id) {
        item[keyValue] = value;
      }
    });
    this.userProfilejsonData = temp;
  }
  @action
  FetchUsersData(value:boolean){
    this.isFetchUsersData=value
  }

  @action
  updateUserProfileJsonData(key, value) {
    this.userProfilejsonData = {
      ...this.userProfilejsonData,
      [key]: value,
    };
  }

  @action
  updateUserProfileJsonDataObj(slot, key, value) {
    const temp = cloneDeep(this.userProfilejsonData);
    temp[slot] = {
      ...temp[slot],
      [key]: value,
    };
    this.userProfilejsonData = temp;
  }

  @action
  addEducationList() {
    this.userProfilejsonData.education.push({
      id: uuidv4(),
      Level_of_Education: '',
      Field_of_Study: '',
      School_Name: '',
      From: '',
      To: '',
    });
  }

  @action
  addWorkExperienceList() {
    this.userProfilejsonData.work_experience.push({
      id: uuidv4(),
      Company: '',
      Job_Title: '',
      Country: '',
      isCurrentlyWorking: false,
      From: '',
      To: '',
    });
  }

  @action
  addCertificateList() {
    this.userProfilejsonData.certifications.push({
      id: uuidv4(),
      name: '',
    });
  }

  @action
  addLicenseList() {
    this.userProfilejsonData.licenses.push({
      id: uuidv4(),
      name: '',
    });
  }
  @action setResume(resume) {
    this.resume = resume;
  }

  @action setSelectedFile(file) {
    this.selectedFile = file;
  }
  @action setSkills(skill) {
    const skillArr: any = [];
    skillArr.push(skill);
    this.defaultUserProfileJsonData.skills = [...this.defaultUserProfileJsonData.skills];
  }
  @action
  async get_user_profile_name_id_list(){
    const resp = await this.userProfileApi.get_user_profile_name_id_list();
    this.userprofile=resp.data.user_profiles
     let radiobuttons = this.userprofile
    .filter((user: any) => user.user_name !== null && user.user_name !== undefined)
    .map((user: any) => { return {'user_name':user.user_name, 'profile_id': user.profile_id}});
    this.userDetails=radiobuttons;
    this.availableCredits = resp.data.availableCredits;
  }
 @action
async get_user_list() {

  const payload = {
   user_name: this.userProfilejsonData.user_name, // Include the userName in the payload

  };

  const resp = await this.userProfileApi.get_user_list(payload);
  this.profile_id=resp.data.profile_id;
  this.userProfilejsonData=resp.data;
  this.availableCredits = resp.data.availableCredits;
  //this.temp_profile_id=resp.data.profile_id;
  return resp;
}

@action
async get_user_id(id){
  const resp =await this.userProfileApi.get_user_id(id);
  this.userPersonalDetails=resp.data;
  this.temp_profile_id=resp.data.profile_id;
}

@action
setSelectedId(value)
{
  this.selectedId = value

}
@action
async rename_user(payload){
  const payloadUser = {
    profile_id: this.profile_id,
    name: this.userProfilejsonData.user_name
  };
  const resp = await this.userProfileApi.rename_user(payloadUser);
  this.loading = false;
  runInAction(() => {
    if (resp.status === 200) {
      if (resp.data) {
        if(this.setUserData){
          this.userProfilejsonData = resp.data;
        }
        else{
          this.userPersonalDetails=resp.data;
        }
       // this.defaultUserProfileJsonData = resp.data;

        this.profile_id=resp.data.profile_id;

      }
    }
  });}
@action
async get_delete(id){
  const resp= await this.userProfileApi.get_delete(id);
  return resp;
}
@action
setUserProfileData = (key, value) => {
  // Update the specific property in userPersonalDetails
  this.userPersonalDetails.user_name = value;
};
@action
resetUserData(){
  this.userProfilejsonData = { ...this.defaultUserProfileJsonData };
  this.userPersonalDetails = { ...this.defaultUserProfileJsonData };
  this.profile_id = 0;
  this.userPersonalDetails.user_name = "";
  this.setSelectedId(undefined);
}
  }

