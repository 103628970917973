import { observer } from 'mobx-react-lite';
import { Popover } from '@headlessui/react';

const EditPopover = observer((props: any) => {
  const { sopStore } = props;
 
  return (
    <>
      {sopStore.edit_clicked && (
        <>
          <Popover style={{ boxShadow: '0px 0px 20px 0px rgba(0, 0, 0, 0.15)' }} className={`absolute z-40 top-0 ${window.location.pathname.includes('/lor') ? 'mt-[14.5rem]':window.location.pathname.includes('/canadasop') && sopStore.slot === 'explain_the_factors_that_make_canada_you_preferred_destination_for_studying' ? 'mt-[-14.5rem]'  : window.location.pathname.includes('/canadasop') || window.location.pathname.includes('/uksop') ? 'mt-[4.5rem]': 'mt-2'} ml-2 right-[-125%] w-full bg-white rounded-md shadow-md `}>

            {({ open }) => (
              <>
              <div className='bg-[#F8F9FA] border-b mb-5 border-b-[#E3E5E9] px-5 py-2 text-[#292D35] text-lg font-medium'>Edit field</div>
                <div className='px-5'>{props.children}</div>
                <div className="bg-[#F8F9FA] border-t border-b-[#E3E5E9] flex justify-end text-xs uppercase font-bold p-5">
                  <button
                    className="text-[#737E93] "
                    onClick={() => sopStore.setEditClicked(false)}
                  >
                    Cancel
                  </button>
                  <button onClick={props.handleClickUpdate} className="ml-2 px-1 text-[#A033FF]">
                    Update
                  </button>
                </div>
              </>
            )}
          </Popover>
        </>
      )}
    </>
  );
});

export default EditPopover;
