import { v4 as uuidv4 } from 'uuid';
import { PlusButton } from '../../../components/plus-button/plus-button';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../hooks/use-store';

import ProfileCustomTextField from '../../../components/profile-text-field/profile-text-field';
let template = {
  id: uuidv4(),
  placeholder: 'Enter',
  name: 'Add a Licenses',
  type: 'text',
  label: 'Add a Licenses',
};

const Licenses = observer(() => {
  const { userProfileStore } = useStore();
  const AddLicenseList = () => {
    userProfileStore.addLicenseList();
  };

  const handleChange = (e, itemId) => {
    userProfileStore.updateUserJsonDataArr('licenses', itemId, 'name', e.target.value);
  };

  return (
    <>
      <div>
        {userProfileStore.userProfilejsonData.licenses &&
          userProfileStore.userProfilejsonData.licenses.length > 0 &&
          userProfileStore.userProfilejsonData.licenses.map((item, index) => {
            return (
              <div className="flex items-center">
                <ProfileCustomTextField
                  placeholder={template['placeholder']}
                  name={template['name']}
                  type={template['type']}
                  label={template['label'] + '  ' + (index + 1).toString()}
                  slot={'licenses'}
                  itemId={item['id']}
                  keyValue={'name'}
                  value={item['name']}
                  onChange={(e) => handleChange(e, item['id'])}
                />
                {index === 0 && <PlusButton name={item.name} handleClick={AddLicenseList} />}
              </div>
            );
          })}
      </div>
    </>
  );
});

export default Licenses;
