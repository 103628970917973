import {ApisauceInstance, create} from "apisauce";
import {ApiConfig, DEFAULT_API_CONFIG} from "./api-config";


export class Api {

  apisauce: ApisauceInstance;
  config: ApiConfig
  userToken: string = ""

  constructor(config: ApiConfig = DEFAULT_API_CONFIG) {
    this.config = config
  }

  async setUp() {
    this.apisauce = create({
      baseURL: this.config.url,
      timeout: this.config.timeout,
      headers: {
        Accept: "application/json",
        "X-API-Secret": this.config.apiSecret,
        "X-Token": this.userToken,
        "Access-Control-Allow-Methods":"POST, GET, OPTIONS"
      },
    })
  }
}
