import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { classNames } from '../../utils';
import { useParams } from 'react-router-dom';
import { Loading } from '../../../components/Loading';
import SopFooter from '../../sop-components/sop-footer';
import SopContainer from '../../sop-components/sop-container';
import SopStepper from '../../sop-components/sop-stepper';
import FinalOutputScreen from '../usa_sop/final_output_screen/final_output_screen';
import About_college from '../usa_sop/about_college/about_college';
import Motivation from '../usa_sop/motivation/motivation';
import Goals from '../usa_sop/goals/goals';
import Academics from '../usa_sop/qualifications/qualifications';
import Contribution from '../usa_sop/contribution/contribution';
import { useStore } from '../../../hooks/use-store';
import { OpenApiStatus } from '../../../api/open-api-status';

const AustraliaSop = observer((props: any) => {
  const {
    sopStore,
    dashboardStore,
    newContentStore,
    sopProfileSelectionStore,
    userStore,
    userProfileStore,
  } = useStore();
  const path = window.location.pathname;
  let { id } = useParams();
console.log(id)
  useEffect(()=>{
    if(sopProfileSelectionStore.clearSopStep === false && newContentStore.libraryClick === false){
     sopStore.clearSop();
    }
    },[])

  useEffect(() => {
    sopStore.get_questions(155);
    sopStore.setToolId(155);
    sopStore.setFinalOutputScreen(false);
    sopStore.set_australia_sop(true);
    newContentStore.set_active_folder(null);
    if (path.includes('/sop')) {
      sopStore.set_sop_id(id);
      dashboardStore.setScreenName('Statement of Purpose');
      if (!id && userStore.isenterprise && newContentStore.libraryClick === false) {
        sopProfileSelectionStore.show_sop_selection_creation_modal();
        userProfileStore.resetUserData();
      }
    }
    if (id === '155' && userStore.isenterprise && newContentStore.libraryClick === false) {
      sopProfileSelectionStore.show_sop_selection_creation_modal();
      userProfileStore.resetUserData();
    } 
    if(newContentStore.libraryClick === true){
      sopProfileSelectionStore.hide_sop_selection_creation_modal();
   }
   if(newContentStore.libraryClick === true && window.location.pathname.includes('/dashboard')){
    sopProfileSelectionStore.show_sop_selection_creation_modal();
    userProfileStore.resetUserData();
   }
    else if (
      !userStore.isenterprise &&
      sopProfileSelectionStore.sopProfileSelectionValues.length <= 0
    ) {
      sopProfileSelectionStore.show_sop_selection_creation_modal();
      userProfileStore.resetUserData();
    } else {
      sopProfileSelectionStore.show_sop_selection_creation_modal();
      userProfileStore.resetUserData();
    }
    if (path) sopProfileSelectionStore.resetSopSelection();
    fetchDataForSOP();
    return () => {
      sopStore.set_selected_textarea('', '', '');
      sopProfileSelectionStore.resetSopSelection();
    };
  }, []);

  useEffect(() => {
    sopProfileSelectionStore.sopProfileSelection = null;
      sopProfileSelectionStore.current_sop_name = '';
  }, []);

  const fetchDataForSOP = async () => {
    if(newContentStore.libraryClick === false && path.includes('/dashboard')){
    sopStore.clear_sop_state();
    await sopStore.get_sop_workspace(id, newContentStore.activeFolder);
    }
    if(newContentStore.libraryClick === false  && sopProfileSelectionStore.clearSopStep === true &&  path.includes('/australiasop/155') ){
      sopStore.clear_sop_state();
      await sopStore.get_sop_workspace(sopStore.documentId, sopStore.parentId);
    }
    else{
      sopStore.library_sop_state()
      await sopStore.get_sop_workspace(sopStore.documentId, sopStore.parentId);
    }
  }
  async function fetchData() {
    try {
      const openApiStatus = new OpenApiStatus();
      await openApiStatus.fetchOpenAIStatus();
    } catch (error) {
      console.error('An error occurred:', error);
    }
  }
useEffect(()=>{
  fetchData();
},[])
const OpenApi = localStorage.getItem('openApiStatus')

  return (
    <>
      {sopStore.jsonDataLoaded ? (
        !sopStore.final_output_screen ? (
          <div
            className={classNames(
              dashboardStore.showDashboardSideBar ? 'calc(100vw - 320px)' : 'w-screen',
              ''
            )}
          >
            <div
              id="header"
              className={classNames(
                dashboardStore.showDashboardSideBar ? 'calc(100vw - 320px)' : 'w-screen'
              )}
              style={{
                position: 'fixed',
                width: dashboardStore.showDashboardSideBar ? 'calc(100vw - 320px)' : '100vw',
                overflow: 'hidden',
                top: 80,
                boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
                zIndex:1,
                marginTop:OpenApi == 'true' ? '50px' : '0px'
              }}
            >
              <div
                className={`${
                  dashboardStore.showDashboardSideBar ? 'px-10' : 'px-20'
                } bg-white border-b-2 border-gray-100`}
              >
                <SopStepper sopStore={sopStore} />
              </div>
            </div>
            <div
              className={`${dashboardStore.showDashboardSideBar ? 'px-10' : 'px-20'} pb-10`}
              id="content"
              style={{
                position: 'relative',
                width: dashboardStore.showDashboardSideBar ? 'calc(100vw - 320px)' : '100vw',
                overflow: 'auto',
                marginTop: 280,
                height: 'calc(100vh - 300px)',
              }}
            >
              <SopContainer
                step1={<About_college />}
                step2={<Motivation />}
                step3={<Goals />}
                step4={<Academics />}
                step5={<Contribution />}
                sopStore={sopStore}
              />
            </div>
            <div
              className="py-2 px-20 border-t-2 bg-white shadow-lg w-full z-50"
              id="footer"
              style={{
                position: 'fixed',
                width: dashboardStore.showDashboardSideBar ? 'calc(100vw - 320px)' : '100vw',
                bottom: '0px',
                left: dashboardStore.showDashboardSideBar ? '320px' : '0px',
                right: '0px',
                overflow: 'hidden',
              }}
            >
              <SopFooter sopStore={sopStore} steps={5} />
            </div>
          </div>
        ) : (
          <FinalOutputScreen />
        )
      ) : (
        <Loading />
      )}
    </>
  );
});
export default AustraliaSop;
