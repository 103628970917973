import { observer } from 'mobx-react-lite';
import SopDescription from './sop-description';
import {toast} from 'react-toastify';
const SopStepper = observer((props: any) => {
  const { sopStore } = props;

  const sopStepClickHandler = (id, desc) => {
    sopStore.generatedOutput = '';
    sopStore.set_active_step(id);
    sopStore.setSopDescription(desc);
  };
  const OpenApiStatus = localStorage.getItem('openApiStatus')

  return (
    <>
      <nav aria-label="Progress" className={`"bg-white pt-4  ${OpenApiStatus && "mb-[0rem] z-[2]"}"`}>
        <ol role="list" className="space-y-4 md:flex md:space-x-2 md:space-y-0">
          {sopStore.steps.map((step) => (
            <>
              <li
                key={step.name}
                className="md:flex-1"
                onClick={() => {
                  if (sopStore.selecteduniversitiy && sopStore.selectedDegree && sopStore.selectedCourse && sopStore.sopjsonData.full_name!='' && sopStore.sopjsonData.nationality != '' &&
                  sopStore.sopjsonData.motivation_for_studying_in_canada != '' &&
                  sopStore.sopjsonData.intend_to_pursue != '' &&
                  sopStore.sopjsonData.university_to_pursue_this_course != '' &&
                  sopStore.sopjsonData.purpose_of_writing_this_statement_of_purpose != '' ) {
                    if(sopStore.availableCredits>= sopStore.requiredCredits){
                    sopStepClickHandler(step.id, step.desc);
                    }
                    else{
                      toast.error("Sorry,You don't have enough credits");
                    }
                  }
                }}
              >
                {step.current && step.completed ? (
                  <div
                    // href={step.href}
                    className="text-[#292D35] flex h-16 flex-col border bg-purple-50 rounded-lg border-purple-500 py-2 pl-4 cursor-pointer"
                    aria-current="step"
                  >
                    <div className="flex items-center">
                      <div className="flex flex-col">
                        <span className="text-[10px] font-medium uppercase pb-1">{step.id}</span>
                        <span className="text-lg">{step.name}</span>
                      </div>
                      <div className="w-fit ml-auto p-2 pr-6 text-3xl">
                        <i
                          className="fa-light fa-circle-check"
                          style={{ color: '#73C124', fontSize: '20px' }}
                        ></i>
                      </div>
                    </div>
                  </div>
                ) : step.current ? (
                  <div
                    // href={step.href}
                    className="text-[#292D35] shadow-lg flex min-h-16 max-h-20 flex-col border bg-purple-50 rounded-lg border-[#A033FF] py-2 pl-4 cursor-pointer"
                    aria-current="step"
                  >
                    <span className="text-[10px] font-medium uppercase">{step.id}</span>
                    <span className="text-lg font-bold">{step.name}</span>
                  </div>
                ) : step.completed ? (
                  <div
                    // href={step.href}
                    className="flex min-h-16 max-h-20 flex-col border bg-purple-50 rounded-xl py-2 pl-4 cursor-pointer"
                    aria-current="step"
                  >
                    <div className="flex">
                      <div className="flex flex-col">
                        <span className="text-[10px] font-medium uppercase">{step.id}</span>
                        <span className="text-lg">{step.name}</span>
                      </div>
                      <div className="w-fit ml-auto p-2 pr-6 text-3xl">
                        <i
                          className="fa-light fa-circle-check"
                          style={{ color: '#73C124', fontSize: '20px' }}
                        ></i>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div
                    // href={step.href}
                    className="flex min-h-16 max-h-20 flex-col border bg-gray-50 rounded-xl border-gray-200 py-2 pl-4 cursor-pointer"
                  >
                    <span className="text-[10px] text-gray-400 uppercase">{step.id}</span>
                    <span className="text-lg text-[#737E93]">{step.name}</span>
                  </div>
                )}
              </li>
            </>
          ))}
        </ol>
        <SopDescription  sopStore={sopStore} />
      </nav>
    </>
  );
});
export default SopStepper;
