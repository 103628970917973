import { LexicalComposer } from '@lexical/react/LexicalComposer';
import EditorNodes from './nodes/EditorNodes';
import PlaygroundEditorTheme from './themes/PlaygroundEditorTheme';
import { SharedHistoryContext } from './context/SharedHistoryContext';
import { TableContext } from './plugins/TablePlugin';
import { SharedAutocompleteContext } from './context/SharedAutocompleteContext';
import SimpleEditor from './simple-editor';
import './editor-shell.css';
import { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useStore } from '../../hooks/use-store';
import { Loading } from '../../components/Loading';
import { observer } from 'mobx-react-lite';


const SimpleRichEditor = observer((props) => {

  const initialConfig = {
    editorState: null,
    namespace: 'Cambri',
    nodes: [...EditorNodes],
    onError: (error: Error) => {
      throw error;
    },
    theme: PlaygroundEditorTheme
  };




  return (
    <>
   
      <div>
        <div className="editor-shell-container">
          <LexicalComposer initialConfig={initialConfig}>
            <SharedHistoryContext>
              <TableContext>
                <SharedAutocompleteContext>
                  <div className="simple-editor-shell">
                    <SimpleEditor />
                  </div>
                </SharedAutocompleteContext>
              </TableContext>
            </SharedHistoryContext>
          </LexicalComposer>
        </div>
      </div>
    </>
  );
}
)

export default SimpleRichEditor;