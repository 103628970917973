import {observer} from "mobx-react-lite";
import {useEffect, useState} from "react";
import {useStore} from "../../hooks/use-store";
import {MultiSelect} from "react-multi-select-component";

export const MultiSelectDropDown = observer((props: any) => {
  const {toolsStore} = useStore()
  const [selected, setSelected] = useState([])

  const valueChangeHandler = (selectedValues) => {
    let selectedStr = ''
    setSelected(selectedValues)
    let i;
    for (i = 0; i < selectedValues.length; i++) {
      selectedStr = selectedStr.concat(selectedValues[i].value)
      if (selectedValues.length > 1 && i === selectedValues.length - 2) {
        selectedStr = selectedStr.concat(' and ')
      } else if (i !== selectedValues.length - 1) {
        selectedStr = selectedStr.concat(', ')
      }
    }
    toolsStore.updateComposeObject(props.slot, selectedStr)
  }

  useEffect(() => {
    toolsStore.updateComposeObject(props.slot, props.default)
  }, [])

  return (
    <>
      <div className='flex flex-col py-4'>
        <label className='py-2 font-bold'>{props.display}</label>
        <MultiSelect
          options={props.values}
          value={selected}
          onChange={valueChangeHandler}
          labelledBy="Select"
        />
      </div>
    </>
  )
});
