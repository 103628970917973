import { action, makeAutoObservable, runInAction, toJS } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
import { SopApi } from '../api/sop-api';
import { safe_get } from '../pages/utils';
import _, { clone, cloneDeep } from 'lodash';
import { makeid_alpa_numeric } from '../utils';
import reason_for_chosen_university_templates from '../pages/sops/usa_sop/templates/reason_for_chosen_university_templates.json';
import reason_pursue_course from '../pages/sops/usa_sop/templates/reason_pursue_course_templates.json';
import short_term_goals from '../pages/sops/usa_sop/templates/short_term_goals.json';
import long_term_goals from '../pages/sops/usa_sop/templates/long_term_goals.json';
import life_experiences from '../pages/sops/usa_sop/templates/life_experience_long_form.json';
import role_model from '../pages/sops/usa_sop/templates/role_model_idol_celebrity_long_form.json';
import literature from '../pages/sops/usa_sop/templates/Literature.json';
import abstract from '../pages/sops/usa_sop/templates/abstract.json';

import { toast } from 'react-toastify';

import sanitizeHtml from 'sanitize-html';
import { GermanyApi } from '../api/germany-api';
export const sanitizeConf = {
  allowedTags: [],
  allowedClasses: {
    p: [],
  },
  allowedAttributes: {
    p: [],
  },
};

interface PlagiarismResult {
  plagiarism_result: {
    status: number;
    results: {
      batch: any[];
      score: {
        identicalWords: number;
        aggregatedScore: number;
        minorChangedWords: number;
        relatedMeaningWords: number;
      };
      database: any[];
      internet: {
        id: string;
        url: string;
        tags: any[];
        title: string;
        metadata: {
          filename: string;
        };
        totalWords: number;
        introduction: string;
        matchedWords: number;
        similarWords: number;
        identicalWords: number;
        paraphrasedWords: number;
      }[];
      repositories: any[];
    };
    notifications: {
      alerts: any[];
    };
    scannedDocument: {
      scanId: string;
      credits: number;
      enabled: {
        aiDetection: boolean;
        plagiarismDetection: boolean;
      };
      metadata: {
        filename: string;
      };
      totalWords: number;
      creationTime: string;
      totalExcluded: number;
      expectedCredits: number;
    };
    developerPayload: {file_id:number,user_id:number};
  };
  availableCredits: number;
}

const initialPlagiarismObject: PlagiarismResult = {
  plagiarism_result: {
    status: 0,
    results: {
      batch: [],
      score: {
        identicalWords: 0,
        aggregatedScore: 999,
        minorChangedWords: 0,
        relatedMeaningWords: 0,
      },
      database: [],
      internet: [
        {
          id: 'dummy-id',
          url: 'http://example.com/',
          tags: [],
          title: 'Dummy Internet Source',
          metadata: {
            filename: 'dummy-source',
          },
          totalWords: 0,
          introduction: 'This is a dummy internet source.',
          matchedWords: 0,
          similarWords: 0,
          identicalWords: 0,
          paraphrasedWords: 0,
        },
      ],
      repositories: [],
    },
    notifications: {
      alerts: [],
    },
    scannedDocument: {
      scanId: 'dummy-scan-id',
      credits: 0,
      enabled: {
        aiDetection: false,
        plagiarismDetection: true,
      },
      metadata: {
        filename: 'dummy-file.txt',
      },
      totalWords: 0,
      creationTime: '2023-08-05T11:43:58.683606Z',
      totalExcluded: 0,
      expectedCredits: 0,
    },
    developerPayload: {'user_id': 0, 'file_id': 0},
  },
  availableCredits: 0,
};

export class GermanyStore {
  activeStep = 'Step 1';
  isFileCreated = false;
  selecteduniversitiy = null;
  selectedDegree = null;
  selectedCourse = null;
  jsonDataLoaded = false;
  selectedCard = -1;
  cardSelected = false;
  selectedCardDescription = '';
  newComposeObject = {};
  generatedOutput = '';
  loading = false;
  loadingbutton:boolean=false;
  showTemplate = false;
  editor_data = { blocks: [], entityMap: {} };
  editor_id: string = makeid_alpa_numeric(6);
  sopId: number;
  current_folder_id: number;
  sopName: string = '';
  isMovingSopDocDialogOpen: false;
  breadCrumbs: {name:string,isfile:boolean}[];
  availableCredits: number;
  requiredCredits : number;
  college_summary = '';
  folderid = null;
  sop_description =
    'Tell us your top university and course choices. Your details will help us shape an SOP that showcases your fit perfectly.';
  final_output_screen = false;
  edit_clicked = false;
  slot = '';
  slotId = '';
  toolId: number;
  is_premium_user: boolean = false;
  questions: [];
  stepsArr = ['Step 1', 'Step 2', 'Step 3', 'Step 4', 'Step 5','Step 6'];
  showPlagiarism = false;
  plagiarismTotalRequestDuration = 200000;
  plagiarismIntervalDuration = 30000;
  plagiarismRequestInProgress = false;
  plagiarism_result: PlagiarismResult = initialPlagiarismObject;
  plagiarismRequestLoading = false;
  plagiarismErrorOccured = false;
  isFromCreateSop=false;
  isCanadaSop=false;
  isAustraliaSop = false;
  templateIds:any = []
  state:any;
  lastActiveStepClick:any;
  libraryClick : boolean = false;
  documentId: any;
  parentId:any;
  isGermany:boolean=true;
  motivationPlaceholder: any;
  universityDataLor:any;
loader:boolean=false;
saveAndNextButton:boolean=false;
loaderEditor:boolean=false;
toolsClick:boolean=false;

@action 
setToolsClick(value){
  this.toolsClick = value
}

  @action
  setUniversityDataLor(value){
    this.universityDataLor = value
  }


  @action
  setMotivationPlaceholder(value){
    this.motivationPlaceholder= value
  }

  @action setIsGermany(bool) {
    this.isGermany = bool
  }

  @action
  setLibraryClick(value){
    this.libraryClick = value;
  }

  @action setTemplateIndex(id) {
    this.templateIds.push(id);
  }

  @action setToolId(id) {
    this.toolId = id;
  }

  @action setSlot(slot) {
    this.slot = slot;
  }

  @action setEditClicked(bool) {
    this.edit_clicked = bool;
  }

  @action setFinalOutputScreen(bool) {
    this.final_output_screen = bool;
  }
  @action setSlotId(id) {
    this.slotId = id;
  }

  @action setIsFromCreateSop(bool){
    this.isFromCreateSop=bool;
  }

  steps = [
    {
      id: 'Step 1',
      name: 'About College',
      href: '#',
      current: true,
      completed: false,
      desc: 'Tell us your top university and course choices. Your details will help us shape an SOP that showcases your fit perfectly.',
      selectedTemplate:0
    },
    {
      id: 'Step 2',
      name: 'Motivation',
      href: '#',
      current: false,
      completed: false,
      desc: ' What keeps you going? Describe the reasons behind your pursuit of a Masters degree, including academic, personal, and professional motivations.',
      selectedTemplate:0
    },
    {
      id: 'Step 3',
      name: 'Goals/Aspirations',
      href: '#',
      current: false,
      completed: false,
      desc: 'Help us understand your vision and ambitions that guide your pursuit of your chosen program, detailing both the short-term and long-term goals that shape your journey ahead.',
      selectedTemplate:0
    },
    {
      id: 'Step 4',
      name: 'Education/Qualification',
      href: '#',
      current: false,
      completed: false,
      desc: 'Talk about your educational experiences so far and how they have helped you pursue your career goals.',
      selectedTemplate:0
    },
    {
      id: 'Step 5',
      name: 'Professional experience',
      href: '#',
      current: false,
      completed: false,
      desc: ' Reflect on your professional life, showcasing roles, key achievements, and skills that have shaped your career path and align with your future goals.',
      selectedTemplate:0
    },


      {
        id: 'Step 6',
        name: 'Fit/Contributions',
        href: '#',
        current: true,
        completed: false,
        desc: 'Share your passion for research and how you plan to contribute to the university community with your unique insights and collaborative spirit.',
        selectedTemplate:0
      }

  ];

  defaultSopJsonData = {
    course: {},
    university: {},
    degree: {},
    word_count: '1000',
    reason_for_doing_course: '',
    drives_passion_specific_area_study_discover_interest: '',
    reason_for_chosen_university: '',
    chosen_specific_university_country_features_align_requirements: '',
    motivation: {},
    short_term_goals_long_term_ambitions_post_masters_detailed_career_pathways_entrepreneurial_ventures_explore: '',
    discuss_significant_challenges_faced_educational_professional_journey_overcame: '',
    qualifications: [],
    contributions: [],
    professionalExperience:[],
    choose_undergraduate_major_field_study_far: '',
    highlight_academic_honors_scholarships_significant_extracurricular_involvements_educational_journey: '',
    describe_major_academic_projects_completed_objectives_challenges_personal_contributions: '',
    specific_areas_research_captivate_interest_contribute_fields_research_focused_degree_phd_program: '',
    consider_prepared_degree_persuade_admissions_committee_select: '',
    ways_foresee_contributing_university_environment_experiences_interests: '',
    share_anecdotes_exemplify_leadership_qualities_ability_engage_collaborate_others: '',
    taken_career_educational_break_explain_productively_utilized_time:'',
    valuable_skills_acquired_professional_experiences_specific_examples_capabilities:'',
    planning_switch_fields_motivates_change_prepared_transition:'',
    defaultQualifications: [],
    profile_id: null,
    templates: [] as number[],
    lastActiveStep: this.activeStep,
  };

  templates = {
    reason_pursue_course: [reason_pursue_course],
    reason_for_chosen_university: [reason_for_chosen_university_templates],
    short_term_goals: [short_term_goals],
    long_term_goals: [long_term_goals],
    life_experiences: [life_experiences],
    role_model: [role_model],
    literature: [literature],
    abstract: [abstract],
    motivation: [],
  };

  templatesForScreen = {};
  examplesForScreen = {};
  premium:boolean=false;
  @action setTemplates(templates) {
    this.templates['motivation'] = templates;
  }

  selected_text_area = {
    name: '',
    template_id: '',
    template_data: '',
  };
  sopjsonData = { ...this.defaultSopJsonData };
  loaingbutton:boolean=false;
  constructor(public germanyApi: GermanyApi) {
    makeAutoObservable(this);
    makePersistable(this, { name: 'GermanyStore', properties: ['is_premium_user' ], storage: window.localStorage });
    this. setIsGermany(false)
  }

  @action
  setSopDescription(content) {
    this.sop_description = content;
    console.log(this.sop_description,"CONTENTDESC")
  }

  @action
  updateSopJsonData(key, value) {
    if (key === 'motivation') {
      this.sopjsonData = {
        ...this.sopjsonData,
        [key]: {
          value: value,
          key: this.selectedCard ? this.selectedCard : this.sopjsonData.motivation['key'],
          cardTitle: this.selectedCardDescription
            ? this.selectedCardDescription
            : this.sopjsonData.motivation['question'],
        },
      };
    } else {
      this.sopjsonData = {
        ...this.sopjsonData,
        [key]: value,
      };
    }
  }

  @action
  templateExists(question) {
    return (
      (this.templatesForScreen[question] && this.templatesForScreen[question].length > 0) || false
    );
  }

  @action
  exampleExists(question) {
    return (
      (this.examplesForScreen[question] && this.examplesForScreen[question].length > 0) || false
    );
  }

  @action
  setIsSopDocMovingDialogOpen(payload) {
    this.isMovingSopDocDialogOpen = payload;
  }

  @action
  updateSopJsonDataArr(slot, key, value) {
    const temp = clone(this.sopjsonData);
    safe_get(temp, slot, []).map((item) => {
      if (item.id === key) {
        item.value = value;
      }
      this.sopjsonData = temp;
    });
  }

  @action
  updateSopJsonObj(slot, value) {
    const temp = clone(this.sopjsonData);
    temp[slot] = {};
    if (slot == 'motivation') {
      temp[slot] = {
        key: this.selectedCard,
        value: value,
        cardTitle: this.selectedCardDescription,
      };
    } else {
      temp[slot] = {
        key: this.selectedCard,
        value: value,
      };
    }
    this.sopjsonData = temp;
  }

  @action
  updateCurrentStatusForStep(stepName) {
    this.steps.map((step) => {
      if (step.id === stepName) {
        step.current = true;
      } else {
        step.current = false;
      }
    });
  }

  @action
  updateSelectedTemplate(stepName,id) {
     this.steps.forEach((step) => {
      if(step.id == stepName){
          step.selectedTemplate = id
      }
     })
  }

  @action
  updateSelectedTextarea(textareaName) {
    console.log(textareaName, 'textAreaname');
  }

  @action
  set_selected_textarea(textareaName, id, content) {
    this.selected_text_area.name = textareaName;
    this.selected_text_area.template_id = id;
    this.selected_text_area.template_data = content;
    this.updateSelectedTextarea(textareaName);
  }

  @action
  set_active_step(stepName) {
    this.activeStep = stepName;
    this.sopjsonData.lastActiveStep = stepName;
    this.updateCurrentStatusForStep(stepName);
      }

  @action
  get_university_details() {
    const universityId = 156;
    const resp = this.germanyApi.get_university_details(universityId);
    return resp;
  }

  // @action
  // get_course_details(payload) {
  //   const resp = this.germanyApi.get_course_details(payload);
  //   return resp;
  // }
  @action
  get_course_detailsALL() {
    const resp = this.germanyApi.get_course_detailsALL();
    return resp;
  }
  @action
  updateSelectedUniversity(value) {
    this.selecteduniversitiy = value;
  }

  @action
  updateSelectedDegree(value) {
    this.selectedDegree = value;
  }

  @action
  updateSelectedCourse(value) {
    this.selectedCourse = value;
  }

  @action
  clear_newCompose_object() {
    this.newComposeObject = {};
  }

  @action
  updateNewComposeObject(key, value) {
    this.newComposeObject[key] = value;
  }

  @action
  set_new_composition_object(data: object) {
    this.newComposeObject = data;
  }

  @action
  update_sop_json(json) {
    this.sopjsonData = json;
  }

  @action
  set_australia_sop(bool){
    this.isAustraliaSop=bool
  }

 getTemplateIds = () => {
  const ids:number[] = []
     this.steps.forEach((step) => {
      if(step.selectedTemplate !== 0){
        ids.push(step.selectedTemplate)
      }
    })
    this.sopjsonData.templates = ids
  }

  @action
  async update_sop_workspace(folderId, fileId) {
    this.saveAndNextButton = true
    this.set_selected_textarea(
      this.selected_text_area.name,
      this.selected_text_area.template_id,
      ''
    );
    this.getTemplateIds()
    const cloneSopJsonData = cloneDeep(toJS(this.sopjsonData));
    for (let key in cloneSopJsonData) {
      if (
        key === 'drives_passion_specific_area_study_discover_interest' ||
        key === 'chosen_specific_university_country_features_align_requirements'||
        key === 'reason_for_chosen_university' ||
        key === 'short_term_goals_long_term_ambitions_post_masters_detailed_career_pathways_entrepreneurial_ventures_explore' ||
        key === 'discuss_significant_challenges_faced_educational_professional_journey_overcame' ||
        key === 'specific_areas_research_captivate_interest_contribute_fields_research_focused_degree_phd_program' ||
        key === 'share_anecdotes_exemplify_leadership_qualities_ability_engage_collaborate_others' ||
        key === 'valuable_skills_acquired_professional_experiences_specific_examples_capabilities'
        || key === 'taken_career_educational_break_explain_productively_utilized_time'||
        key === 'ways_foresee_contributing_university_environment_experiences_interests' ||
        key === 'consider_prepared_degree_persuade_admissions_committee_select' ||
        key === 'choose_undergraduate_major_field_study_far' || key === 'planning_switch_fields_motivates_change_prepared_transition'||
        key === 'highlight_academic_honors_scholarships_significant_extracurricular_involvements_educational_journey' ||
        key === 'describe_major_academic_projects_completed_objectives_challenges_personal_contributions' 

      ) {
        cloneSopJsonData[key] = sanitizeHtml(cloneSopJsonData[key], sanitizeConf);
      } else if (key === 'motivation') {
        for (let keyMotivation in cloneSopJsonData[key]) {
          if (keyMotivation === 'value') {
            cloneSopJsonData[key][keyMotivation] = sanitizeHtml(
              cloneSopJsonData[key][keyMotivation],
              sanitizeConf
            );
          }
        }
      } else if (key === 'qualifications' || key === 'contributions' || key === 'professionalExperience') {
        for (let i = 0; i < cloneSopJsonData[key].length; i++) {
          for (let keyElement in cloneSopJsonData[key][i]) {
            if (keyElement === 'value') {
              // @ts-ignore
              cloneSopJsonData[key][i][keyElement] = sanitizeHtml(
                cloneSopJsonData[key][i][keyElement],
                sanitizeConf
              );
            }
          }
        }
      }
    }

    this.sopjsonData = cloneSopJsonData;

    const payloadData = {
      gentool: '156',
      folderid: folderId ? folderId : 0,
      documentid: fileId ? fileId : 0,
      payload: this.sopjsonData,
      issubmitted: false,
      profile_id: this.sopjsonData.profile_id,
    };
    const resp = await this.germanyApi.update_workspace(payloadData);
    if (resp.status == 200) {
      this.saveAndNextButton = false
      this.sopId = resp.data.documentid;
      this.state = resp.data.state;
      this.edit_clicked = false;
      this.loadingbutton=false;
    }
    return resp;
  }

  @action
  async save_sop_file(activeFolder, filename,parentId ) {
    const payloadData = {
      parent: activeFolder,
      name: filename ? filename : '',
      tool_id: this.toolId,
    };
    this.loadingbutton=true;
    const resp = await this.germanyApi.create_file(payloadData);

    this.loadingbutton=false;
    runInAction(() => {
      if (resp.status === 200) {
        this.loadingbutton=true;
        this.update_sop_workspace(resp.data.folder_id, resp.data.file_id);
        this.sopId = resp.data.file_id;
        this.folderid = resp.data.folder_id;
        this.loadingbutton=false;
      }
    });
  }

  @action
  async getTemplates(payload) {
    const resp = await this.germanyApi.get_templates(payload);
    runInAction(() => {
      if (resp.status === 200) {
        this.templatesForScreen = resp.data;
      }
    });
  }

  @action
  async getExamples(payload) {
    const resp = await this.germanyApi.get_examples(payload);
    runInAction(() => {
      if (resp.status === 200) {
        this.examplesForScreen = resp.data;
      }
    });
  }

  @action
  async get_details_of_university() {
    const payloadData = {
      kid: safe_get(this.sopjsonData, 'university.value', ''),
    };
    const resp = await this.germanyApi.get_details_of_university(payloadData);
    runInAction(() => {
      if (resp.status === 200) {
        if (resp.data && resp.data.length > 0 && resp.data[0]['summary']) {
          this.college_summary = resp.data[0]['summary'];
        }
      }
    });
  }

  @action
  toggle_show_template() {
    this.showTemplate = !this.showTemplate;
  }

  @action
  set_sop_id(id) {
    this.sopId = id;
  }

  @action
  set_current_folder_id(id) {
    this.current_folder_id = id;
  }

  @action async get_generated_sop(sopId, folderId) {
    this.loading = true;
    const resp = await this.germanyApi.get_generated_sop(sopId, folderId);
    this.generatedOutput = resp.data.output;
    this.sopId = resp.data.documentid;
    this.append_paragraph_to_editor_json(resp.data.output);
    this.is_premium_user = resp.data.premium_user;
    this.loading = false;
  }

  @action async setPremiumUser(bool){
    this.is_premium_user=bool;
  }

  @action async get_questions(toolId) {
    this.loading = true;
    const resp = await this.germanyApi.get_questions(toolId);
    this.questions = resp.data.questions;
    this.is_premium_user=resp.data.premium_user;
    this.availableCredits=resp.data.availableCredits;
  }

  @action
  async get_sop_workspace(sopId, folderId) {
    this.loading = true;
    const payloadData = {
      toolid: '156',
      documentid: sopId ? sopId : 0,
      folderid: folderId ? folderId : 0,
      payload: {},
      issubmitted: false,
    };
    const resp = await this.germanyApi.get_workspace(payloadData);
    runInAction(() => {
      if (resp.status === 200) {
        this.loading = false;
        if (resp.data && resp.data.state) {
          let sopJsonObj = { ...this.sopjsonData };
          this.sopjsonData = { ...sopJsonObj, ...safe_get(resp.data, 'state', this.sopjsonData) };
          this.sopName = resp.data.file_name;
          this.folderid = resp.data.folderid;
          this.breadCrumbs = resp.data.bread_crums;
                    this.stepsArr.map((step) => {
            this.markCompleted(step);
          });
        }
        this.jsonDataLoaded = true;
        this.availableCredits = resp.data.availableCredits;
        this.requiredCredits= resp.data.requiredCredits;
        this.premium= resp.data.premium_user;
        this.lastActiveStepClick = resp.data.state.lastActiveStep;
      }
    });
  }

  @action
  updateCardSelected(value) {
    this.cardSelected = value;
  }

  @action
  updateCardDescription(value) {
    this.selectedCardDescription = value;
  }
  @action
  setFolderId(value){
this.folderid = value;
  };
  @action
  update_selected_card(id) {
    this.selectedCard = id;
  }
  @action
  updateSopName(name){
    this.sopName=name;
  }
  @action
  setProfileId(value) {
    console.log(value, 'value1');
    this.sopjsonData.profile_id = value;
  }
  @action
  async generatecontent(profile_id: string) {
    this.loading = true;
    const qualArr: any = [];
    const contArr = [];
const profArr = [];
    if (safe_get(this.sopjsonData, 'qualifications', []).length > 0) {
      this.sopjsonData.qualifications.map((item) => qualArr.push(item['value']));
    }
    if (safe_get(this.sopjsonData, 'professionalExperience', []).length > 0) {
      this.sopjsonData.professionalExperience.map((item) => profArr.push(item['value']));
    }
    if (safe_get(this.sopjsonData, 'contributions', []).length > 0) {
      this.sopjsonData.contributions.map((item) => {
        contArr.push(item['value']);
      });
    }
    const payload = {
      toolid: 156,
      fileid: this.sopId,
      folderid: this.folderid,
      profileid: profile_id,
      questions:{
      course: safe_get(this.sopjsonData, 'course.label', ''),
      university: safe_get(this.sopjsonData, 'university.label', ''),
      summary: this.college_summary,
      degree: safe_get(this.sopjsonData, 'degree.label', ''),
      word_count: '1000',
      drives_passion_specific_area_study_discover_interest: safe_get(this.sopjsonData, 'drives_passion_specific_area_study_discover_interest', ''),
      chosen_specific_university_country_features_align_requirements: safe_get(this.sopjsonData,'chosen_specific_university_country_features_align_requirements',''),
      reason_for_chosen_university: safe_get(this.sopjsonData, 'reason_for_chosen_university', ''),
      motivation: safe_get(this.sopjsonData, 'motivation.value', ''),
      short_term_goals_long_term_ambitions_post_masters_detailed_career_pathways_entrepreneurial_ventures_explore: safe_get(this.sopjsonData, 'short_term_goals_long_term_ambitions_post_masters_detailed_career_pathways_entrepreneurial_ventures_explore', ''),
      discuss_significant_challenges_faced_educational_professional_journey_overcame: safe_get(this.sopjsonData, 'discuss_significant_challenges_faced_educational_professional_journey_overcame', ''),
      qualifications: qualArr,
      contributions: contArr,
      choose_undergraduate_major_field_study_far: safe_get(
        this.sopjsonData,
        'choose_undergraduate_major_field_study_far',
        ''
      ),
      highlight_academic_honors_scholarships_significant_extracurricular_involvements_educational_journey: safe_get(this.sopjsonData, 'highlight_academic_honors_scholarships_significant_extracurricular_involvements_educational_journey', ''),
      describe_major_academic_projects_completed_objectives_challenges_personal_contributions: safe_get(this.sopjsonData, 'describe_major_academic_projects_completed_objectives_challenges_personal_contributions', ''),
      specific_areas_research_captivate_interest_contribute_fields_research_focused_degree_phd_program: safe_get(this.sopjsonData, 'specific_areas_research_captivate_interest_contribute_fields_research_focused_degree_phd_program', ''),
      consider_prepared_degree_persuade_admissions_committee_select: safe_get(this.sopjsonData, 'consider_prepared_degree_persuade_admissions_committee_select', ''),
      ways_foresee_contributing_university_environment_experiences_interests: safe_get(this.sopjsonData, 'ways_foresee_contributing_university_environment_experiences_interests', ''),
      share_anecdotes_exemplify_leadership_qualities_ability_engage_collaborate_others: safe_get(this.sopjsonData, 'share_anecdotes_exemplify_leadership_qualities_ability_engage_collaborate_others', ''),
      valuable_skills_acquired_professional_experiences_specific_examples_capabilities: safe_get(this.sopjsonData, 'valuable_skills_acquired_professional_experiences_specific_examples_capabilities',''),
      taken_career_educational_break_explain_productively_utilized_time: safe_get(this.sopjsonData,'taken_career_educational_break_explain_productively_utilized_time',''),
      planning_switch_fields_motivates_change_prepared_transition: safe_get(this.sopjsonData,'planning_switch_fields_motivates_change_prepared_transition','')
      }
  };

    const resp = await this.germanyApi.generate_content(payload);
    this.loading = true
    if (resp.status === 200) {
      this.loaderEditor = true;
      this.loading = false
      this.loader=true
      this.append_paragraph_to_editor_json(resp.data.response);
      this.generatedOutput = resp.data.response;
      this.availableCredits = resp.data.availableCredits;
      this.is_premium_user = resp.data.is_premium_user;
    } else {
      this.loading = false;
      toast.error(resp.data, { position: 'top-center' });
    }
    this.loading = false;
  }

  @action
  get_initial_json_for_editor() {
    return this.editor_data;
  }

  @action
  update_editor_data(data) {
    this.editor_data = data;
  }

  @action
  clear_sop_state() {
    this.sopjsonData = { ...this.defaultSopJsonData };
    this.generatedOutput = '';
    this.jsonDataLoaded = false;
   this.set_active_step('Step 1');
    this.editor_data = { blocks: [], entityMap: {} };
    this.steps.forEach((step) => {
      step.selectedTemplate = 0;
    });

  }
  @action
  library_sop_state() {
    this.sopjsonData = { ...this.sopjsonData };
   this.generatedOutput = '';
   this.jsonDataLoaded = false;
   this.editor_data = { blocks: [], entityMap: {} };
  //  // this.set_active_step('Step 1');
  //   this.editor_data = { blocks: [], entityMap: {} };
  //   this.steps.forEach((step) => {
  //     step.selectedTemplate = 0;
    // });

  }

  @action
  clearSop(){
    
    this.sopjsonData = { ...this.defaultSopJsonData };
    this.set_active_step('Step 1');
  }
  @action
  refresh_editor_id() {
    this.editor_id = makeid_alpa_numeric(6);
  }

  @action
  updateBreadcums(array){
    this.breadCrumbs=array;
  }

  @action
  append_paragraph_to_editor_json(text) {
    const block = {
      data: {},
      depth: 0,
      entityRanges: [],
      inlineStyleRanges: [],
      key: makeid_alpa_numeric(5),
      text: text?.replace(/<\/br>/g, ''),
      type: 'paragraph',
    };
    const data = this.editor_data;
    // @ts-ignore
    data['blocks'] = [block];
    this.update_editor_data(data);
    this.refresh_editor_id();
  }

  @action
  updateCompletedStatusForStep(stepName, isCompleted) {
    this.steps.map((step) => {
      if (step.id === stepName) {
        if (isCompleted) {
          step.completed = true;
        } else {
          step.completed = false;
        }
      }
    });
  }

  @action
  markCompleted(stepName) {
       switch (stepName) {
      case 'Step 1':
        if (
          !_.isEmpty(safe_get(this.sopjsonData, 'course', {})) &&
          !_.isEmpty(safe_get(this.sopjsonData, 'university', {})) &&
          !_.isEmpty(safe_get(this.sopjsonData, 'degree', {})) &&
          safe_get(this.sopjsonData, 'drives_passion_specific_area_study_discover_interest', '') !== '' &&
          safe_get(this.sopjsonData, 'chosen_specific_university_country_features_align_requirements', '') !== ''
        ) {
          this.updateCompletedStatusForStep(stepName, true);
        } else {
          this.updateCompletedStatusForStep(stepName, false);
        }
        return;
      case 'Step 2':
        if (safe_get(this.sopjsonData, 'motivation.value', '')) {
          this.updateCompletedStatusForStep(stepName, true);
        } else {
          this.updateCompletedStatusForStep(stepName, false);
        }
        return;
      case 'Step 3':
        if (
          safe_get(this.sopjsonData, 'short_term_goals_long_term_ambitions_post_masters_detailed_career_pathways_entrepreneurial_ventures_explore', '') !== '' &&
          safe_get(this.sopjsonData, 'discuss_significant_challenges_faced_educational_professional_journey_overcame', '') !== ''
        ) {
          this.updateCompletedStatusForStep(stepName, true);
        } else {
          this.updateCompletedStatusForStep(stepName, false);
        }
        return;
      case 'Step 4':
        if (
          safe_get(this.sopjsonData, 'choose_undergraduate_major_field_study_far', '') !='' &&
          safe_get(this.sopjsonData, 'highlight_academic_honors_scholarships_significant_extracurricular_involvements_educational_journey', '') !=='' &&
          safe_get(this.sopjsonData, 'describe_major_academic_projects_completed_objectives_challenges_personal_contributions', '')  !==''
        ) {
          this.updateCompletedStatusForStep(stepName, true);
        } else {
          this.updateCompletedStatusForStep(stepName, false);
        }
        return;
      case 'Step 5':
        if (
          safe_get(this.sopjsonData, 'valuable_skills_acquired_professional_experiences_specific_examples_capabilities', '') !=='' &&
          safe_get(this.sopjsonData, 'taken_career_educational_break_explain_productively_utilized_time', '') !=='' &&
          safe_get(this.sopjsonData, 'planning_switch_fields_motivates_change_prepared_transition', '') !=''
        ) {
          this.updateCompletedStatusForStep(stepName, true);
        } else {
          this.updateCompletedStatusForStep(stepName, false);
        }
        return;
    case 'Step 6':
      if (safe_get(this.sopjsonData, 'contributions', []).length > 0) {
        this.updateCompletedStatusForStep(stepName, true);
      } else {
        this.updateCompletedStatusForStep(stepName, false);
      }
      return;
  }
  }

  @action
  async single_palgarism_request() {
    if (this.plagiarismRequestInProgress) {
      return {};
    }

    this.plagiarismRequestInProgress = true;
    const response = await this.germanyApi.get_palgarism_details(this.sopId);
    if (response.status === 200) {
      this.plagiarismRequestInProgress = false;
      return response.data;
    } else {
      this.plagiarismRequestInProgress = false;
      toast.error(response.data, { position: 'top-center' });
      return {};
    }
  }

  @action
  async get_palgarism_details() {
    const requestStartTime = Date.now();
    const requestEndTime = requestStartTime + this.plagiarismTotalRequestDuration;

    // while (Date.now() < requestEndTime) {
      const response = await this.single_palgarism_request();
      if (response && response.plagiarism_result) {
        this.plagiarism_result = response;
        this.availableCredits=response.availableCredits;
        // break;
      }

      await new Promise((resolve) => setTimeout(resolve, this.plagiarismIntervalDuration));
    // }
  }
  @action 
  setGeneratedOutput(value){
    this.generatedOutput = value
  }

  @action
  async sendPlagiarismRequest() {
    this.plagiarismRequestLoading = true;
    this.resetPlagiarsim();
    console.log(this.generatedOutput,"PLAGARISMGERMANY");
    let payload = { input_text: this.generatedOutput, file_id: this.sopId };
    //Remove for testing let payload = { input_text: p, file_id: this.sopId };
    const resp = await this.germanyApi.submit_plagiarsim_scan(payload);
    if (resp.status === 200) {
      await this.get_palgarism_details();
      if (
        safe_get(this.plagiarism_result.plagiarism_result.results.score, 'aggregatedScore', 999) ===
        999
      ) {
        this.plagiarismErrorOccured = true;
      }
    } else {
      this.plagiarismRequestLoading = false;
      toast.error(resp.data, { position: 'top-center' });
      this.plagiarism_result =initialPlagiarismObject;
    }
    this.plagiarismRequestLoading = false;
  }

  @action
  async deletePlagarism(file_id){
    return await this.germanyApi.delete_plagarism_result(file_id);
  }

  @action
  resetPlagiarsim(){
    this.plagiarismRequestInProgress = false;
    this.plagiarismErrorOccured = false;
    this.plagiarism_result = initialPlagiarismObject;
  }

  @action
  toggle_show_plagiarism() {
    this.showPlagiarism = !this.showPlagiarism;
  }

}
