import { ReportsApi } from './../api/report-api';
import { action, makeAutoObservable } from 'mobx';
import { makePersistable } from 'mobx-persist-store';

export class ReportsStore {
  constructor(private reportsApi: ReportsApi) {
    makeAutoObservable(this);
    makePersistable(this, { name: 'ReportsStore', properties: [], storage: window.localStorage });
  }
  sopPerDay: any;
  sopPerWeek: any;
  sopPerMonth: any;
  userBased: any;
  sopPerDayUser: any;
  sopPerWeekUser: any;
  sopPerMonthUser: any;
  sopTotalSOP: any;
  dateBasedUsers: any;
  @action
  async getReports() {
    const res = await this.reportsApi.getReports();

    this.sopPerDay = res.data.sopPerDay;
    this.sopPerMonth = res.data.sopPerMonth;
    this.sopPerWeek = res.data.sopPerWeek;
    this.userBased = res.data.userBasedSop;
  }
  @action
  async userReports(payload) {
    const res = await this.reportsApi.userReports(payload);
    this.sopPerDayUser = res.data.sopPerDay;
    this.sopPerMonthUser = res.data.sopPerMonth;
    this.sopPerWeekUser = res.data.sopPerWeek;
    this.sopTotalSOP = res.data.totalSOP;
    this.dateBasedUsers = res.data.sopInGivenDateRange;
  }
}
