import { useEffect, useState } from 'react';
import { useStore } from '../../../../hooks/use-store';
import { emitObject } from '../../../../pages/tools/interfaces/interfaces';
import ContentEditableTextArea from '../../../tools/editable-textarea/editable-textarea';
import { SideOverlay } from '../../../../side-overlay';
import { observer } from 'mobx-react-lite';
import FetchExamples from '../../../sop-components/fetch_examples/fetch_examples';
import EditPopover from '../../final-output-screen-components/edit_popover';
import FetchTemplates from '../../../sop-components/fetch_templates/fetch_templates';
import { useParams } from 'react-router';

const Goals = observer(() => {
  const { sopStore, newContentStore } = useStore();
  const [goals, setGoals] = useState({});
  const regex = /enter your text/;
  const [infoIconClicked, setInfoIconClicked] = useState(false);
  const {id} = useParams()

  useEffect(() => {
    if(id=='155'){
      sopStore.getTemplates({
        tool_id: 155,
        screen: 'S3',
        fileid:sopStore.sopId!=155 ? sopStore.sopId : 0
      });
      sopStore.getExamples({
        tool_id: 155,
        screen: 'S3',
      });
    }
    else{
      sopStore.getTemplates({
        tool_id: 152,
        screen: 'S3',
        fileid:sopStore.sopId!=152 ? sopStore.sopId : 0
      });
      sopStore.getExamples({
        tool_id: 152,
        screen: 'S3',
      });
    }
  }, []);

  const onBlurTextArea = (textAreaEvent: emitObject) => {
    if (sopStore.final_output_screen) {
      setGoals({ ...goals, [textAreaEvent.key]: textAreaEvent.value });
    } else {
      sopStore.updateSopJsonData(textAreaEvent.key, textAreaEvent.value);
    }
  };

  const template_close_handler = () => {
    sopStore.toggle_show_template();
  };

  const onTemplateClicked = (question, slot) => {
    sopStore.setSlot(slot);
    sopStore.set_selected_textarea(question, '', '');
    sopStore.toggle_show_template();
  };

  const onEditClicked = (slot) => {
    sopStore.getTemplates({
      tool_id: 152,
      screen: 'S3',
      fileid:sopStore.sopId!=152?sopStore.sopId:0
    });
    sopStore.getExamples({
      tool_id: 152,
      screen: 'S3',
    });
    const temp = sopStore.sopjsonData[slot];
    setGoals({ ...goals, [slot]: temp });
    sopStore.setEditClicked(true);
    sopStore.setSlot(slot);
  };

  const handleClickUpdate = (slot, value) => {
    sopStore.updateSopJsonData(slot, value);
    sopStore.update_sop_workspace(newContentStore.activeFolder, sopStore.sopId);
  };

  const templatesAndExamplesLength = () => {
    if (infoIconClicked) {
      if (
        sopStore.examplesForScreen &&
        sopStore.examplesForScreen[sopStore.selected_text_area.name].length > 0
      ) {
        return sopStore.examplesForScreen[sopStore.selected_text_area.name].length;
      }
    } else {
      if (
        sopStore.templatesForScreen?.[sopStore.selected_text_area.name] &&
        sopStore.templatesForScreen[sopStore.selected_text_area.name].length > 0
      ) {
        return sopStore.templatesForScreen[sopStore.selected_text_area.name].length;
      } else return 0;
    }
  };

  return (
    <>
      <div className="flex flex-col justify-center relative">
        {sopStore?.questions.map((item: any) => {
                    if (item?.screen == 'S3' && item?.slots !== null) {
            return (
              <ContentEditableTextArea
              placeholder={sopStore.templateExists(item['question']) ? 'Write your content here or use our template':"Write your content here"}
                onTemplateClicked={() => onTemplateClicked(item.question, item.slots)}
                setInfoIconClicked={setInfoIconClicked}
                onBlur={onBlurTextArea}
                htmlText={sopStore.sopjsonData[item['slots']]}
                heightInRem={sopStore.final_output_screen ? 2 : 8}
                display={item['question']}
                slot={item['slots']}
                regex={regex}
                finalOutputScreen={sopStore.final_output_screen}
                onEditClicked={onEditClicked}
                showpencilIcon={true}
                showTemplateIcon={
                  sopStore.final_output_screen
                    ? false
                    : true && sopStore.templateExists(item['question'])
                }
                showInfoIcon={
                  sopStore.final_output_screen
                    ? false
                    : true && sopStore.exampleExists(item['question'])
                }
              />
            );
          }
        })}

        {sopStore.slot == 'short_term_goals' && (
          <EditPopover
            sopStore={sopStore}
            handleCancel={() => {}}
            handleClickUpdate={() =>
              handleClickUpdate('short_term_goals', goals['short_term_goals'])
            }
          >
            <ContentEditableTextArea
              placeholder={'Write your content here or use our template'}
              onTemplateClicked={() =>
                onTemplateClicked(
                  'Elaborate your career plans immediately post your graduation',
                  'short_term_goals'
                )
              }
              onBlur={onBlurTextArea}
              htmlText={goals['short_term_goals']}
              heightInRem={sopStore.final_output_screen ? 2 : 8}
              display={'Elaborate your career plans immediately post your graduation'}
              label={'short term'}
              slot={'short_term_goals'}
              regex={regex}
              // finalOutputScreen={sopStore.final_output_screen}
              onEditClicked={onEditClicked}
              showTemplateIcon={sopStore.templateExists(
                'Elaborate your career plans immediately post your graduation'
              )}            />
          </EditPopover>
        )}

        {sopStore.slot == 'long_term_goals' && (
          <EditPopover
            sopStore={sopStore}
            handleCancel={() => {}}
            handleClickUpdate={() => handleClickUpdate('long_term_goals', goals['long_term_goals'])}
          >
            <ContentEditableTextArea
              placeholder={'Write your content here or use our template'}
              onTemplateClicked={() =>
                onTemplateClicked('Tell us about your long term career plans', 'long_term_goals')
              }
              onBlur={onBlurTextArea}
              htmlText={goals['long_term_goals']}
              heightInRem={2}
              display={'Tell us about your long term career plans'}
              label={'long term'}
              slot={'long_term_goals'}
              regex={regex}
              // finalOutputScreen={sopStore.final_output_screen}
              onEditClicked={onEditClicked}
              showTemplateIcon={sopStore.templateExists(
                'Tell us about your long term career plans'
              )}
            />
          </EditPopover>
        )}
      </div>
      <SideOverlay
        children={
          <div>
            {infoIconClicked ? (
              <FetchExamples infoIconClicked={infoIconClicked} sopStore={sopStore}/>
            ) : (
              <FetchTemplates setState={setGoals} state={goals} sopStore={sopStore}
              />
            )}
          </div>
        }
        onClose={template_close_handler}
        show={sopStore.showTemplate}
        title={infoIconClicked ? 'Examples' : 'Templates'}
        length={templatesAndExamplesLength()}
      />
    </>
  );
});

export default Goals;
