import { PaymentApi } from './../api/payment-api';
import { action, makeAutoObservable } from 'mobx';
import { makePersistable } from 'mobx-persist-store';

export class PaymentStore {
  constructor(private paymentApi: PaymentApi) {
    makeAutoObservable(this);
    makePersistable(this, { name: 'PaymentStore', properties: [], storage: window.localStorage });
  }

  selectedPlanDetails: { selectedPlan: any, price: any , coupon_code: any } = {
    selectedPlan: '',
    price: null,
    coupon_code: '',
  };
  orderId: string = '';
  transactionDetails:object = {}
  paymentSlideValue:number = 0
  paymentCardsData:{}[]=[]
  paymentsliderMarks:object={}
  creditsAndPrices:{ credits: number, price: number,id:string }[]
  showPremiumPlandialog: boolean = false;
  couponCode : any ='';
  @action
  async createOrder(payload) {
    return await this.paymentApi.createOrder(payload);
  }

  async createOrder_2(payload) {
    return await this.paymentApi.createOrder_2(payload);
  }
@action
async setCouponCode(code){
  this.couponCode = code;
}
  @action
  async paymentSuccess(payload) {
    return await this.paymentApi.paymentSuccess(payload);
  }


  @action
  async setTransactionDetails(payload) {
    this.transactionDetails = payload
  }

  @action 
  async setPaymentSlider (payload){
    this.paymentSlideValue = payload
  }

  @action 
  async getPaymentsData(){
    return await this.paymentApi.getPaymentCardDetails();
  }

  @action 
  async setPaymentsCardsData(payload){
    this.paymentCardsData = payload
  }

  @action
  async setPaymentSliderMarks(payload){
    this.paymentsliderMarks = payload
  }

  @action
  async setCreditsAndPrices (payload){
    this.creditsAndPrices=payload
  }

  @action
  show_premium_dialog() {
    this.showPremiumPlandialog = true;
  }
  @action
  hide_premium_dialog() {
    this.showPremiumPlandialog = false;
  }

}
