
import Card from './card';
import cardsContent from './cardsContent.json';
import { useStore } from '../../hooks/use-store';
import { observer } from 'mobx-react-lite';

const Cards = observer((props: any) => {
  const { sopStore } = useStore();


  return (
    <>
      <div className="py-2">
        <div className="rounded-2xl border border-[#E3E5E9] bg-[#FEFEFE] ">
          <p className="text-[#4E5664] text-xl text-center p-10">
            Choose 1 card you want to proceed with{' '}
          </p>
          <div className="flex items-center pb-8 px-6 flex-wrap">
            {cardsContent.map((card, index) => (
              <div className="bg-[#FEFEFE] p-4 h-auto" key={card.title}>
                <Card
                  onClick={() =>
                    {
                      sopStore.update_selected_card(card.id);
                      localStorage.setItem('selectedCard',card.id.toString())
                      sopStore.updateCardSelected(true);
                      sopStore.updateCardDescription(card.title)
                    }}
                  key={card.id}
                  title={card.title}
                  description={card.description}
                  selectedCard={sopStore.selectedCard}
                  isSelected={card.id == sopStore.selectedCard}
                  icon={card.icon}
                  showInfoIcon={
                    sopStore.final_output_screen
                      ? false
                      : true && sopStore.exampleExists(sopStore.selectedCardDescription)
                  }
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
});

export default Cards;
