import {
  $createParagraphNode,
  $createTextNode,
  $getRoot,
  $createLineBreakNode
} from 'lexical';


export function empty_editor(editor) {
  editor.update(() => {
    const root = $getRoot();
    root.clear();

    const paragraphNode = $createParagraphNode();
    const textNode = $createTextNode('');
    paragraphNode.append(textNode);
    root.append(paragraphNode);
  });
}


export function update_editor_with_line_separated_text(editor, text) {
  editor.update(() => {
    const root = $getRoot();
    root.clear();

    let sopText = text;
    sopText.replaceAll(/<\/br>/g, '');
    let splitByNewLine = sopText.split('\n');

    splitByNewLine.forEach((el) => {
      if (el.toString().trim() != '') {
        const paragraphNode = $createParagraphNode();
        const textNode = $createTextNode(el.toString().trim());
        paragraphNode.append(textNode);
        root.append(paragraphNode);
      }
    });
  });
}


export function update_editor_with_rehydrate_json(editor, json_obj) {
  const state = editor.parseEditorState(JSON.stringify(json_obj));
  editor.setEditorState(state);
}

export function hydrate_editor_from_state(editor, state) {
  /*
  editor_document: {
    comments: any,
      document_id: number,
      editor_state: any,
      editor_text: string,
      extras: any,
      sop_text: string,
  };
  */
  try {
    if (
      state?.editor_state !== null
      &&
      Object.keys(state?.editor_state).length > 0
      &&
      get_plain_text_from_editor_json(editor, state?.editor_state) === state?.sop_text
    ) {
      update_editor_with_rehydrate_json(editor, state?.editor_state);
    } else {
      update_editor_with_line_separated_text(editor, state?.sop_text);
    }
  } catch (e) {
    console.error(e);
    empty_editor(editor);
  }
}

export function get_plain_text_from_editor_json(editor,json_obj){
  const parsedEditorState = editor.parseEditorState(JSON.stringify(json_obj));
  const plain_text = parsedEditorState.read(() => $getRoot().getTextContent()) || '';
  return plain_text;
}

export function build_save_payload(editor, id, comments = {}, extras = {}) {
  const editor_state = editor.getEditorState().toJSON();
  const parsedEditorState = editor.parseEditorState(JSON.stringify(editor_state));
  const plain_text = parsedEditorState.read(() => $getRoot().getTextContent()) || '';

  const payload = {
    'sop_text': plain_text,
    'editor_text': plain_text,
    'document_id': parseInt(id),
    'editor_state': editor_state,
    'comments': comments,
    'extras': extras
  };
  return payload;
}
